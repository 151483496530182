import { gql, useMutation } from "@apollo/client";

export const MUTATION_EDIT_HISTORY_LABEL = gql`
  mutation upsertHistoryLabel($actionId: ID!, $historyLabel: String!) {
    upsertHistoryLabel(
      input: { actionId: $actionId, historyLabel: $historyLabel }
    )
  }
`;

export function useEditHistoryLabelMutation() {
  const [
    editHistoryLabelMutation,
    {
      loading: isLoadingEditHistoryLabelMutation,
      error: errorEditHistoryLabelMutation
    }
  ] = useMutation(MUTATION_EDIT_HISTORY_LABEL);

  return {
    editHistoryLabelMutation,
    isLoadingEditHistoryLabelMutation,
    errorEditHistoryLabelMutation
  };
}
