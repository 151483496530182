import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
export const GET_EXECUTION_IMAGES = gql`
  query getImages($limit: Int!, $cursor: String) {
    getImages(input: { limit: $limit, cursor: $cursor }) {
      nextCursor
      images {
        id
        name
        environmentType
        environmentTypeVersion
        validationType
        validationTypeVersion
        digest
        updatedOn
        requirements {
          isMultinode
        }
        packages {
          name
          version
        }
        validatedPackages {
          name
          version
        }
        addons {
          name
        }
        recommendations {
          command
          isMultinode
        }
      }
    }
  }
`;

export function useListExecutionImagesQuery(
  limit: number = 20,
  cursor: string | null | undefined = null
) {
  const {
    data: executionImages,
    loading: isLoadingGetExecutionImages,
    error: errorGetExecutionImages,
    fetchMore: fetchMoreExecutionImages
  } = useQuery(GET_EXECUTION_IMAGES, {
    variables: { limit, cursor },
    context: { clientName: "execution" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const listMoreImages = (limit: number, nextCursor: string) =>
    fetchMoreExecutionImages({
      variables: {
        limit: limit,
        cursor: nextCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.getImages.images = [
          ...(prevResult?.getImages?.images ? prevResult.getImages.images : []),
          ...fetchMoreResult.getImages.images
        ];
        return fetchMoreResult;
      }
    });

  useEffect(() => {
    if (executionImages && executionImages.getImages.nextCursor) {
      listMoreImages(limit, executionImages.getImages.nextCursor);
    }
  }, [executionImages]);

  return {
    executionImages: executionImages?.getImages?.images,
    isLoadingGetExecutionImages,
    errorGetExecutionImages
  };
}
