import { gql, useLazyQuery, useQuery } from "@apollo/client";

export const GET_PMX_ACTIVITY_DETAILS_QUERY = gql`
  query getPmxActivityDetails($id: ID!) {
    getPmxActivityDetails(input: { id: $id }) {
      __typename
      id
      trialNumber
      trialShortDescription
      bayNumber
      projectId
      mainRepository {
        __typename
        name
        id
        isStandard
        created
      }
      conversations {
        __typename
        id
        name
        comments {
          id
          text
          createdBy
          created
          referencedFiles {
            __typename
            name
            joinCommentType
            revision
            versionId
            isValid
            isDir
            createdBy
            created
            md5
            size
            repo {
              id
              name
            }
          }
          referencedActions {
            id
            actionType
          }
          replyComment {
            id
            text
          }
        }
      }
      allAccessUser {
        __typename
        email
        name
      }
      readOnlyRepositories {
        __typename
        id
        name
        isStandard
      }
    }
  }
`;

export function useGetPmxActivitiyDetailsQuery(
  onCompleted: Function,
  activityId: number
) {
  const {
    data: activityData,
    loading: isActivityLoading,
    error: errorActivity,
    refetch: refetchActivityDetails
  } = useQuery(GET_PMX_ACTIVITY_DETAILS_QUERY, {
    variables: {
      id: activityId
    },
    onCompleted: (data) => {
      onCompleted(data);
    },
    skip: !activityId,
    notifyOnNetworkStatusChange: true
  });

  return {
    activityData,
    isActivityLoading,
    errorActivity,
    refetchActivityDetails
  };
}

export function useGetPmxActivitiyDetailsLazyQuery() {
  const [
    queryGetPmxActivityDetails,
    { data: activityData, loading: isActivityLoading, error: errorActivity }
  ] = useLazyQuery(GET_PMX_ACTIVITY_DETAILS_QUERY);

  return {
    queryGetPmxActivityDetails,
    activityData,
    isActivityLoading,
    errorActivity
  };
}
