import { gql, useMutation } from "@apollo/client";

export const MARK_NOTIFICATION_MUTATION = gql`
  mutation markNotifications(
    $notificationIds: [Int!]
    $numberOfNotifications: Int
    $value: Boolean!
  ) {
    markNotifications(
      input: {
        notificationIds: $notificationIds
        numberOfNotifications: $numberOfNotifications
        value: $value
      }
    ) {
      email
      notifications {
        associationId
        read
        outcomeType
        notificationType
        message
        id
        createdOn
        author
      }
    }
  }
`;

export function useMarkNotificationMutation() {
  const [
    markNotification,
    {
      data: markNotificationMutationData,
      error: markNotificationError,
      loading: markNotificationLoading
    }
  ] = useMutation(MARK_NOTIFICATION_MUTATION, {
    onError(err: any) {
      console.log(err);
    },
    context: { clientName: "appsync" }
  });

  return {
    markNotification,
    markNotificationMutationData,
    markNotificationError,
    markNotificationLoading
  };
}
