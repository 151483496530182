import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_MODSPACE_CONTENT = gql`
  mutation importModspaceContent(
    $activityId: ID!
    $username: String!
    $encryptedPassword: String!
    $repoFolder: String
    $modspaceFolder: String!
    $modspacePaths: [String]
    $modspaceRevision: String
    $qualityCheckStatus: QualityCheckStatus
    $description: String
  ) {
    importModspaceContent(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        modspacePaths: $modspacePaths
        repoFolder: $repoFolder
        modspaceFolder: $modspaceFolder
        activityId: $activityId
        qualityCheckStatus: $qualityCheckStatus
        description: $description
        modspaceRevision: $modspaceRevision
      }
    ) {
      actionId
      actionStatus
    }
  }
`;

export function useImportContentFromModspaceMutation() {
  const [
    importContentMutation,
    {
      data: importMutationData,
      loading: isLoadingMutation,
      error: errorMutation
    }
  ] = useMutation(MUTATION_IMPORT_MODSPACE_CONTENT, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    importContentMutation,
    importMutationData,
    isLoadingMutation,
    errorMutation
  };
}
