import { gql, useMutation } from "@apollo/client";

export const DELETE_PMX_ACTIVITY_MUTATION = gql`
  mutation deletePmxActivity($id: ID!) {
    deletePmxActivity(input: { id: $id })
  }
`;

export function useDeletePmxActivityMutation() {
  const [deletePmxActivityMutation] = useMutation(DELETE_PMX_ACTIVITY_MUTATION);

  return {
    deletePmxActivityMutation
  };
}
