import { gql, useMutation } from "@apollo/client";

export const PUSH_NOTIFICATION_MUTATION = gql`
  mutation pushNotifications($numberOfNotifications: Int) {
    pushNotifications(
      input: { numberOfNotifications: $numberOfNotifications }
    ) {
      email
      notifications {
        id
        message
        associationId
        author
        createdOn
        notificationType
        outcomeType
        read
      }
    }
  }
`;

export function usePushNotificationMutation() {
  const [
    pushNotification,
    { data: pushNotificationMutationData, error: pushNotificationError }
  ] = useMutation(PUSH_NOTIFICATION_MUTATION, {
    onError(err: any) {
      console.log(err);
    },
    context: { clientName: "appsync" }
  });

  return {
    pushNotification,
    pushNotificationError,
    pushNotificationMutationData
  };
}
