import { gql, useQuery } from "@apollo/client";

export const QUERY_GET_MY_ACTIONS = gql`
  query getMyActions($page: Int, $pageSize: Int) {
    getMyActions(input: { page: $page, pageSize: $pageSize }) {
      actions {
        __typename
        id
        actionType
        actionFile {
          actionFileType
          file {
            name
            revision
            versionId
            isValid
            repo {
              name
              id
            }
          }
        }
        startDatetime
        sourceFolder
        sourceFiles
        user {
          email
        }
        description
        actionQualityCheckStatus
        pmxActivity {
          id
          trialNumber
          mainRepository {
            id
            name
          }
        }
        actionStatus
        actionSource
        actionStatusDescription
        hasAdditionalFilesLinked
        actionHistory {
          updated
          description
          actionQualityCheckStatus
          userId
        }
        historyLabel {
          name
        }
        additionalDetails
        jobId
      }
      totalRowCount
    }
  }
`;

export function useGetMyActionsQuery(
  page: number | null,
  pageSize: number | null
) {
  const {
    data: actions,
    loading: isLoading,
    error,
    refetch: refetchActions
  } = useQuery(QUERY_GET_MY_ACTIONS, {
    variables: { page: page, pageSize: pageSize },
    //notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  return {
    actions,
    refetchActions,
    isLoading,
    error
  };
}
