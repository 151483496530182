import { gql, useLazyQuery } from "@apollo/client";
export const GET_DATASET_FOR_STUDY = gql`
  query getDatasetForStudy(
    $username: String!
    $encryptedPassword: String!
    $studyKey: String!
    $system: String!
    $therapeuticArea: String
    $indication: String
  ) {
    getDatasetForStudy(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        studyKey: $studyKey
        system: $system
        therapeuticArea: $therapeuticArea
        indication: $indication
      }
    ) {
      systemName
      name
      lifeCycle
      businessArea
      schema
      description
      modelType
      modelSubType
      virtualModel
      numberOfTables
      numberOfVirtualModels
      asof
      dataFreeze
      lastUpdate
    }
  }
`;

export function useQueryGetDatasetForStudy() {
  const [
    getDatasetForStudy,
    { data: dataset, loading: isLoadingDataset, error: errorDataset }
  ] = useLazyQuery(GET_DATASET_FOR_STUDY, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  return {
    getDatasetForStudy,
    dataset,
    isLoadingDataset,
    errorDataset
  };
}
