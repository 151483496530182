import { gql, useQuery } from "@apollo/client";
export const GET_ACCESS_RIGHTS_QUERY = gql`
  query getAccessRights($repoId: ID!) {
    getAccessRights(input: { repoId: $repoId }) {
      name
      reader
      contributor
    }
  }
`;

export function useGetAccessRightsQuery(repoId: string) {
  const {
    data: accessRightDetails,
    loading: isLoadingAccess,
    error,
    refetch: refetchAccessQuery
  } = useQuery(GET_ACCESS_RIGHTS_QUERY, {
    variables: { repoId },
    notifyOnNetworkStatusChange: true
  });

  return {
    accessRightDetails,
    refetchAccessQuery,
    isLoadingAccess,
    error
  };
}
