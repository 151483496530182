import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_SVN_CONTENT = gql`
  query getSpmRePaths(
    $username: String!
    $encryptedPassword: String!
    $spmReFolder: String!
    $spmReRevision: String
  ) {
    getSpmRePaths(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        spmReFolder: $spmReFolder
        spmReRevision: $spmReRevision
      }
    ) {
      spmRePaths {
        name
        size
      }
    }
  }
`;

export function useGetSvnContentQuery() {
  const [getSpmRePaths, { error, loading, data }] = useLazyQuery(
    QUERY_GET_SVN_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getSpmRePaths,
    error,
    loading,
    data
  };
}
