import React from "react";
import { Amplify, Hub, Auth } from "aws-amplify";
import { parseJwt } from "../../helpers/jwtHelper";
// TODO: use react-router for handling redirects?

// File used for Authentication throughout the application
export type User = {
  email: string;
  name: string;
};

type AuthContextType = {
  isSignedIn: boolean;
  decodedToken: any | undefined;
  token: string | undefined;
  isActivatedUser: boolean | undefined;
  isAdminUser: boolean | undefined;
  isDataAccessManager: boolean | undefined;
  signIn: () => void;
  signOut: () => void;
  userData: User | undefined;
  callAPI: () => Promise<any>;
};

const AmplifyConfiguration = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_LOGIN_DOMAIN,
      scope: ["email", "openid", "profile"],
      redirectSignIn:
        process.env.REACT_APP_ENV === "local"
          ? "http://localhost:3000"
          : "https://" + process.env.REACT_APP_DOMAIN_NAME,
      redirectSignOut:
        process.env.REACT_APP_ENV === "local"
          ? "http://localhost:3000"
          : "https://" + process.env.REACT_APP_DOMAIN_NAME,
      responseType: "token"
    }
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_REGION
      },
      {
        name: "interactive-api",
        endpoint: process.env.REACT_APP_INTERACTIVE_API_URL,
        region: process.env.REACT_APP_REGION
      },
      {
        name: "execution-api",
        endpoint: process.env.REACT_APP_EXECUTION_API_URL,
        region: process.env.REACT_APP_REGION
      }
    ]
  }
};

const AuthContext = React.createContext<AuthContextType>({
  isSignedIn: false,
  token: undefined,
  decodedToken: undefined,
  isActivatedUser: undefined,
  isDataAccessManager: undefined,
  isAdminUser: undefined,
  signIn: () => {},
  signOut: () => {},
  userData: undefined,
  callAPI: (path: string, options?: any) => {
    return fetch(path, options);
  }
});

export const useAuth = () => React.useContext(AuthContext);

// This is the HOC to wrap the App
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // Start Authentication code
  const [token, setToken] = React.useState<string | undefined>();
  //const [user, setUser] = React.useState<User | undefined>();

  const user: User = {
    name: token && JSON.parse(atob(token.split(".")[1])).name,
    email: token && JSON.parse(atob(token.split(".")[1])).email
  };

  const isSignedIn = !!token;
  const decodedToken = token ? parseJwt(token) : undefined;

  let isActivatedUser = false;
  if (decodedToken && "cognito:preferred_role" in decodedToken) {
    isActivatedUser = !decodedToken["cognito:preferred_role"].includes(
      ":role/unactivatedUser/"
    );
  }

  let isAdminUser = false;
  isAdminUser =
    decodedToken &&
    decodedToken["cognito:groups"] &&
    decodedToken["cognito:groups"].includes("phil_admins");

  let isDataAccessManager = false;
  isDataAccessManager =
    decodedToken &&
    decodedToken["cognito:groups"] &&
    decodedToken["cognito:groups"].includes("phil_data_access_manager");

  async function callAPI(path: string, options?: any): Promise<any> {
    // create full url
    const url = AmplifyConfiguration.API.endpoints[0].endpoint + path;
    // inject token if present
    if (token) {
      if (options?.headers) {
        options = {
          ...options,
          headers: {
            ...options?.headers,
            Authorization: token
          }
        };
      } else {
        options = {
          ...options,
          headers: {
            Authorization: token
          }
        };
      }
    }

    return fetch(url, options);
  }

  const initAuth = () => {
    Auth.currentSession()
      .then((cognitoSession) => {
        const token = cognitoSession.getIdToken().getJwtToken();
        setToken(token);

        //redirect, if such a command was stored in the cookie
        const initialPathname = localStorage.getItem("initialPathname");
        if (initialPathname) {
          localStorage.removeItem("initialPathname");
          window.location.href = initialPathname;
        }
      })
      .catch((err) => {
        if (
          !window.location.pathname.includes("/externalDownload/") &&
          !window.location.pathname.includes("/externalUpload/")
        ) {
          console.error("Login Error:", err);
          // Redirect to cognito / azure ad login if no token is present, but save the current URL first in a cookie, so we can redirect after authentication
          if (window.location.pathname !== "/") {
            localStorage.setItem("initialPathname", window.location.pathname);
          }

          const url = `https://${AmplifyConfiguration.Auth.oauth.domain}/oauth2/authorize?redirect_uri=${AmplifyConfiguration.Auth.oauth.redirectSignIn}&response_type=TOKEN&client_id=${AmplifyConfiguration.Auth.userPoolWebClientId}&state=ySyhKcGtQ4Ec1pi86vrRrSFijsf4TXgo&scope=email%20openid%20profile&nonce=${AmplifyConfiguration.Auth.oauth.nonce}`;
          console.log(`redirecting to ${url}`);
          window.location.href = url;
        }
      });
  };

  React.useEffect(() => {
    // setup everything
    Amplify.configure(AmplifyConfiguration);

    //only for event listening
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "customOAuthState":
          break;
        case "signIn":
          break;
        case "cognitoHostedUI":
          break;
        case "signOut":
          break;
        case "signIn_failure":
          setToken(undefined);
          break;
        case "cognitoHostedUI_failure":
          setToken(undefined);
          break;
        default:
          break;
      }
    });

    // check state and signIn if needed
    initAuth();
    // eslint-disable-next-line
  }, []);

  const authContextValue: AuthContextType = {
    isSignedIn,
    token,
    decodedToken,
    isActivatedUser,
    isAdminUser,
    isDataAccessManager,
    signIn: () => {
      initAuth();
    },
    signOut: () => {
      Auth.signOut();
      setToken(undefined);
    },
    userData: user,
    callAPI: callAPI
  };

  // End of Authentication code

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
