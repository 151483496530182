import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_EXTERNAL_UPLOAD_LINK = gql`
  mutation createExternalUploadLink(
    $pmxActivityId: Int
    $absolutePath: String
    $emailAddress: String!
    $additionalMessage: String
  ) {
    createExternalUploadLink(
      input: {
        pmxActivityId: $pmxActivityId
        absolutePath: $absolutePath
        emailAddress: $emailAddress
        additionalMessage: $additionalMessage
      }
    )
  }
`;

export function useCreateExternalUploadLink() {
  const [
    createExternalUploadLink,
    {
      loading: isCreateExternalUploadLinkLoading,
      error: errorCreateExternalUploadLink,
      data: createExternalUploadLinkData
    }
  ] = useMutation(MUTATION_CREATE_EXTERNAL_UPLOAD_LINK, {
    fetchPolicy: "no-cache"
  });

  return {
    createExternalUploadLink,
    isCreateExternalUploadLinkLoading,
    errorCreateExternalUploadLink,
    createExternalUploadLinkData
  };
}
