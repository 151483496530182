import { useMutation } from "@apollo/client";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChatOutlineIcon from "@mui/icons-material/ChatOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { useState } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { DELETE_TEST_REPOS_MUTATION } from "../../backend/hooks/mutationDeleteTestRepos";
import { useUserHelper } from "../../hooks/userHelper";
import { useAuth } from "../authContextProvider";
import CreateUserModal from "../createUserModal";
import Error from "../abstractComponents/error";
import Notification from "../notification";
import ErrorBoundary from "../abstractComponents/errorBoundary";

export default function NavBar(): JSX.Element {
  const { signOut, isActivatedUser, isAdminUser } = useAuth();
  const [isEditWindowOpen, setIsEditWindowOpen] = useState(false);
  const { isBayerUser, isTestUser } = useUserHelper();

  function handleLogoutClick() {
    signOut();
  }
  const [deleteTestRepos, { error }] = useMutation(DELETE_TEST_REPOS_MUTATION, {
    onError(err) {
      console.log(err);
    }
  });

  function deleteTestRepositories() {
    deleteTestRepos();
  }

  return (
    <>
      {isEditWindowOpen && (
        <CreateUserModal isPopupVisibleFunction={setIsEditWindowOpen} />
      )}
      {isActivatedUser && (
        <Navbar
          sticky="top"
          collapseOnSelect
          expand="lg"
          variant="light"
          className="upper_menu"
        >
          <Navbar.Brand href="/" className="logo_navbar">
            <img src="/images/Bayer-PHIL.svg" alt="PHIL_logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link id="activities_link" href="/activities">
                Activities
              </Nav.Link>
              <Nav.Link id="actions_link" href="/actions">
                Actions
              </Nav.Link>
              {isAdminUser && (
                <Nav.Link id="audit_link" href="/audit">
                  Audit Trail
                </Nav.Link>
              )}
              {(isTestUser || isAdminUser) && (
                <Nav.Link href="/token">Token</Nav.Link>
              )}
            </Nav>
            <ErrorBoundary>
              <Notification />
            </ErrorBoundary>
            <NavDropdown
              title="More"
              className="collapsable_phil_menu"
              id="collasible-nav-dropdown"
            >
              {isBayerUser && (
                <>
                  <NavDropdown.Item
                    className="create_user_button"
                    onClick={() => setIsEditWindowOpen(true)}
                  >
                    <PersonAddOutlinedIcon /> Create User
                  </NavDropdown.Item>
                </>
              )}
              <NavDropdown.Item href="/support">
                <ChatOutlineIcon /> Contact Us
              </NavDropdown.Item>
              <NavDropdown.Item href="/external">
                <HelpOutlineIcon /> Help
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="log_out" onClick={handleLogoutClick}>
                <ExitToAppOutlinedIcon /> Log Out
              </NavDropdown.Item>
            </NavDropdown>

            {isTestUser && (
              <Button
                className="delete_test_repositories"
                onClick={deleteTestRepositories}
              >
                DELETE TEST REPOSITORIES
              </Button>
            )}
          </Navbar.Collapse>
        </Navbar>
      )}
      {error && <Error error={error} />}
    </>
  );
}
