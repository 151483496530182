import { useState, useContext } from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useBrowseRepositoryHook } from "../../hooks/browseRepositoryHook";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { GetRepoObjectQueryData } from "../../backend/types";
import { TreeViewListFilesInRepo } from "./treeViewListFilesInRepo";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TableViewListFilesInRepo } from "./tableViewListFilesInRepo";
import { FilePreview } from "./filePreview";
import EditAction from "../editAction";
import ActionsOfFiles from "../contextMenu/actionsOfFiles";
import TracebilityReport from "../contextMenu/tracebilityReport";
import NavBarPmxActivity from "./navbarPmxActivity";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Success from "../abstractComponents/success";

export const FileExplorer = () => {
  const {
    treeHierarchy,
    isCreateFolderWindowOpen,
    setIsCreateFolderWindowOpen,
    isLoadingCreateFolderMutation,
    errorCreateFolderMutation,
    setCurrentSelectedPath,
    setCurrentSelectedVersionId,
    setCurrentSelectedRevision,
    error,
    createFolder,
    currentSelectedPath,
    currentSelectedVersionId,
    currentSelectedRevision,
    refetchRepoObjects,
    isActionOfFilesVisible,
    setIsActionOfFilesVisible,
    isTracebilityReportVisible,
    setIsTracebilityReportVisible,
    isLoading,
    tableMenuWasOpenOnPath,
    setTableMenuWasOpenOnPath,
    onClosePropertiesModal,
    onCloseTracebilityModal,
    setForceRefresh,
    isForceRefreshRunning,
    selectedCommentIdFromMenu,
    setSelectedCommentIdFromMenu,
    isEditActionVisible,
    setIsEditActionVisible,
    isProcessingExport,
    setIsProcessingExport,
    openPropertiesModal,
    setIsCopyMoveModalOpenHook,
    isPreselectedRevision,
    setIsPreselectedRevision,
    setCurrentSelectedRepoId,
    currentSelectedRepoId,
    currentFileList,
    dataForceRefresh,
    getRepoObjects,
    repoObjects,
    repoObjectsError,
    refetchRepoObjectsForPath,
    areRepoObjectsLoading
  } = useBrowseRepositoryHook();

  const { isActivityLoading, errorActivity, activityId, activityData } =
    useContext(PmxActivityDetailsContext);

  const onNodeSelectFunction = (
    path: string,
    versionId: string = "",
    revision: string = "",
    repoId: number | null = currentSelectedRepoId || null
  ) => {
    setCurrentSelectedPath(path);

    let currentRevision = revision;

    if (!versionId && !path?.endsWith("/")) {
      versionId =
        repoObjects?.find((element: any) => element.name === path)?.versionId ||
        "";
    }
    if (!currentRevision && !path?.endsWith("/")) {
      currentRevision = String(
        repoObjects?.find((element: any) => element.versionId === versionId)
          ?.revision
      );
    }

    // otherwise see folders as files
    if (!path.endsWith("/")) {
      setCurrentSelectedVersionId(versionId!);
    } else {
      setCurrentSelectedVersionId("");
    }
    setCurrentSelectedRevision(currentRevision!);
    setCurrentSelectedRepoId(repoId);
  };

  const isTreeViewVisibleStorage = () => {
    if (
      localStorage.getItem("isTreeViewVisibleStorage") &&
      localStorage.getItem("isTreeViewVisibleStorage") !== "undefined" &&
      localStorage.getItem("isTreeViewVisibleStorage") !== ""
    ) {
      if (localStorage.getItem("isTreeViewVisibleStorage") === "false") {
        return false;
      } else {
        return true;
      }
    } else {
      localStorage.setItem("isTreeViewVisibleStorage", String(true));
      return true;
    }
  };

  const [isTreeViewVisible, setIsTreeViewVisible] = useState(
    isTreeViewVisibleStorage()
  );

  const onClickToggleTreeViewButton = () => {
    localStorage.setItem(
      "isTreeViewVisibleStorage",
      String(!isTreeViewVisible)
    );
    setIsTreeViewVisible(!isTreeViewVisible);
  };

  return (
    <>
      <NavBarPmxActivity
        refetchRepoObjects={refetchRepoObjects}
        activeIndex={0}
        onNodeSelect={onNodeSelectFunction}
        selectedCommentIdFromMenu={selectedCommentIdFromMenu}
        setSelectedCommentIdFromMenu={setSelectedCommentIdFromMenu}
      />
      <div className="app_content pmx_app_content">
        {!activityData &&
          !currentFileList &&
          (isActivityLoading || isLoading) && (
            <div className="center_div">
              <br />
              <Spinner animation="border" className="spinner_color" />
              <p>Loading...</p>
            </div>
          )}
        {activityData && currentFileList && (
          <>
            <Container fluid className="browse-pmx-activity-container">
              <Row className="pmx-activity-row-container">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  className="float-start pmx_bradcrumbs"
                >
                  <Link to={`/activity/${activityId}`}>
                    {activityData.trialNumber}
                  </Link>
                  ,<Typography>File Explorer</Typography>
                  {(isLoadingCreateFolderMutation ||
                    isForceRefreshRunning ||
                    isLoading ||
                    isProcessingExport) && (
                    <Spinner animation="border" className="spinner_color" />
                  )}
                </Breadcrumbs>
                <br />
                <hr />
                <div
                  className={`${
                    isTreeViewVisible ? "d-block" : "d-none"
                  } w-25 tree-view-column text-break tree_view_column`}
                >
                  {treeHierarchy?.map((repoQuery: GetRepoObjectQueryData) => (
                    <ErrorBoundary key={repoQuery.repo.id}>
                      <TreeViewListFilesInRepo
                        repo={repoQuery}
                        onNodeSelect={onNodeSelectFunction}
                        currentSelectedPath={currentSelectedPath}
                        currentSelectedRepoId={currentSelectedRepoId}
                        setCurrentSelectedRepoId={setCurrentSelectedRepoId}
                      />
                    </ErrorBoundary>
                  ))}
                </div>
                <div className="align-self-start mt-2 w-auto h-100">
                  <Button
                    onClick={onClickToggleTreeViewButton}
                    className={"close_tree_view_button"}
                    variant="light"
                  >
                    {isTreeViewVisible ? (
                      <ArrowBackIosNewIcon />
                    ) : (
                      <ArrowForwardIosIcon />
                    )}
                  </Button>
                </div>
                <div
                  className={
                    isTreeViewVisible
                      ? "table_view_column flex-grow-1"
                      : "table_view_column_closed flex-grow-1"
                  }
                >
                  {(!currentSelectedPath?.endsWith("/") &&
                    currentSelectedPath !== "") ||
                  currentSelectedVersionId ? (
                    <ErrorBoundary>
                      <FilePreview
                        absolutePath={currentSelectedPath}
                        versionId={currentSelectedVersionId}
                        revision={currentSelectedRevision}
                        setTableMenuWasOpenOnPath={setTableMenuWasOpenOnPath}
                        setIsActionOfFilesVisible={setIsActionOfFilesVisible}
                        openPropertiesModal={openPropertiesModal}
                        setIsPreselectedRevision={setIsPreselectedRevision}
                        setIsTracebilityReportVisible={
                          setIsTracebilityReportVisible
                        }
                        repoId={currentFileList!.repo!.id!}
                      />
                    </ErrorBoundary>
                  ) : (
                    <ErrorBoundary>
                      <TableViewListFilesInRepo
                        currentSelectedPath={currentSelectedPath}
                        currentFileList={currentFileList}
                        files={currentFileList.repoObjects}
                        onNodeSelect={onNodeSelectFunction}
                        tree={
                          treeHierarchy?.find(
                            (repo: GetRepoObjectQueryData) =>
                              repo.repo.id === String(currentSelectedRepoId)
                          )?.repoObjects || []
                        }
                        createFolderMutation={createFolder}
                        setIsCreateFolderWindowOpen={
                          setIsCreateFolderWindowOpen
                        }
                        isCreateFolderWindowOpen={isCreateFolderWindowOpen}
                        refetchRepoObjects={refetchRepoObjects}
                        tableMenuWasOpenOnPath={tableMenuWasOpenOnPath}
                        setTableMenuWasOpenOnPath={setTableMenuWasOpenOnPath}
                        setIsActionOfFilesVisible={setIsActionOfFilesVisible}
                        setIsTracebilityReportVisible={
                          setIsTracebilityReportVisible
                        }
                        setForceRefresh={setForceRefresh}
                        setSelectedCommentIdFromMenu={
                          setSelectedCommentIdFromMenu
                        }
                        setIsEditActionVisible={setIsEditActionVisible}
                        setIsProcessingExport={setIsProcessingExport}
                        repo={currentFileList.repo}
                        setIsCopyMoveModalOpenHook={setIsCopyMoveModalOpenHook}
                        setCurrentSelectedRepoId={setCurrentSelectedRepoId}
                        currentSelectedRepoId={String(
                          currentSelectedRepoId || null
                        )}
                        repoObjects={repoObjects}
                        getRepoObjects={getRepoObjects}
                        refetchRepoObjectsForPath={refetchRepoObjectsForPath}
                        areRepoObjectsLoading={areRepoObjectsLoading}
                      />
                    </ErrorBoundary>
                  )}
                </div>
              </Row>

              <br />
            </Container>
          </>
        )}
        {isActionOfFilesVisible && treeHierarchy && (
          <ActionsOfFiles
            activity={activityData}
            currentFileList={currentFileList}
            onNodeSelect={onNodeSelectFunction}
            onClose={onClosePropertiesModal}
            absolutePath={tableMenuWasOpenOnPath}
            versionId={
              currentSelectedVersionId
                ? currentSelectedVersionId
                : repoObjects
                  ? String(
                      repoObjects?.find(
                        (element: any) =>
                          element.name === tableMenuWasOpenOnPath
                      )?.versionId
                    )
                  : ""
            }
            isPreselectedRevision={isPreselectedRevision}
            setIsPreselectedRevision={setIsPreselectedRevision}
            refetch={refetchRepoObjectsForPath}
          />
        )}
        {isEditActionVisible && treeHierarchy && (
          <ErrorBoundary>
            <EditAction
              openDirectly={true}
              onClose={() => {
                setIsEditActionVisible(false);
              }}
              refetch={refetchRepoObjectsForPath}
              versionId={
                currentSelectedVersionId
                  ? currentSelectedVersionId
                  : repoObjects
                    ? String(
                        repoObjects?.find(
                          (element: any) =>
                            element.name === tableMenuWasOpenOnPath
                        )?.versionId
                      )
                    : ""
              }
            />
          </ErrorBoundary>
        )}
        {isTracebilityReportVisible && treeHierarchy && (
          <ErrorBoundary>
            <TracebilityReport
              activityId={activityId}
              onNodeSelect={onNodeSelectFunction}
              onClose={onCloseTracebilityModal}
              currentFile={
                currentSelectedVersionId
                  ? {
                      versionId: currentSelectedVersionId,
                      name: currentSelectedPath
                    }
                  : repoObjects?.find(
                      (element: any) => element.name === tableMenuWasOpenOnPath
                    )
              }
            />
          </ErrorBoundary>
        )}

        {(error ||
          errorActivity ||
          errorCreateFolderMutation ||
          repoObjectsError) && (
          <Error
            error={
              (error && String(error).toLowerCase().includes("failed to fetch")
                ? "The system is still processing new files and will not respond during that time. Please try again in a few minutes."
                : error) ||
              errorActivity ||
              errorCreateFolderMutation ||
              repoObjectsError
            }
          />
        )}

        {dataForceRefresh && (
          <Success message="Force refresh will finish in a few minutes. Your browser will update automatically" />
        )}
      </div>
    </>
  );
};
