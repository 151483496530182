import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  ToggleButton
} from "react-bootstrap";
import { useAddActivityMutation } from "../../backend/hooks/pmxActivity/mutationCreateActivity";
import { useGetMyPmxActivitiesQuery } from "../../backend/hooks/pmxActivity/queryGetMyPmxActivities";
import { ActivityTemplate, PmxActivity } from "../../backend/types";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import { ListActivities } from "./listActivities";
import { DataGridPro, GridSelectionModel } from "@mui/x-data-grid-pro";
import { customFilters } from "../../helpers/tableViewHelper";
import { useAuth } from "../authContextProvider";
import UserManualLink from "../abstractComponents/usermanual";
import SvnImport, { SVNType } from "../abstractComponents/svnImport";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { useGetActivityTemplatesQuery } from "../../backend/hooks/pmxActivity/queryGetActivityTemplates";
import Success from "../abstractComponents/success";

// Component used to provide the posibility to create activities, and a parent for the list of PMX Activities

const TRY_TIMES_BEFORE_QUIT = 3;
const MILISECONDS_BETWEEN_TRIES = 3000;

export default function Activities() {
  const [isCreateWindowOpen, setIsCreateWindowOpen] = useState(false);
  const [newActivityTrialNumber, setNewActivityTrialNumber] = useState("");
  const [isTrialNumberInvalid, setIsTrialNumberInvalid] = useState(false);
  const [trialNumberError, setTrialNumberError] = useState("");
  const [newRepository, setNewRepository] = useState("");
  const [bayNumber, setBayNumber] = useState("");
  const [isBayNumberInvalid, setIsBayNumberInvalid] = useState(false);
  const [bayNumberError, setBayNumberError] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isProjectIdInvalid, setIsProjectIdInvalid] = useState(false);
  const [projectIdError, setProjectIdError] = useState("");
  const [trialShortDescription, setTrialShortDescription] = useState("");
  const [finalError, setFinalError] = useState("");
  const [retryTimes, setRetryTimes] = useState(0);
  const [isLoadingFinal, setIsLoadingFinal] = useState(true);
  const { isBayerUser } = useUserHelper();
  const [isNewRepositoryStandard, setIsNewRepositoryStandard] = useState(true);
  const [svnDetails, setSvnDetails] = useState<any>(null);
  const [svnType, setSvnType] = useState<SVNType>(SVNType.Template);
  const [readOnlyRepoIds, setReadOnlyRepoIds] = useState<GridSelectionModel>(
    []
  );
  const alphaNumericRegex = /[^a-zA-Z0-9.:\\+-=@_/ ]/;
  const { isDataAccessManager } = useAuth();
  const [selectedActivityTemplate, setSelectedActivityTemplate] = useState("");
  const [isAsyncCall, setIsAsyncCall] = useState(false);

  useEffect(() => {
    document.title = "PHIL App - My Activities";
  }, []);

  function validateAlphaNumeric(
    value: string,
    setState: React.Dispatch<React.SetStateAction<string>>,
    setInvalid: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<string>>
  ) {
    value = value.trim();
    setState(value);
    if (value.match(alphaNumericRegex)) {
      setInvalid(true);
      setError(
        "Must only contain letters, numbers, spaces or the following symbols: .:+=@_/"
      );
    } else if (value.includes("aws")) {
      setInvalid(true);
      setError("Must not start with 'aws'.");
    } else {
      setInvalid(false);
    }
  }

  const { addNewActivity, isLoadingMutation, errorMutation, dataMutation } =
    useAddActivityMutation();

  const { activities, error, refetchActivites } =
    useGetMyPmxActivitiesQuery(false);

  const {
    getActivityTemplates,
    activityTemplatesLoading,
    errorActivityTemplates,
    activityTemplates
  } = useGetActivityTemplatesQuery();

  function identifySourceTemplate() {
    let source: any = {
      template: selectedActivityTemplate
    };
    switch (svnType) {
      case SVNType.Empty:
        source = null;
        setIsAsyncCall(false);
        break;
      case SVNType.Template:
        source = {
          template: selectedActivityTemplate
        };
        setIsAsyncCall(false);
        break;
      case SVNType.SPMRE:
        if (svnDetails?.spmReFolder != "") {
          let spmReDetails = { ...svnDetails };
          if (svnDetails?.spmReRevision === "HEAD")
            delete spmReDetails.spmReRevision;
          source = {
            spmre: spmReDetails
          };
          setIsAsyncCall(true);
        }
        break;
      case SVNType.Modspace:
        if (svnDetails?.modspaceFolder != "") {
          let modspaceDetails = { ...svnDetails };
          if (svnDetails?.modspaceRevision === "HEAD")
            delete modspaceDetails.modspaceRevision;
          source = {
            modspace: modspaceDetails
          };
          setIsAsyncCall(true);
        }
        break;
      default:
        setIsAsyncCall(false);
        break;
    }
    return source;
  }

  function saveNewActivity() {
    addNewActivity({
      variables: {
        repoId: newRepository,
        trialNumber: newActivityTrialNumber,
        bayNumber,
        trialShortDescription,
        projectId,
        isStandard: isNewRepositoryStandard,
        readOnlyRepositories: readOnlyRepoIds.map((id) => Number(id)),
        source: identifySourceTemplate()
      }
    }).then(() => {
      if (!error) {
        refetchActivites();
        setIsCreateWindowOpen(false);
        setNewRepository("");
        setNewActivityTrialNumber("");
        setSvnType(SVNType.Template);
        setSelectedActivityTemplate("");
      }
    });
  }

  function resetCreateActivityForm() {
    setBayNumber("");
    setTrialShortDescription("");
    setProjectId("");
    setReadOnlyRepoIds([]);
    setNewRepository("");
    setNewActivityTrialNumber("");
    setSvnDetails(null);
    setSvnType(SVNType.Template);
    setSelectedActivityTemplate("");
  }

  useEffect(() => {
    if (activityTemplates?.getActivityTemplates.length > 0) {
      setSelectedActivityTemplate(
        activityTemplates?.getActivityTemplates[0].name
      );
    }
  }, [activityTemplates]);

  // we call the API 3 times before showing an error, so Lambda will not always fail
  useEffect(() => {
    if (error?.message) {
      if (retryTimes < TRY_TIMES_BEFORE_QUIT) {
        setTimeout(function () {
          refetchActivites();
          setRetryTimes(retryTimes + 1);
        }, MILISECONDS_BETWEEN_TRIES);
      } else {
        setFinalError(error?.message);
        setIsLoadingFinal(false);
      }
    }
  }, [error, retryTimes]);

  useEffect(() => {
    if (activities) {
      setIsLoadingFinal(false);
    }
  }, [activities]);

  return (
    <div className="app_content">
      {(isLoadingFinal || isLoadingMutation) && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>{error ? "Retrying..." : "Loading..."}</p>
        </div>
      )}
      {!isLoadingFinal && !isLoadingMutation && (
        <Container fluid>
          <Row>
            <Col xs={10}>
              <div className="d-inline-flex">
                <h2>My Activities</h2>
                <UserManualLink
                  url="/usermanual/concepts/#pmxactivities"
                  testId="um-activities"
                />
              </div>
            </Col>
            {isBayerUser && (
              <Col>
                <Button
                  onClick={async () => {
                    setIsCreateWindowOpen(true);
                    await getActivityTemplates();
                  }}
                  id="create_new_activity"
                  className="float-end button-secondary"
                  data-testid="create_new_activity"
                >
                  <LocalActivityOutlinedIcon /> Create New
                </Button>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <ListActivities
              activities={activities?.getMyPmxActivities}
              refetchActivities={refetchActivites}
            />
          </Row>

          <Modal
            show={isCreateWindowOpen}
            onHide={() => {
              resetCreateActivityForm();
              setIsCreateWindowOpen(false);
            }}
            dialogClassName="modal-75w"
            enforceFocus={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create New Activity</Modal.Title>
              <UserManualLink
                url="/usermanual/createrepos/"
                testId="um-new-activity"
                className="align-self-start ms-1"
              />
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className="mb-3"
                key="formTrialId"
                controlId="formTrialId"
              >
                <Form.Label>
                  Activity Name <span className="red_form">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  isInvalid={isTrialNumberInvalid}
                  placeholder="Activity Name"
                  value={newActivityTrialNumber}
                  onChange={(e) =>
                    validateAlphaNumeric(
                      e.target.value,
                      setNewActivityTrialNumber,
                      setIsTrialNumberInvalid,
                      setTrialNumberError
                    )
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {trialNumberError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                key="formBasicBayNumber"
                controlId="formBasicBayNumber"
                data-testid="formBasicBayNumber"
              >
                <Form.Label>BayNumber</Form.Label>
                <Form.Control
                  type="text"
                  required
                  isInvalid={isBayNumberInvalid}
                  placeholder="Enter BayNumber"
                  value={bayNumber}
                  onChange={(e) =>
                    validateAlphaNumeric(
                      e.target.value,
                      setBayNumber,
                      setIsBayNumberInvalid,
                      setBayNumberError
                    )
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {bayNumberError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                key="formBasicProjectId"
                controlId="formBasicProjectId"
              >
                <Form.Label>Project Id</Form.Label>
                <Form.Control
                  type="text"
                  required
                  isInvalid={isProjectIdInvalid}
                  placeholder="Enter Project ID"
                  value={projectId}
                  onChange={(e) =>
                    validateAlphaNumeric(
                      e.target.value,
                      setProjectId,
                      setIsProjectIdInvalid,
                      setProjectIdError
                    )
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {projectIdError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                key="formTrialDescription"
                controlId="formTrialDescription"
              >
                <Form.Label>Activity Short Description</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  required
                  placeholder="Enter Activity Short Description"
                  value={trialShortDescription}
                  onChange={(e) => setTrialShortDescription(e.target.value)}
                />
              </Form.Group>
              <FormControl className="mx-2 mb-2">
                <FormLabel>Source</FormLabel>
                <RadioGroup
                  id="source-radio"
                  defaultValue={svnType}
                  row
                  onChange={(e) =>
                    setSvnType(SVNType[e.target.value as keyof typeof SVNType])
                  }
                >
                  <FormControlLabel
                    value={SVNType.Empty}
                    control={<Radio />}
                    label={SVNType.Empty}
                  />
                  <FormControlLabel
                    value={SVNType.Template}
                    control={<Radio />}
                    label="General Template"
                  />
                  <FormControlLabel
                    data-testid="spmre-radio"
                    value={SVNType.SPMRE}
                    control={<Radio />}
                    label={SVNType.SPMRE}
                  />
                  <FormControlLabel
                    data-testid="modspace-radio"
                    value={SVNType.Modspace}
                    control={<Radio />}
                    label={SVNType.Modspace}
                  />
                </RadioGroup>
              </FormControl>
              {[SVNType.SPMRE, SVNType.Modspace].includes(svnType) && (
                <Accordion
                  id="svn-accordion"
                  className="mb-3"
                  defaultActiveKey="0"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Import from {svnType} source
                    </Accordion.Header>
                    <Accordion.Body>
                      <SvnImport
                        setSvnDetails={setSvnDetails}
                        svnDetails={svnDetails}
                        svnType={svnType}
                        activities={activities?.getMyPmxActivities}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {svnType === SVNType.Template && (
                <>
                  <Form.Select
                    aria-label="Template"
                    value={selectedActivityTemplate}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      setSelectedActivityTemplate(event.target.value);
                    }}
                    key="activity-templates"
                    id="activity-templates-select"
                  >
                    {activityTemplates?.getActivityTemplates.map(
                      (activityTemplate: ActivityTemplate) => (
                        <option
                          key={activityTemplate.name}
                          value={activityTemplate.name}
                        >
                          {activityTemplate.name} (
                          {activityTemplate.description})
                        </option>
                      )
                    )}
                  </Form.Select>
                  {errorActivityTemplates && (
                    <Error error={errorActivityTemplates} />
                  )}
                  <br />
                </>
              )}
              <Accordion id="readonly-accordion" className="mb-3 ">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Attach PMX Activites</Accordion.Header>
                  <Accordion.Body>
                    <DataGridPro
                      rows={
                        activities
                          ? activities?.getMyPmxActivities.filter(
                              (activity: PmxActivity) =>
                                activity.mainRepository.isStandard
                            )
                          : []
                      }
                      columns={[
                        {
                          field: "trialNumber",
                          headerName: "Trial Number",
                          width: 300,
                          filterOperators: customFilters
                        },
                        {
                          field: "bayNumber",
                          headerName: "BayNumber",
                          width: 200
                        },
                        {
                          field: "projectId",
                          headerName: "Project ID",
                          width: 200,
                          filterOperators: customFilters
                        },
                        {
                          field: "trialShortDescription",
                          headerName: "Trial Description",
                          width: 150,
                          filterOperators: customFilters
                        }
                      ]}
                      rowHeight={60}
                      pageSize={10}
                      getRowId={(row) => row.mainRepository.id}
                      selectionModel={readOnlyRepoIds}
                      onSelectionModelChange={(newSelectionModel) => {
                        setReadOnlyRepoIds(newSelectionModel);
                      }}
                      autoHeight
                      checkboxSelection
                      className="choose_read_only_repos"
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {isDataAccessManager && (
                <ButtonGroup>
                  <ToggleButton
                    type="radio"
                    variant={"outline-primary"}
                    name="isNewRepositoryStandard"
                    id="standardPublicRepository"
                    value="true"
                    checked={isNewRepositoryStandard}
                    onClick={() => setIsNewRepositoryStandard(true)}
                  >
                    Standard PMx public
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    variant={"outline-primary"}
                    name="isNewRepositoryStandard"
                    id="accessRestrictedRepository"
                    data-testid="accessRestrictedRepository"
                    value="false"
                    checked={!isNewRepositoryStandard}
                    onClick={() => setIsNewRepositoryStandard(false)}
                  >
                    Access restricted
                  </ToggleButton>
                </ButtonGroup>
              )}
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsCreateWindowOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={
                  newActivityTrialNumber === "" ||
                  isTrialNumberInvalid ||
                  isBayNumberInvalid ||
                  isProjectIdInvalid
                }
                onClick={saveNewActivity}
              >
                Create New
              </Button>
            </Modal.Footer>
          </Modal>
          <br />
        </Container>
      )}
      {(finalError || errorMutation) && (
        <Error error={finalError || errorMutation} />
      )}
      {dataMutation && isAsyncCall && (
        <Success message="Activity file import can take up to a few hours" />
      )}
    </div>
  );
}
