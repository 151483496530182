import { gql, useLazyQuery } from "@apollo/client";
export const GET_CREATIONAL_ACTIONS = gql`
  query getCreationalActions($versionIds: [ID!]!) {
    getCreationalActions(input: { versionIds: $versionIds }) {
      __typename
      id
      actionType
      actionFile {
        actionFileType
        file {
          __typename
          name
          versionId
          revision
          isValid
          repo {
            name
            id
          }
        }
      }
      startDatetime
      sourceFolder
      sourceFiles
      user {
        email
      }
      description
      actionQualityCheckStatus
      pmxActivity {
        id
        trialNumber
        mainRepository {
          __typename
          id
          name
        }
      }
      actionStatus
      actionSource
      actionStatusDescription
      actionHistory {
        id
        updated
        description
        actionQualityCheckStatus
        userId
      }
      historyLabel {
        __typename
        id
        name
      }
      hasAdditionalFilesLinked
      additionalDetails
      jobId
    }
  }
`;

export function useCreationalActionsLazyQuery() {
  const [
    getCreationalActions,
    {
      data: creationalActions,
      loading: isLoadingCreationalActions,
      error: errorCreationalActions,
      refetch: refetchCreationalActions
    }
  ] = useLazyQuery(GET_CREATIONAL_ACTIONS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true
  }); // TODO: Query results is not stored in the cache to avoid a mix up in actionFile
  return {
    getCreationalActions,
    refetchCreationalActions,
    creationalActions,
    isLoadingCreationalActions,
    errorCreationalActions
  };
}
