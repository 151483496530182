import { Button, Modal } from "react-bootstrap";
import { FileTreeView, FileType } from "./fileTreeView";
import { RepoObject } from "../../backend/types";

interface FileSelectModalProps {
  setModalAction: (modelConfig: { open: boolean }) => void;
  updateFileSelection: (fileType: FileType, node: any) => void;
  onConfirmModal: () => void;
  modelConfig: { open: boolean; fileType?: string | any; text?: string | any };
  selectedFile: string;
  isFileHidden?: (file: RepoObject) => boolean;
  treeSelection?: any;
  parentLoading?: boolean[];
}

export function FileSelectModal(props: FileSelectModalProps) {
  const onCloseModal = () => {
    props.setModalAction({ open: false });
  };

  const onConfirmModal = () => {
    props.onConfirmModal();
    props.setModalAction({ open: false });
  };

  return (
    <Modal
      dialogClassName="modal-33w"
      className="file-select-modal"
      show={props.modelConfig.open}
      onHide={() => props.setModalAction({ open: false })}
    >
      <Modal.Header closeButton className="qc_badge">
        <Modal.Title id="custom-modal-styling-title">
          {props.modelConfig.text}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileTreeView
          filesSelection={props.selectedFile}
          updateFileSelection={props.updateFileSelection}
          fileType={props.modelConfig.fileType}
          isFileHidden={props.isFileHidden}
          treeSelection={props.treeSelection}
          parentLoading={props.parentLoading}
        />
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button variant="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="confirm-button"
          onClick={onConfirmModal}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
