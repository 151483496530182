import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
export const LIST_IMAGES = gql`
  query listImages($limit: Int!, $cursor: String) {
    listImages(input: { limit: $limit, cursor: $cursor }) {
      nextCursor
      images {
        imageId
        imageName
        platformDetails
        architecture
        createdAt
        software {
          name
          version
        }
      }
    }
  }
`;

export function useListImagesQuery(
  limit: number = 5,
  cursor: string | null | undefined = null
) {
  const {
    data: images,
    loading: isLoadingListImages,
    error: errorListImages,
    fetchMore: fetchMoreImages
  } = useQuery(LIST_IMAGES, {
    variables: { limit, cursor },
    context: { clientName: "interactive" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const listMoreImages = (limit: number, nextCursor: string) =>
    fetchMoreImages({
      variables: {
        limit: limit,
        cursor: nextCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.listImages.images = [
          ...(prevResult?.listImages?.images
            ? prevResult.listImages.images
            : []),
          ...fetchMoreResult.listImages.images
        ];
        return fetchMoreResult;
      }
    });

  useEffect(() => {
    if (images && images.listImages.nextCursor) {
      listMoreImages(limit, images.listImages.nextCursor);
    }
  }, [images]);

  return {
    images: images?.listImages?.images,
    isLoadingListImages,
    errorListImages
  };
}
