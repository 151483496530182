import { gql, useMutation } from "@apollo/client";

export const MUTATION_UPDATE_ENVIRONMENT = gql`
  mutation updateEnvironmentSettings(
    $environmentId: ID!
    $instanceTypeId: String!
  ) {
    updateEnvironmentSettings(
      input: { environmentId: $environmentId, instanceTypeId: $instanceTypeId }
    )
  }
`;

export function useUpdateEnvironmentSettingsMutation() {
  const [
    updateEnvironmentSettings,
    {
      loading: isLoadingEnvironmentSettings,
      error: errorEnvironmentSettings,
      data: updateEnvironmentSettingsData
    }
  ] = useMutation(MUTATION_UPDATE_ENVIRONMENT, {
    context: { clientName: "interactive" }
  });

  return {
    updateEnvironmentSettings,
    isLoadingEnvironmentSettings,
    errorEnvironmentSettings,
    updateEnvironmentSettingsData
  };
}
