import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_SVN_CONTENT = gql`
  query getSpmReFolders(
    $username: String!
    $encryptedPassword: String!
    $search: String!
  ) {
    getSpmReFolders(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        search: $search
      }
    ) {
      spmReFolders
    }
  }
`;

export function useGetSvnMetadataQuery() {
  const [getSpmReFolders, { error, loading, data }] = useLazyQuery(
    QUERY_GET_SVN_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getSpmReFolders,
    errorMetadata: error,
    loadingMetadata: loading,
    metadata: data
  };
}
