import { gql, useMutation } from "@apollo/client";

export const MUTATION_SUBMIT_EXECUTION_JOB = gql`
  mutation submitJob(
    $name: String!
    $imageId: ID!
    $activityId: ID!
    $actionType: ActionType!
    $qualityCheckStatus: QualityCheckStatus
    $entrypoint: RepoObjectInput!
    $workingDir: String!
    $description: String
    $inputs: [RepoObjectInput!]
    $command: String
    $environmentVariables: [EnvironmentVariableInput]
    $timeout: Int
    $memory: Int
    $vCPU: Int
    $numOfNodes: Int
  ) {
    submitJob(
      input: {
        name: $name
        imageId: $imageId
        activityId: $activityId
        actionType: $actionType
        qualityCheckStatus: $qualityCheckStatus
        entrypoint: $entrypoint
        workingDir: $workingDir
        description: $description
        inputs: $inputs
        environmentVariables: $environmentVariables
        command: $command
        timeout: $timeout
        memory: $memory
        vCPU: $vCPU
        numOfNodes: $numOfNodes
      }
    ) {
      id
      status
      createdAt
      startedAt
      createdBy
      activityId
      actionId
      actionType
      qualityCheckStatus
      entrypoint {
        repositoryId
        versionId
      }
      workingDir
      inputs {
        repositoryId
        versionId
      }
      timeout
      name
      description
      vCPU
      memory
      numOfNodes
      environmentVariables {
        name
        value
      }
    }
  }
`;

export function useSubmitExecutionJobMutation() {
  const [
    submitJob,
    {
      data: executionJob,
      loading: isSubmitExecutionJobLoading,
      error: errorSubmitExecutionJob
    }
  ] = useMutation(MUTATION_SUBMIT_EXECUTION_JOB, {
    context: { clientName: "execution" }
  });

  return {
    submitJob,
    executionJob,
    isSubmitExecutionJobLoading,
    errorSubmitExecutionJob
  };
}
