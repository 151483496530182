import { gql, useMutation } from "@apollo/client";

export const MUTATION_GET_MULTIPLART_UPLOAD_LINK = gql`
  mutation getMultipartUploadLinks(
    $activityId: ID!
    $absolutePath: String!
    $uploadId: String!
    $partNumbers: [Int]!
  ) {
    getMultipartUploadLinks(
      input: {
        absolutePath: $absolutePath
        uploadId: $uploadId
        activityId: $activityId
        partNumbers: $partNumbers
      }
    ) {
      presignedUrls {
        partNumber
        url
      }
    }
  }
`;

export function useGetMultipartUploadLink() {
  const [
    getMultipartUploadLink,
    {
      loading: getMulipartUploadLinkLoading,
      data: getMulipartUploadLinkData,
      error: getMulipartUploadLinkError
    }
  ] = useMutation(MUTATION_GET_MULTIPLART_UPLOAD_LINK, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    getMultipartUploadLink,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkData,
    getMulipartUploadLinkError
  };
}
