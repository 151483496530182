import React, { useState, createContext, useContext } from "react";
import { GetRepoObjectQueryData } from "../backend/types";
import { useGetRepoObjectsQuery } from "../backend/hooks/pmxActivity/queryGetRepoObjects";
import { PmxActivityDetailsContext } from "./pmxActivityDetailsProvider";
import getTreeHierarchyFromRepoObjectList from "../helpers/treeHelper";

type BrowsePmxActivityType = {
  treeHierarchyGlobal: GetRepoObjectQueryData[] | [];
  setTreeHierarchyGlobal: Function;
  refetchRepoObjects: Function;
  isLoadingGetRepoObjects: boolean;
  repos?: any;
  error?: any; // what is type
};

type CaughtProviderProps = {
  children: React.ReactNode;
};

export const BrowsePmxActivityContext = createContext<BrowsePmxActivityType>(
  {} as BrowsePmxActivityType
);

export const BrowsePmxActivityProvider: React.FC<CaughtProviderProps> = ({
  children
}) => {
  const { activityId } = useContext(PmxActivityDetailsContext);
  const [treeHierarchyGlobal, setTreeHierarchyGlobal] = useState<
    GetRepoObjectQueryData[]
  >([]);
  const [repoObjects, setRepoObjects] = useState<GetRepoObjectQueryData[]>([]);

  const { refetchRepoObjects, isLoading } = useGetRepoObjectsQuery(
    activityId,
    false,
    (data: any) => {
      setRepoObjects(data);
      const tree = getTreeHierarchyFromRepoObjectList(data);
      setTreeHierarchyGlobal(tree);
    },
    treeHierarchyGlobal.length > 0
  );

  return (
    <BrowsePmxActivityContext.Provider
      value={{
        treeHierarchyGlobal,
        setTreeHierarchyGlobal,
        refetchRepoObjects,
        repos: repoObjects,
        isLoadingGetRepoObjects: isLoading
      }}
    >
      {children}
    </BrowsePmxActivityContext.Provider>
  );
};
