import { gql, useMutation } from "@apollo/client";

export const ACTIVATE_USER_MUTATION = gql`
  mutation activateUser {
    activateUser
  }
`;

export function useActivateUserMutation() {
  const [
    activateUserMutation,
    { loading: isLoadingMutation, error: errorMutation }
  ] = useMutation(ACTIVATE_USER_MUTATION);

  return {
    activateUserMutation,
    isLoadingMutation,
    errorMutation
  };
}
