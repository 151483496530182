import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { replaceLast } from "../../../helpers/stringHelper";
import Error from "../../abstractComponents/error";
import Success from "../../abstractComponents/success";
import { PmxActivityDetailsContext } from "../../../contexts/pmxActivityDetailsProvider";

import { useUncompressArchiveMutation } from "../../../backend/hooks/contextMenu/fileOperations/mutationUncompressArchive";

export default function UncompressModal(props: {
  isUncompressModalOpen: boolean;
  closeUncompressModal: any;
  tableMenuWasOpenOnPath: string;
  versionId: string | undefined | null;
  refetchRepoObjects: Function;
}) {
  const { activityData } = useContext(PmxActivityDetailsContext);

  const [folderName, setFolderName] = useState("");
  const [shouldUncompressNewFolder, setShouldUncompressNewFolder] =
    useState("yes");
  const {
    uncompressArchiveMutation,
    isLoadingUncompressMutation,
    errorUncompressMutation,
    uncompressData
  } = useUncompressArchiveMutation();

  useEffect(
    () =>
      setFolderName(
        replaceLast(
          ".zip",
          "",
          String(props.tableMenuWasOpenOnPath.split("/").at(-1))
        )
      ),
    [props.tableMenuWasOpenOnPath, props.isUncompressModalOpen]
  );

  const submitData = () => {
    uncompressArchiveMutation({
      variables: {
        pmxActivityId: Number(activityData.id),
        versionId: props.versionId,
        subfolder: shouldUncompressNewFolder === "no" ? null : folderName
      }
    }).then(() => {
      props.refetchRepoObjects();
      props.closeUncompressModal();
      setShouldUncompressNewFolder("yes");
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.isUncompressModalOpen}
        onHide={props.closeUncompressModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Uncompress {props.tableMenuWasOpenOnPath}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingUncompressMutation && (
            <Spinner animation="border" className="spinner_color" />
          )}
          <p>This will uncompress the following file:</p>
          <h4>{props.tableMenuWasOpenOnPath}</h4>
          <br />
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Check // prettier-ignore
              type="switch"
              id="uncompress_on_same_floder"
              label="Uncompress in a new folder?"
              value={shouldUncompressNewFolder}
              defaultChecked={true}
              onChange={(e) => {
                e.target.checked
                  ? setShouldUncompressNewFolder("yes")
                  : setShouldUncompressNewFolder("no");
              }}
            />
          </Form.Group>

          <Form.Group
            className={
              shouldUncompressNewFolder === "yes" ? "mb-3" : "mb-3 grey_color"
            }
            controlId="formBasicName"
          >
            <Form.Label>
              Name of Folder{" "}
              {shouldUncompressNewFolder === "yes" && (
                <span className="red_form">*</span>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              required={shouldUncompressNewFolder === "no"}
              placeholder="Enter name of folder"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              disabled={shouldUncompressNewFolder === "no"}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeUncompressModal}>
            Cancel
          </Button>
          <Button onClick={submitData}>Uncompress</Button>
        </Modal.Footer>
      </Modal>

      {errorUncompressMutation && <Error error={errorUncompressMutation} />}
      {uncompressData?.uncompressArchive && (
        <Success message="Uncompress started. It can take up to 15 Minutes." />
      )}
    </>
  );
}
