import { Container, Spinner, Button } from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDownloadExternalLinkQuery } from "../../backend/hooks/externalLink/queryGetExternalDownloadLink";

interface ParamsDownload {
  downloadId: string;
}

// Create Download Link, that can be used by external Users
export default function ExternalDownloadLink() {
  const downloadId = (useParams() as ParamsDownload).downloadId;

  const { getDownloadLink, downloadUrls, errorDownload, downloadIsLoading } =
    useDownloadExternalLinkQuery();

  useEffect(() => {
    downloadUrls?.getExternalDownloadLink?.forEach((url: any) => {
      window.open(url?.url);
    });
  }, [downloadUrls]);

  return (
    <>
      <Container fluid>
        <h3>Welcome to PHIL Download Utility</h3>
        <p>
          <Button
            onClick={() =>
              getDownloadLink({
                variables: {
                  id: downloadId
                }
              })
            }
          >
            Download My File
          </Button>
        </p>
        {downloadIsLoading && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>Generating Link...</p>
          </div>
        )}
      </Container>
      {errorDownload && <Error error={errorDownload} />}
    </>
  );
}
