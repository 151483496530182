import { gql, useMutation } from "@apollo/client";

export const COPY_OBJECTS_MUTATION = gql`
  mutation copyObjects(
    $activityId: ID!
    $absolutePaths: [String]!
    $destinationPath: String!
    $override: Boolean
    $comment: String
    $repoIdOrigin: ID
  ) {
    copyObjects(
      input: {
        activityId: $activityId
        absolutePaths: $absolutePaths
        destinationPath: $destinationPath
        override: $override
        comment: $comment
        repoIdOrigin: $repoIdOrigin
      }
    )
  }
`;

export function useCopyObjectsMutation() {
  const [
    copyObjectsMutation,
    { loading: isCopyObjectsLoading, error: copyObjectsError }
  ] = useMutation(COPY_OBJECTS_MUTATION);

  return {
    copyObjectsMutation,
    isCopyObjectsLoading,
    copyObjectsError
  };
}
