export interface URLObject {
  activityId: number;
  path: string;
  versionId?: string;
  openProperties?: boolean;
  repoId?: number;
  commentId?: number;
}
export const COMMENT_ID_LABEL = "comment_id_";

export function extractIdAndPathFromParameter(param: string): URLObject {
  const url = decodeURIComponent(param).split("?");
  let pair = null,
    data: any = {},
    params;

  if (url.length > 1) {
    if (url[1].includes("&")) {
      params = url[1].split("&");
      params.forEach(function (d) {
        pair = d.split("=");
        data[pair[0]] = pair[1];
      });
    } else if (url[1].includes("versionId")) {
      params = url[1].split("versionId=");
      data["versionId"] = params[1];
    } else if (url[1].includes("repoId")) {
      params = url[1].split("repoId=");
      data["repoId"] = params[1];
    } else if (url[1].includes("commentId")) {
      params = url[1].split("commentId=");
      data["commentId"] = params[1];
    }
  }

  const decodedParam = decodeURIComponent(url[0]).split("/");
  const activityId = Number(decodedParam[0]);
  decodedParam.shift(); //remove the first element

  return {
    activityId,
    path: decodedParam.join("/"),
    ...data
  };
}

// takes a string of form "/home/parent/child" and returns "["/", "Home", "home/child"]"
export function extractTreeViewArrayFromURL(absolutePath: string): string[] {
  const finalArray = ["/"];
  const splitPath = absolutePath.split("/");

  for (const key in splitPath) {
    let collectString = "";
    for (const keyInner in splitPath) {
      if (key >= keyInner) {
        collectString = collectString + splitPath[keyInner] + "/";
      }
    }
    if (collectString !== "/") {
      finalArray.push(collectString);
    }
  }

  return finalArray;
}

export function getPreviewURL(
  activityId: string,
  versionId: string,
  absolutePath: string,
  repoId: string,
  openPropertiesModal: boolean = false
) {
  return (
    "/activity/" +
    activityId +
    encodeURIComponent("/" + absolutePath) +
    encodeURIComponent("?versionId=" + versionId) +
    encodeURIComponent("&repoId=" + repoId) +
    (openPropertiesModal ? encodeURIComponent("&openProperties=true") : "")
  );
}
