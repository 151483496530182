import { Alert } from "react-bootstrap";
import { useState } from "react";
import { ApolloError } from "@apollo/client";

// Component used as a popup when an error comes from GraphQL
export default function Error(props: { error?: string | ApolloError }) {
  const [show, setShow] = useState(true);

  return (
    <Alert
      className="center_div alert_error fixed_bottom"
      onClose={() => setShow(false)}
      show={show}
      dismissible
      variant="danger"
    >
      {props.error ? (
        <p>
          Error:{" "}
          {props.error instanceof ApolloError
            ? props.error.networkError?.message === "Failed to fetch"
              ? "Your session expired or server is offline"
              : props.error.message
            : props.error}
        </p>
      ) : (
        <p>There was an error</p>
      )}
    </Alert>
  );
}
