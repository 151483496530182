import { gql, useMutation } from "@apollo/client";

export const EDIT_PMX_ACTIVITY_MUTATION = gql`
  mutation editPmxActivity(
    $id: ID!
    $bayNumber: String
    $projectId: String
    $trialNumber: String!
    $trialShortDescription: String
    $isStandard: Boolean
    $readOnlyRepositories: [Int]
  ) {
    editPmxActivity(
      input: {
        id: $id
        trialNumber: $trialNumber
        bayNumber: $bayNumber
        trialShortDescription: $trialShortDescription
        projectId: $projectId
        isStandard: $isStandard
        readOnlyRepositories: $readOnlyRepositories
      }
    )
  }
`;

export function useEditPmxActivityMutation() {
  const [editActivity, { loading: isLoadingMutation, error: errorMutation }] =
    useMutation(EDIT_PMX_ACTIVITY_MUTATION);

  return {
    editActivity,
    isLoadingMutation,
    errorMutation
  };
}
