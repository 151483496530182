import { gql, useLazyQuery } from "@apollo/client";
export const GET_UPLOAD_LINKS = gql`
  query getExternalUploadLinks(
    $id: ID!
    $absolutePaths: [String]!
    $md5sums: [String]!
    $qualityCheckStatus: QualityCheckStatus
    $uncompressOnUpload: Boolean
    $description: String
  ) {
    getExternalUploadLinks(
      input: {
        id: $id
        absolutePaths: $absolutePaths
        md5sums: $md5sums
        qualityCheckStatus: $qualityCheckStatus
        uncompressOnUpload: $uncompressOnUpload
        description: $description
      }
    ) {
      actionId
      actionStatus
      presignedUrls {
        url
        fields
      }
    }
  }
`;

export function useDownloadExternalLinkQuery() {
  const [
    getUploadLink,
    { data: uploadData, loading: uploadIsLoading, error: errorUpload }
  ] = useLazyQuery(GET_UPLOAD_LINKS, {
    fetchPolicy: "no-cache",
    context: {
      clientName: process.env.REACT_APP_ENV !== "local" ? "appsyncapi" : ""
    },
    onError(err: any) {
      console.error(err);
    }
  });
  return {
    getUploadLink,
    uploadData,
    errorUpload,
    uploadIsLoading
  };
}
