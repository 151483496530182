export const popupImage = (selector: string) => {
  // we cannot know how long it takes for the images to render, so we keep assigning the event listener
  setTimeout(() => assignOnClickToImages(selector), 100);
  setTimeout(() => assignOnClickToImages(selector), 1000);
  setTimeout(() => assignOnClickToImages(selector), 5000);
  setTimeout(() => assignOnClickToImages(selector), 10000);
  setTimeout(() => assignOnClickToImages(selector), 30000);
};

const assignOnClickToImages = (selector: string) => {
  const elements = document.querySelectorAll(selector + " img");
  const modal = document.getElementById("myModal");
  const modalImg = document.getElementById("img01") as HTMLImageElement;
  const captionText = document.getElementById("caption");
  const span = document.getElementsByClassName("close")[0];
  span.addEventListener("click", () => {
    modal!.style.display = "none";
  });
  [].map.call(elements, (elem: HTMLImageElement) => {
    if (elem) {
      elem.addEventListener("click", () => {
        modal!.style.display = "block";
        modalImg!.src = elem.src;
        captionText!.innerHTML = elem.alt;
      });
    }
  });
};
