import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetCommentDetails } from "../../backend/hooks/queryGetCommentDetails";

interface ParamsAction {
  commentId: string;
}

//this component will redirect the users to the proper PMX activity, given the comment ID
export function Comment() {
  const commentId = (useParams() as ParamsAction).commentId;

  const { comment } = useGetCommentDetails(Number(commentId));
  useEffect(() => {
    if (comment) {
      window.location.replace(
        `/activity/${comment.pmxActivity.id}#comment_id_${commentId}`
      );
    }
  }, [comment]);

  return (
    <>
      <div className="center_div">
        <Spinner animation="border" className="spinner_color" />
        <p>Redirecting...</p>
      </div>
    </>
  );
}
