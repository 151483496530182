import Error from "./abstractComponents/error";
import MaterialButton from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useEffect, useState, useRef } from "react";
import { shortenString } from "../helpers/stringHelper";
import { usePushNotificationMutation } from "../backend/hooks/notifications/mutationPushNotifications";
import { useGetNotificationsSubscription } from "../backend/hooks/notifications/subscriptionGetNotifications";
import { useMarkNotificationMutation } from "../backend/hooks/notifications/mutationMarkNotifications";
import { Spinner } from "react-bootstrap";

import {
  Notification as NotificationTSType,
  NotificationOutcomeType,
  NotificationType
} from "../backend/appSyncTypes";

const NOTIFICATION_STRING_LENGTH = 69;
const DEFAULT_NOTIFICATION_NUMBER = 10;

export default function Notification(): JSX.Element {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [errorFromMutation, setErrorFromMutation] = useState("");
  const [currentLoadingNotificationId, setCurrentLoadingNotificationId] =
    useState<number | null>(null);
  const [notificationNumber, setNotificationNumber] = useState(
    DEFAULT_NOTIFICATION_NUMBER
  );

  const wrapperRef = useRef(null);
  const { pushNotification /*, pushNotificationError*/ } =
    usePushNotificationMutation();

  const { markNotification, markNotificationError, markNotificationLoading } =
    useMarkNotificationMutation();

  const { notificationData, isLoadingNotification, notificationError } =
    useGetNotificationsSubscription();

  useEffect(() => {
    if (!markNotificationLoading) {
      setCurrentLoadingNotificationId(null);
    }
  }, [markNotificationLoading]);
  // close modal when clicking outside
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsNotificationVisible(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const handleClickNotificationItem = (
    notificationItem: NotificationTSType
  ) => {
    if (!notificationItem.read) {
      markNotification({
        variables: {
          notificationIds: [notificationItem.id],
          numberOfNotifications: notificationNumber,
          value: !notificationItem.read
        }
      });
    }
    //Have some delay so we can actually mark the notification
    setTimeout(() => {
      if (notificationItem.notificationType === NotificationType.Action) {
        window.location.href = "/action/" + notificationItem.associationId;
      }
      if (notificationItem.notificationType === NotificationType.Comment) {
        window.location.href = "/comment/" + notificationItem.associationId;
      }
      if (notificationItem.notificationType === NotificationType.Activity) {
        window.location.href = "/activity/" + notificationItem.associationId;
      }
    }, 200);
  };

  const calculateNotificationClassFromItem = (
    notificationItem: NotificationTSType
  ): string => {
    let className = "notification_item ";
    if (notificationItem.outcomeType === NotificationOutcomeType.Error) {
      if (notificationItem.read) {
        className = className + "notification_error_read";
      } else {
        className = className + "notification_error";
      }
    }
    if (notificationItem.outcomeType === NotificationOutcomeType.Success) {
      if (notificationItem.read) {
        className = className + "notification_success_read";
      } else {
        className = className + "notification_success";
      }
    }

    return className;
  };

  const callNotifications = () =>
    setTimeout(function () {
      pushNotification({
        variables: {
          numberOfNotifications: notificationNumber
        }
      }).catch(() => {
        // If there is an error, we pull again 2 other times.
        // TODO - Remove this once the fetch
        setTimeout(function () {
          pushNotification({
            variables: {
              numberOfNotifications: notificationNumber
            }
          }).catch(() => {
            setTimeout(function () {
              pushNotification({
                variables: {
                  numberOfNotifications: notificationNumber
                }
              }).catch((error) => {
                setErrorFromMutation(String(error));
              });
            }, 3000);
          });
        }, 3000);
      });
    }, 1000);

  useEffect(() => {
    if (isNotificationVisible) {
      callNotifications();
    }
  }, [isNotificationVisible]);

  useEffect(() => {
    callNotifications();
  }, [notificationNumber]);

  return (
    <div className="notification_menu_item" ref={wrapperRef}>
      <MaterialButton
        className="float-end inner_menu_button"
        onClick={() => {
          setIsNotificationVisible(!isNotificationVisible);
        }}
        id="notification_button"
      >
        {isLoadingNotification ? (
          <NotificationsNoneOutlinedIcon className="button-color" />
        ) : (
          <Badge
            className="comments_icon"
            badgeContent={
              notificationData?.getNotifications?.notifications &&
              notificationData?.getNotifications?.notifications?.filter(
                (notificationItem: NotificationTSType) => !notificationItem.read
              ).length
            }
            color="error"
          >
            <NotificationsNoneOutlinedIcon className="button-color" />
          </Badge>
        )}
      </MaterialButton>

      {isNotificationVisible && (
        <div className="notification_area">
          {notificationData?.getNotifications?.notifications &&
            notificationData?.getNotifications?.notifications?.map(
              (notificationItem: NotificationTSType) => (
                <div
                  className={calculateNotificationClassFromItem(
                    notificationItem
                  )}
                  key={String(notificationItem.id)}
                >
                  <div
                    title={notificationItem.message}
                    className={
                      !notificationItem.read
                        ? "notification_item_area notification_item_area_unread"
                        : "notification_item_area"
                    }
                    onClick={() =>
                      handleClickNotificationItem(notificationItem)
                    }
                  >
                    <span>
                      {shortenString(
                        notificationItem.message,
                        NOTIFICATION_STRING_LENGTH
                      )}
                    </span>
                    <div
                      className={
                        !notificationItem.read
                          ? "notification_item_date notification_item_date_unread"
                          : "notification_item_date"
                      }
                    >
                      {notificationItem.createdOn}
                    </div>
                  </div>
                  <div
                    className={
                      notificationItem.read
                        ? "notification_item_mark notification_item_mark_read"
                        : "notification_item_mark"
                    }
                    onClick={() => {
                      markNotification({
                        variables: {
                          notificationIds: [notificationItem.id],
                          numberOfNotifications: notificationNumber,
                          value: !notificationItem.read
                        }
                      });
                      setCurrentLoadingNotificationId(notificationItem.id);
                    }}
                    title="Mark as read"
                  >
                    {markNotificationLoading &&
                    ((currentLoadingNotificationId &&
                      currentLoadingNotificationId === notificationItem.id) ||
                      (!currentLoadingNotificationId &&
                        !notificationItem.read)) ? (
                      <Spinner animation="grow" size="sm" />
                    ) : (
                      <div className="inner_notification_button_div">
                        &#9679;
                      </div>
                    )}
                  </div>
                  <br className="clear_float" />
                </div>
              )
            )}
          {notificationData?.getNotifications?.notifications &&
            notificationData?.getNotifications?.notifications.length > 0 && (
              <div className="notification_under_holder">
                <MaterialButton
                  variant="text"
                  className="comment_reply_link notification_20_more"
                  onClick={() => {
                    setNotificationNumber(
                      notificationNumber + DEFAULT_NOTIFICATION_NUMBER
                    );
                  }}
                >
                  +{DEFAULT_NOTIFICATION_NUMBER} more
                </MaterialButton>

                <MaterialButton
                  variant="text"
                  className="comment_reply_link notification_all_read"
                  onClick={() => {
                    markNotification({
                      variables: {
                        numberOfNotifications: notificationNumber,
                        value: true
                      }
                    });
                  }}
                >
                  Mark all as read
                </MaterialButton>
              </div>
            )}
        </div>
      )}
      {(errorFromMutation || notificationError || markNotificationError) && (
        <Error
          error={
            "Connection with notification server lost. Please refresh page."
          }
        />
      )}
    </div>
  );
}
