import { gql, useMutation } from "@apollo/client";

export const MUTATION_DELETE_COMMENT = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(input: { id: $id })
  }
`;

export function useDeleteCommentMutation() {
  const [
    deleteCommentMutation,
    { loading: loadingDeleteMutation, error: errorDeleteCommentMutation }
  ] = useMutation(MUTATION_DELETE_COMMENT);

  return {
    deleteCommentMutation,
    loadingDeleteMutation,
    errorDeleteCommentMutation
  };
}
