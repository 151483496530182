import { gql, useMutation } from "@apollo/client";

export const HIDE_OBJECTS_MUTATION = gql`
  mutation hideObjects(
    $activityId: ID!
    $absolutePaths: [String]!
    $comment: String!
  ) {
    hideObjects(
      input: {
        activityId: $activityId
        absolutePaths: $absolutePaths
        comment: $comment
      }
    )
  }
`;

export function useHideObjectsMutation() {
  const [
    hideObjectsMutation,
    { loading: isLoadingMutationHide, error: errorMutationHide }
  ] = useMutation(HIDE_OBJECTS_MUTATION);

  return {
    hideObjectsMutation,
    isLoadingMutationHide,
    errorMutationHide
  };
}
