import { gql, useQuery } from "@apollo/client";
import { DiffLocation } from "../../interactiveTypes";
export const LIST_DIFFS = gql`
  query listDiffs($environmentId: ID!, $location: DiffLocation!) {
    listDiffs(input: { environmentId: $environmentId, location: $location }) {
      computing
      lastDiffComputed
      lastSync
      repoFiles {
        repoId
        repoName
        files {
          diffId
          lastUpdated
          state
          isConflict
          path
          location
        }
      }
    }
  }
`;

export function useListDiffsQuery(
  environmentId: string,
  location: DiffLocation,
  polling: Boolean = false
) {
  const {
    data: diffs,
    loading: isLoadingListDiffs,
    error: errorListDiffs,
    refetch: refetchDiff
  } = useQuery(LIST_DIFFS, {
    context: { clientName: "interactive" },
    variables: { environmentId: environmentId, location: location },
    notifyOnNetworkStatusChange: true,
    ...(polling ? { pollInterval: 5000 } : { pollInterval: 0 }) // Query data periodically every 5 sec to query the list difference table
  });

  return {
    refetchDiff,
    diffs,
    isLoadingListDiffs,
    errorListDiffs
  };
}
