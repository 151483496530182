import { gql, useMutation } from "@apollo/client";

export const ADD_ACTION_MUTATION = gql`
  mutation createAction(
    $actionType: ActionType!
    $actionFiles: [ActionFileInputType!]!
    $activityId: ID!
    $description: String
    $sourceFolder: String
    $actionStatus: ActionStatus
    $actionSource: ActionSource
    $actionStatusDescription: String
    $qualityCheckStatus: QualityCheckStatus
  ) {
    createAction(
      input: {
        actionType: $actionType
        actionFiles: $actionFiles
        activityId: $activityId
        description: $description
        sourceFolder: $sourceFolder
        actionStatus: $actionStatus
        actionSource: $actionSource
        actionStatusDescription: $actionStatusDescription
        qualityCheckStatus: $qualityCheckStatus
      }
    ) {
      id
    }
  }
`;

export function useAddActionMutation() {
  const [
    createAction,
    { loading: createActionLoading, error: createActionError }
  ] = useMutation(ADD_ACTION_MUTATION, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    createAction,
    createActionError,
    createActionLoading
  };
}
