import { gql, useLazyQuery } from "@apollo/client";
export const GET_MY_REPO_OBJECTS_FOR_MD5_QUERY = gql`
  query getRepoObjectForMd5($md5: String!, $pmxActivityId: Int!) {
    getRepoObjectForMd5(input: { md5: $md5, pmxActivityId: $pmxActivityId }) {
      __typename
      name
      isDir
      created
      createdBy
      versionId
      revision
      md5
      isValid
      size
      repo {
        name
        id
      }
    }
  }
`;

export function useGetRepoObjectsForMd5() {
  const [
    getRepoObjectMd5,
    { data: repoObject, loading: isRepoObjectLoading, error: repoObjectError }
  ] = useLazyQuery(GET_MY_REPO_OBJECTS_FOR_MD5_QUERY);

  return {
    getRepoObjectMd5,
    repoObject,
    isRepoObjectLoading,
    repoObjectError
  };
}
