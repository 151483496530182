import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_STREAMING_URL = gql`
  mutation connectEnvironment($environmentId: ID!) {
    connectEnvironment(input: { environmentId: $environmentId })
  }
`;

export function useConnectEnvironmentMutation() {
  const [
    connectEnvironment,
    {
      data: connectionUrl,
      loading: isLoadingConnectionUrl,
      error: errorConnectionUrl
    }
  ] = useMutation(MUTATION_CREATE_STREAMING_URL, {
    context: { clientName: "interactive" }
  });

  return {
    connectEnvironment,
    connectionUrl,
    isLoadingConnectionUrl,
    errorConnectionUrl
  };
}
