import { Alert } from "react-bootstrap";
import { useState } from "react";

// Component used as a popup when an async success message comes from GraphQL
export default function Success(props: { message?: string }) {
  const [show, setShow] = useState(true);

  return (
    <Alert
      className="center_div alert_success fixed_bottom"
      onClose={() => setShow(false)}
      show={show}
      dismissible
      variant="success"
    >
      {props.message ? <p>{props.message}</p> : <p>Success</p>}
    </Alert>
  );
}
