import { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Typography } from "@mui/material";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { FileSelect, FileObjectState } from "./fileSelect";
import { useGetFileDifferences } from "../../backend/hooks/compare/queryGetFileDifferences";
import { parseDiff, Diff, Hunk } from "react-diff-view";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";

export function CompareFile() {
  const { isBayerUser } = useUserHelper();
  const { activityData } = useContext(PmxActivityDetailsContext);
  const { activityId } = useParams<{
    activityId: string;
  }>();
  const history = useHistory();

  const useQuery = () => {
    const { search } = useLocation();
    if (!search) {
      history.push(`/activity/${activityId}`);
    }
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query: any = useQuery();
  const { getFileDifferences, isLoadingFileDifferences, errorFileDifferences } =
    useGetFileDifferences();

  const [sourceFile, setSourceFile] = useState<FileObjectState>({
    versionId: query.get("sourceVersionId"),
    repoId: query.get("sourceRepoId"),
    name: "loading...",
    allVersions: []
  });
  const [targetFile, setTargetFile] = useState<FileObjectState>({
    versionId: query.get("targetVersionId")
      ? query.get("targetVersionId")
      : query.get("sourceVersionId"),
    repoId: query.get("targetRepoId")
      ? query.get("targetRepoId")
      : query.get("sourceRepoId"),
    name: "loading...",
    allVersions: []
  });
  const [parsedDifference, setParsedDifference] = useState<any>();

  const updateFileObject = (
    identifier: string,
    fileObject: FileObjectState
  ) => {
    if (identifier === "Source") {
      setSourceFile(fileObject);
    } else {
      setTargetFile(fileObject);
    }
  };

  useEffect(() => {
    getFileDifferences({
      variables: {
        sourceVersionId: sourceFile.versionId,
        sourceRepoId: sourceFile.repoId,
        targetVersionId: targetFile.versionId,
        targetRepoId: targetFile.repoId
      }
    }).then(({ data }) => {
      const [diff] = parseDiff(data.getRepoObjectsDifference.differences, {
        nearbySequences: "zip"
      });

      setParsedDifference(diff);

      query.set("sourceVersionId", sourceFile.versionId);
      query.set("sourceRepoId", sourceFile.repoId);
      query.set("targetVersionId", targetFile.versionId);
      query.set("targetRepoId", targetFile.repoId);
      history.replace({ search: query.toString() });
    });
  }, [sourceFile, targetFile]);

  const swapFiles = () => {
    const temp = { ...sourceFile };
    setSourceFile({ ...targetFile });
    setTargetFile(temp);
  };
  const isDiffEmpty = !parsedDifference || parsedDifference.hunks.length === 0;

  return (
    <>
      <NavBarPmxActivity activeIndex={0} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/`}>File Explorer</Link>
          <Typography>Compare</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        {isBayerUser && (
          <ErrorBoundary>
            <Container fluid id="compare-file-versions">
              <Row className="mb-4 gap-4">
                <FileSelect
                  identifier="Source"
                  fileObject={sourceFile}
                  updateFileObject={updateFileObject}
                />
                <SwapHorizIcon
                  id="swap-files"
                  style={{ cursor: "pointer" }}
                  className="w-auto align-self-center"
                  onClick={swapFiles}
                />
                <FileSelect
                  identifier="Target"
                  fileObject={targetFile}
                  updateFileObject={updateFileObject}
                />
              </Row>
              {isLoadingFileDifferences ? (
                <div className="center_div">
                  <br />
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              ) : isDiffEmpty ? (
                "No difference in the file content."
              ) : (
                <Diff
                  viewType="split"
                  diffType="modify"
                  hunks={parsedDifference.hunks || []}
                >
                  {(hunks) =>
                    hunks.map((hunk) => <Hunk key={hunk.content} hunk={hunk} />)
                  }
                </Diff>
              )}
            </Container>
          </ErrorBoundary>
        )}
      </div>
      {errorFileDifferences && <Error error={errorFileDifferences} />}
    </>
  );
}
