import { gql, useMutation } from "@apollo/client";

export const MUTATION_DELETE_HISTORY_LABEL = gql`
  mutation deleteHistoryLabel($actionId: ID!) {
    deleteHistoryLabel(input: { actionId: $actionId })
  }
`;

export function useDeleteHistoryLabelMutation() {
  const [
    deleteHistoryLabel,
    {
      loading: isLoadingDeleteHistoryLabelMutation,
      error: errorDeleteHistoryLabelMutation
    }
  ] = useMutation(MUTATION_DELETE_HISTORY_LABEL);

  return {
    deleteHistoryLabel,
    isLoadingDeleteHistoryLabelMutation,
    errorDeleteHistoryLabelMutation
  };
}
