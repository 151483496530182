export enum NotificationType {
  Action = "Action",
  Comment = "Comment",
  Activity = "Activity"
}

export enum NotificationOutcomeType {
  Success = "Success",
  Error = "Error"
}

export type Notification = {
  associationId: string;
  author: string;
  createdOn: string;
  id: number;
  message: string;
  notificationType: NotificationType;
  outcomeType: NotificationOutcomeType | null;
  read: boolean;
};
