// TODO: have a talk with frontend team which tool to use to generate queries
//       I just used https://transform.tools/graphql-to-typescript to generate
//       the types below from the schema definition of the backend
//       but there are also more sophisticated solutions

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum EnvironmentType {
  R = "R",
  Nonmem = "Nonmem",
  Monolix = "Monolix",
  Simlix = "Simlix",
  PsN = "PsN"
}

export enum JobStatus {
  Submitted = "SUBMITTED",
  Pending = "PENDING",
  Runnable = "RUNNABLE",
  Starting = "STARTING",
  Running = "RUNNING",
  Succeeded = "SUCCEEDED",
  Canceled = "CANCELED",
  Failed = "FAILED"
}

export enum ValidationType {
  ValidR = "ValidR",
  OpenVal = "OpenVal"
}

export enum ActionType {
  AdfCreation = "ADF_CREATION",
  PlotCreation = "PLOT_CREATION",
  ReportCreation = "REPORT_CREATION",
  ModelExecution = "MODEL_EXECUTION",
  OtherAction = "OTHER_ACTION"
}

export enum QualityCheckStatus {
  Qced = "QCED",
  QcReady = "QC_READY",
  QcFailed = "QC_FAILED"
}

export enum Sort {
  ASC = "ASC",
  DESC = "DESC"
}

export type GetJobsInput = {
  jobId?: Maybe<Scalars["ID"]>;
  status?: Maybe<JobStatus>;
  activityId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type GetJobLogsInput = {
  jobId: Scalars["ID"];
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Scalars["Int"]>;
};

export type GetExternalJobsInput = {
  jobId?: Maybe<Scalars["ID"]>;
  status?: Maybe<JobStatus>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type GetExternalJobLogsInput = {
  jobId: Scalars["ID"];
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type GetImagesInput = {
  imageId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  digest?: Maybe<Scalars["String"]>;
  environmentType?: Maybe<EnvironmentType>;
  environmentTypeVersion?: Maybe<Scalars["String"]>;
  validationType?: Maybe<ValidationType>;
  validationTypeVersion?: Maybe<Scalars["String"]>;
  package?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type SubmitJobInput = {
  name: Scalars["String"];
  imageId: Scalars["ID"];
  activityId: Scalars["ID"];
  actionType: ActionType;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  entrypoint: RepoObjectInput;
  workingDir: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  inputs?: Maybe<Array<RepoObjectInput>>;
  parameters?: Maybe<Array<ParameterInput>>;
  timeout?: Maybe<Scalars["Int"]>;
  memory?: Maybe<Scalars["Int"]>;
  vCPU?: Maybe<Scalars["Int"]>;
  describePatterns?: Maybe<Array<Scalars["String"]>>;
  ignorePatterns?: Maybe<Array<Scalars["String"]>>;
  environmentVariables?: Maybe<EnvironmentVariableInput>;
  command: Maybe<Scalars["String"]>;
};

export type EnvironmentVariableInput = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type SubmitExternalJobInput = {
  name: Scalars["String"];
  imageId: Scalars["ID"];
  entrypoint: FileObjectInput;
  workingDir: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  inputFolderStructure: Array<Scalars["String"]>;
  inputs?: Maybe<Array<FileObjectInput>>;
  parameters?: Maybe<Array<ParameterInput>>;
  timeout?: Maybe<Scalars["Int"]>;
  memory?: Maybe<Scalars["Int"]>;
  vCPU?: Maybe<Scalars["Int"]>;
  ignorePatterns?: Maybe<Array<Scalars["String"]>>;
};

export type CancelJobInput = {
  jobId: Scalars["ID"];
};

export type CancelExternalJobInput = {
  jobId: Scalars["ID"];
};

export type ParameterInput = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type FileObjectInput = {
  filePath: Scalars["String"];
  md5Sum: Scalars["String"];
};

export type RepoObjectInput = {
  repositoryId: Scalars["ID"];
  versionId: Scalars["ID"];
};

export type CreateApiKeyInput = {
  name: Scalars["String"];
};

export type DeleteApiKeyInput = {
  name: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  createAPIKey: Scalars["String"];
  deleteAPIKey: Scalars["Boolean"];
  submitJob: Job;
  submitExternalJob: ExternalJob;
  cancelJob: Job;
  cancelExternalJob: ExternalJob;
};

export type MutationCreateApiKeyArgs = {
  input?: Maybe<CreateApiKeyInput>;
};

export type MutationDeleteApiKeyArgs = {
  input?: Maybe<DeleteApiKeyInput>;
};

export type MutationSubmitJobArgs = {
  input: SubmitJobInput;
};

export type MutationSubmitExternalJobArgs = {
  input: SubmitExternalJobInput;
};

export type MutationCancelJobArgs = {
  input: CancelJobInput;
};

export type MutationCancelExternalJobArgs = {
  input: CancelExternalJobInput;
};

export type GetJobsOutput = {
  __typename?: "GetJobsOutput";
  jobs: Array<Job>;
  nextCursor?: Maybe<Scalars["String"]>;
  totalCount: Scalars["Int"];
};

export type GetJobLogsOutput = {
  __typename?: "GetJobLogsOutput";
  logs: Array<Log>;
  nextCursor?: Maybe<Scalars["String"]>;
};

export type GetExternalJobsOutput = {
  __typename?: "GetExternalJobsOutput";
  jobs: Array<ExternalJob>;
  nextCursor?: Maybe<Scalars["String"]>;
  totalCount: Scalars["Int"];
};

export type GetExternalJobLogsOutput = {
  __typename?: "GetExternalJobLogsOutput";
  logs: Array<Log>;
  nextCursor?: Maybe<Scalars["String"]>;
};

export type GetImagesOutput = {
  __typename?: "GetImagesOutput";
  images: Array<Image>;
  nextCursor?: Maybe<Scalars["String"]>;
  totalCount: Scalars["Int"];
};

export type Query = {
  __typename?: "Query";
  /** Get sussudio jobs of execution environment */
  getJobs: GetJobsOutput;
  /** Get sussudio job log of execution environment */
  getJobLogs: GetJobLogsOutput;
  /** Get external jobs of execution environment */
  getExternalJobs: GetExternalJobsOutput;
  /** Get external job log of execution environment */
  getExternalJobLogs: GetExternalJobLogsOutput;
  /** Get image versions that can be used in execution environment */
  getImages: GetImagesOutput;
};

export type QueryGetJobsArgs = {
  input?: Maybe<GetJobsInput>;
};

export type QueryGetJobLogsArgs = {
  input: GetJobLogsInput;
};

export type QueryGetExternalJobsArgs = {
  input?: Maybe<GetExternalJobsInput>;
};

export type QueryGetExternalJobLogsArgs = {
  input: GetExternalJobLogsInput;
};

export type QueryGetImagesArgs = {
  input?: Maybe<GetImagesInput>;
};

export type Image = {
  __typename?: "Image";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  environmentType: EnvironmentType;
  environmentTypeVersion: Scalars["String"];
  validationType?: Maybe<ValidationType>;
  validationTypeVersion?: Maybe<Scalars["String"]>;
  digest: Scalars["String"];
  updatedOn?: Maybe<Scalars["String"]>;
  packages?: Maybe<Array<Package>>;
  validatedPackages?: Maybe<Array<ValidatedPackage>>;
  addons?: Maybe<Array<Addon>>;
  recommendations?: Maybe<Array<ImageCommand>>;
};

export type ImageCommand = {
  command: Scalars["String"];
  isMultinode: Scalars["Boolean"];
};

export type Package = {
  __typename?: "Package";
  name: Scalars["String"];
  version: Scalars["String"];
};

export type ValidatedPackage = {
  __typename?: "ValidatedPackage";
  name: Scalars["String"];
  version: Scalars["String"];
};

export type Addon = {
  __typename?: "Addon";
  name: Scalars["String"];
};

export type Parameter = {
  __typename?: "Parameter";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type Log = {
  __typename?: "Log";
  timestamp: Scalars["String"];
  message: Scalars["String"];
};

export type EnvironmentVariable = {
  __typename?: "EnvironmentVariable";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type Job = {
  __typename?: "Job";
  id: Scalars["ID"];
  status: JobStatus;
  statusReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  startedAt?: Maybe<Scalars["String"]>;
  stoppedAt?: Maybe<Scalars["String"]>;
  createdBy: Scalars["String"];
  image: Image;
  activityId: Scalars["ID"];
  actionId: Scalars["ID"];
  actionType: ActionType;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  entrypoint: RepoObject;
  workingDir: Scalars["String"];
  commands: Array<Scalars["String"]>;
  inputFolderStructure: Array<Scalars["String"]>;
  inputs: Array<RepoObject>;
  outputFolderStructure?: Maybe<Array<Scalars["String"]>>;
  outputs: Array<RepoObject>;
  timeout?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  vCPU: Scalars["Int"];
  memory: Scalars["Int"];
  numOfNodes: Scalars["Int"];
  parameters: Array<Parameter>;
  describePatterns?: Maybe<Array<Scalars["String"]>>;
  ignorePatterns?: Maybe<Array<Scalars["String"]>>;
  environmentVariables?: Maybe<Array<Maybe<EnvironmentVariable>>>;
};

export type RepoObject = {
  __typename?: "RepoObject";
  repositoryId: Scalars["ID"];
  versionId: Scalars["ID"];
};

export type ExternalJob = {
  __typename?: "ExternalJob";
  id: Scalars["ID"];
  status: JobStatus;
  statusReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  startedAt?: Maybe<Scalars["String"]>;
  stoppedAt?: Maybe<Scalars["String"]>;
  createdBy: Scalars["String"];
  image: Image;
  entrypoint: FileObject;
  workingDir: Scalars["String"];
  commands: Array<Scalars["String"]>;
  inputFolderStructure: Array<Scalars["String"]>;
  inputs: Array<FileObject>;
  outputFolderStructure?: Maybe<Array<Scalars["String"]>>;
  outputs: Array<FileObject>;
  timeout: Scalars["Int"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  vCPU: Scalars["Int"];
  memory: Scalars["Int"];
  uploadUrl?: Maybe<Scalars["String"]>;
  downloadUrl?: Maybe<Scalars["String"]>;
  parameters: Array<Parameter>;
  ignorePatterns?: Maybe<Array<Scalars["String"]>>;
};

export type FileObject = {
  __typename?: "FileObject";
  filePath: Scalars["String"];
  md5Sum: Scalars["String"];
};
