import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Error from "../../abstractComponents/error";
import { ApolloError } from "@apollo/client";

export default function HideModal(props: {
  isHideModalOpen: boolean;
  closeHideModal: any;
  selectedRows: any[];
  tableMenuWasOpenOnPath: string;
  hideComment: string;
  setHideComment: Function;
  hideObjects: Function;
  errorMutationHide: ApolloError | undefined;
  isLoading: Boolean;
}) {
  return (
    <Modal show={props.isHideModalOpen} onHide={props.closeHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Hide</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.isLoading && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>Hiding...</p>
          </div>
        )}
        <p>This will hide the following: </p>
        <ul id="hide_list_items">
          {props.selectedRows.length > 0 ? (
            props.selectedRows.map((row, index) => <li key={index}>{row}</li>)
          ) : (
            <li>{props.tableMenuWasOpenOnPath}</li>
          )}
        </ul>

        <Form.Label>Hide Comment</Form.Label>
        <Form.Control
          as="textarea"
          required
          data-testid="hide_comment_form"
          placeholder="Enter comment"
          value={props.hideComment}
          onChange={(e) => props.setHideComment(e.target.value)}
          id="hide_comment_form"
        />
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.closeHideModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          disabled={!props.hideComment}
          onClick={() => {
            props.hideObjects();
          }}
        >
          Hide
        </Button>
      </Modal.Footer>
      {props.errorMutationHide && <Error error={props.errorMutationHide} />}
    </Modal>
  );
}
