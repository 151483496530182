import { gql, useQuery } from "@apollo/client";
import { Maybe } from "../types";

export const GET_TRACEBILITY_REPORT = gql`
  query getTraceabilityReport($versionId: ID!) {
    getTraceabilityReport(input: { versionId: $versionId }) {
      __typename
      id
      actionType
      actionFile {
        __typename
        actionFileType
        file {
          __typename
          name
          versionId
          revision
          isValid
          repo {
            name
            id
          }
        }
      }
      startDatetime
      sourceFolder
      sourceFiles
      user {
        email
      }
      description
      actionQualityCheckStatus
      pmxActivity {
        __typename
        id
        trialNumber
        mainRepository {
          id
          name
        }
      }
      actionStatus
      actionSource
      actionStatusDescription
      actionHistory {
        __typename
        updated
        description
        actionQualityCheckStatus
        userId
      }
      historyLabel {
        __typename
        id
        name
      }
      hasAdditionalFilesLinked
      additionalDetails
      jobId
    }
  }
`;

export function useGetTraceabilityReport(
  versionId: Maybe<string> | undefined,
  activityId: number
) {
  const {
    data: tracebilityActions,
    loading: isLoadingTracebilityActions,
    error: errorTracebilityActions,
    refetch: refetchTracebilityActions
  } = useQuery(GET_TRACEBILITY_REPORT, {
    variables: { versionId, activityId },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  }); // TODO: Query results is not stored in the cache to avoid a mix up in actionFile

  return {
    refetchTracebilityActions,
    tracebilityActions,
    isLoadingTracebilityActions,
    errorTracebilityActions
  };
}
