import { useState, useContext } from "react";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import EditActivityModal from "./editActivityModal";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ViewConversations from "./viewConversations";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { useUserHelper } from "../../hooks/userHelper";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import MediationIcon from "@mui/icons-material/Mediation";
import HistoryIcon from "@mui/icons-material/History";
import { Button } from "react-bootstrap";

export default function NavBarPmxActivity(props: {
  activeIndex?: number | null;
  refetchRepoObjects?: Function | null;
  onNodeSelect?: Function | null;
  setSelectedCommentIdFromMenu?: Function | null;
  selectedCommentIdFromMenu?: null | number;
}): JSX.Element {
  const { activityData, activityId } = useContext(PmxActivityDetailsContext);
  const [isEditWindowOpen, setIsEditWindowOpen] = useState<boolean>(false);

  const isSidebarCollapsedDefault =
    localStorage.getItem("isSidebarCollapsed") &&
    localStorage.getItem("isSidebarCollapsed") !== "undefined" &&
    localStorage.getItem("isSidebarCollapsed") !== "" &&
    localStorage.getItem("isSidebarCollapsed") !== "false"
      ? true
      : false;

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(
    isSidebarCollapsedDefault
  );

  const { isBayerUser } = useUserHelper();

  return (
    <>
      <div
        className={
          isSidebarCollapsed ? "side_menu side_menu_collapsed" : "side_menu"
        }
      >
        <Divider />
        <List disablePadding>
          <ListItem
            key={"activity_name"}
            disablePadding
            className="side_menu_item brand_activity_name_menu_item"
          >
            <Button
              variant="link"
              id="activity_name"
              onClick={() => {
                setIsEditWindowOpen(true);
              }}
              title="Edit Activity"
              disabled={!activityData}
            >
              {!isSidebarCollapsed &&
                (activityData?.trialNumber
                  ? activityData?.trialNumber
                  : `Loading for ${activityId}...`)}
              <SettingsOutlinedIcon />
            </Button>
          </ListItem>

          <ListItem
            key={"file_explorer"}
            disablePadding
            className={
              props.activeIndex === 0
                ? "side_menu_item pmx_active_tab"
                : "side_menu_item"
            }
            title="File Explorer"
            disabled={!activityData}
          >
            <Link
              id="file_explorer"
              to={activityData ? `/activity/${activityId}` : "#"}
            >
              <FolderOutlinedIcon />
              {!isSidebarCollapsed && "File Explorer"}
            </Link>
          </ListItem>

          {isBayerUser && (
            <ListItem
              disablePadding
              className={
                props.activeIndex === 1
                  ? "side_menu_item pmx_active_tab"
                  : "side_menu_item"
              }
              title="Interactive Env"
              disabled={!activityData}
            >
              <Link
                id="interactive_environments"
                to={activityData ? `/activity/${activityId}/interactive` : "#"}
              >
                <CloudDoneOutlinedIcon />
                {!isSidebarCollapsed && "Interactive Env"}
              </Link>
            </ListItem>
          )}

          {isBayerUser && (
            <ListItem
              disablePadding
              className={
                props.activeIndex === 2
                  ? "side_menu_item pmx_active_tab"
                  : "side_menu_item"
              }
              disabled={!activityData}
            >
              <Link
                id="execution_jobs"
                to={activityData ? `/activity/${activityId}/executions` : "#"}
                title="Execution Jobs"
              >
                <ScheduleSendOutlinedIcon />
                {!isSidebarCollapsed && "Execution Jobs"}
              </Link>
            </ListItem>
          )}
          {isBayerUser && (
            <ListItem
              disablePadding
              className={
                props.activeIndex === 3
                  ? "side_menu_item pmx_active_tab"
                  : "side_menu_item"
              }
              disabled={!activityData}
            >
              <Link
                id="actions_tab_link"
                to={`/activity/${activityId}/actions`}
                title="Actions"
              >
                <MediationIcon />
                {!isSidebarCollapsed && "Actions"}
              </Link>
            </ListItem>
          )}
          <ListItem
            disablePadding
            className={
              props.activeIndex === 4
                ? "side_menu_item pmx_active_tab"
                : "side_menu_item"
            }
            disabled={!activityData}
          >
            <Link
              id="history_view"
              to={activityData ? `/activity/${activityId}/history` : "#"}
              title="History"
            >
              <HistoryIcon />
              {!isSidebarCollapsed && "History"}
            </Link>
          </ListItem>

          {activityData && (
            <ErrorBoundary>
              <ListItem disablePadding className="side_menu_item">
                <ViewConversations
                  hideText={isSidebarCollapsed}
                  onNodeSelect={
                    props.onNodeSelect ? props.onNodeSelect : () => {}
                  }
                  refetchRepoObjects={
                    props.refetchRepoObjects
                      ? props.refetchRepoObjects
                      : () => {}
                  }
                  selectedCommentIdFromMenu={props.selectedCommentIdFromMenu}
                  setSelectedCommentIdFromMenu={
                    props.setSelectedCommentIdFromMenu
                      ? props.setSelectedCommentIdFromMenu
                      : () => {}
                  }
                />
              </ListItem>
            </ErrorBoundary>
          )}
        </List>

        <List className="bottom_collapse_menu" disablePadding>
          <ListItem
            key={"collapse_sidemenu"}
            disablePadding
            className="side_menu_item"
          >
            <Button
              variant="link"
              id="collapse_sidemenu"
              onClick={() => {
                localStorage.setItem(
                  "isSidebarCollapsed",
                  String(!isSidebarCollapsed)
                );
                setIsSidebarCollapsed(!isSidebarCollapsed);
              }}
              title="Collapse"
            >
              {!isSidebarCollapsed ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
              {!isSidebarCollapsed && "Collapse"}
            </Button>
          </ListItem>
        </List>
      </div>

      {isEditWindowOpen && (
        <EditActivityModal
          isEditWindowOpen={isEditWindowOpen}
          setIsEditWindowOpen={setIsEditWindowOpen}
          refetchRepoObjects={
            props.refetchRepoObjects ? props.refetchRepoObjects : () => {}
          }
        />
      )}
    </>
  );
}
