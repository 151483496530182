import { gql, useMutation } from "@apollo/client";

export const RENAME_OBJECTS_MUTATION = gql`
  mutation renameObjects(
    $activityId: ID!
    $absolutePath: String!
    $newName: String!
    $comment: String
    $override: Boolean
  ) {
    renameObjects(
      input: {
        activityId: $activityId
        absolutePath: $absolutePath
        newName: $newName
        comment: $comment
        override: $override
      }
    )
  }
`;

export function useRenameObjectsMutation() {
  const [
    renameObjectsMutation,
    { loading: isRenameLoading, error: renameError }
  ] = useMutation(RENAME_OBJECTS_MUTATION);

  return {
    renameObjectsMutation,
    isRenameLoading,
    renameError
  };
}
