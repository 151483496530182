import { Modal, Form, Button, Alert, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import Error from "./abstractComponents/error";
import { ADD_USER_MUTATION } from "../backend/hooks/mutationAddUser";
import { useMutation } from "@apollo/client";
import { useGetUsersQuery } from "../backend/hooks/queryGetUserList";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import UserManualLink from "./abstractComponents/usermanual";

// Popup that enables to create a new user
export default function CreateUserModal(props: {
  isPopupVisibleFunction: Function;
}) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [telephone, setTelephone] = useState("");
  const [wasDataSent, setWasDataSent] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const { userList, isLoadingGroupQuery } = useGetUsersQuery(false);

  useEffect(() => {
    const companies: any = [];
    if (userList && userList.getUsers) {
      userList.getUsers.forEach((user: any) => {
        if (user.company && !companies.includes(user.company)) {
          companies.push(user.company);
        }
      });
    }

    setCompanyOptions(companies);
  }, [userList]);

  const [
    addUserMutation,
    { loading: isLoadingMutation, error: errorMutation }
  ] = useMutation(ADD_USER_MUTATION, {
    onError(err) {
      console.log(err);
    }
  });

  function saveNewUser() {
    setWasDataSent(true);
    addUserMutation({
      variables: {
        name,
        username,
        email,
        company,
        telephone
      }
    });
  }

  return (
    <Modal
      size="lg"
      show={true}
      onHide={() => props.isPopupVisibleFunction(false)}
    >
      {!wasDataSent ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Create New User</Modal.Title>
            <UserManualLink
              url="/usermanual/receiveaccess/#2-external-user"
              testId="um-external-user"
              className="align-self-start ms-1"
            />
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                First and Last Name <span className="red_form">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter First and Last Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Username <span className="red_form">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicBayNumber">
              <Form.Label>
                Email <span className="red_form">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                required
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicTelephoneNumber">
              <Form.Label>
                Telephone <span className="red_form">*</span>
              </Form.Label>
              <Form.Control
                type="tel"
                required
                placeholder="Enter telephone number in format +49123456789"
                value={telephone}
                onChange={(e) =>
                  setTelephone(e.target.value.replace(" ", "").replace("-", ""))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicProjectId">
              <Form.Label>
                Company <span className="red_form">*</span>
              </Form.Label>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(event, value) => setCompany(value ? value : "")}
                value={company}
                loading={isLoadingGroupQuery}
                onInputChange={(event, value) => setCompany(value ? value : "")}
                options={companyOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Select or enter a company" />
                )}
              />
            </Form.Group>
            <br />
            <p>
              <b>Note:</b> The user will receive an email invitation with a
              temporary password, that then they will have to change.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => props.isPopupVisibleFunction(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={
                name === "" || username === "" || email === "" || company === ""
              }
              onClick={saveNewUser}
            >
              <PersonAddOutlinedIcon /> Create User
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Creating...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoadingMutation ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            ) : (
              <>
                {!errorMutation ? (
                  <>
                    <p>The user was sucessfuly created</p>
                    <Alert
                      className="center_div alert_error fixed_bottom"
                      show={true}
                      dismissible
                      variant="success"
                    >
                      <p>The user was sucessfuly created</p>
                    </Alert>
                  </>
                ) : (
                  <>
                    <Error error={errorMutation} />
                    <p>There was an error creating the user</p>
                  </>
                )}
              </>
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}
