import { DataGridPro } from "@mui/x-data-grid-pro";
import { Link, useParams } from "react-router-dom";
import { useGetHistoryLabels } from "../../backend/hooks/history/queryGetHistoryLabels";
import { useDeleteHistoryLabelMutation } from "../../backend/hooks/history/mutationDeleteHistoryLabel";
import { Button, Spinner } from "react-bootstrap";
import Error from "../abstractComponents/error";

import { useState } from "react";

export function HistoryLabelList() {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const { activityId } = useParams<{
    activityId: string;
  }>();
  const {
    historyLabels,
    getHistoryLabelsLoading,
    getHistoryLabelsError,
    refetchHistoryLabel
  } = useGetHistoryLabels(Number(activityId), page, pageSize);

  const {
    deleteHistoryLabel,
    isLoadingDeleteHistoryLabelMutation,
    errorDeleteHistoryLabelMutation
  } = useDeleteHistoryLabelMutation();

  const COLUMNS = [
    {
      field: "name",
      headerName: "History Label",
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <div key={params.row.id}>
          <Link
            className="history_labels_link"
            data-testid={"history_label_name"}
            to={`/activity/${activityId}/history/fileExplorer?actionId=${params.row.actionId}`}
          >
            {params.row.name}
          </Link>
        </div>
      )
    },
    {
      field: "updatedBy",
      headerName: "User",
      flex: 1,
      sortable: false,
      filterable: false
    },
    {
      field: "actionId",
      headerName: "Action ID",
      flex: 1,
      sortable: false,
      filterable: false
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params: any) => (
        <div key={params.row.id}>
          <Button
            size="sm"
            className="delete_history_label"
            data-testid={"delete_history_label"}
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this history label?"
                )
              ) {
                deleteHistoryLabel({
                  variables: {
                    actionId: params.row.actionId
                  }
                }).then(() => {
                  refetchHistoryLabel();
                });
              }
            }}
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  return (
    <>
      {isLoadingDeleteHistoryLabelMutation && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      <DataGridPro
        rows={historyLabels ? historyLabels.historyLabels : []}
        rowCount={historyLabels ? historyLabels.totalRowCount : 0}
        columns={COLUMNS}
        autoHeight
        hideFooterSelectedRowCount
        page={page}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        pagination
        paginationMode={"server"}
        rowsPerPageOptions={[10, 15, 100]}
        loading={getHistoryLabelsLoading}
        error={getHistoryLabelsError}
      />
      {errorDeleteHistoryLabelMutation && (
        <Error error={errorDeleteHistoryLabelMutation} />
      )}
    </>
  );
}
