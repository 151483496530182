import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_SWAN_CONTENT = gql`
  mutation importSwanContent(
    $swanUser: String!
    $encryptedPassword: String!
    $absoluteSwanPaths: [String]!
    $parentFolder: String!
    $activityId: ID!
    $qualityCheckStatus: QualityCheckStatus
    $description: String
  ) {
    importSwanContent(
      input: {
        swanUser: $swanUser
        encryptedPassword: $encryptedPassword
        absoluteSwanPaths: $absoluteSwanPaths
        parentFolder: $parentFolder
        activityId: $activityId
        qualityCheckStatus: $qualityCheckStatus
        description: $description
      }
    ) {
      actionId
      actionStatus
      imported {
        name
      }
    }
  }
`;

export function useImportSwanContentMutation() {
  const [
    importContentMutation,
    {
      data: swanImportMutationData,
      loading: isLoadingUploadMutation,
      error: errorUploadMutation
    }
  ] = useMutation(MUTATION_IMPORT_SWAN_CONTENT);

  return {
    importContentMutation,
    swanImportMutationData,
    isLoadingUploadMutation,
    errorUploadMutation
  };
}
