import { gql, useQuery } from "@apollo/client";
export const GET_COMMENT_DETAILS_QUERY = gql`
  query getCommentDetails($commentId: ID!) {
    getCommentDetails(input: { commentId: $commentId }) {
      __typename
      id
      text
      created
      createdBy
      pmxActivity {
        id
      }
    }
  }
`;

export function useGetCommentDetails(commentId: number) {
  const { data: comment } = useQuery(GET_COMMENT_DETAILS_QUERY, {
    variables: { commentId: commentId }
  });

  return {
    comment: comment?.getCommentDetails
  };
}
