import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_MODSPACE_CONTENT = gql`
  query getModspaceFolders(
    $username: String!
    $encryptedPassword: String!
    $search: String!
  ) {
    getModspaceFolders(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        search: $search
      }
    ) {
      modspaceFolders
    }
  }
`;

export function useGetModspaceMetadataQuery() {
  const [getModspaceFolders, { error, loading, data }] = useLazyQuery(
    QUERY_GET_MODSPACE_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getModspaceFolders,
    errorModspaceMetadata: error,
    loadingModspaceMetadata: loading,
    modspaceMetadata: data
  };
}
