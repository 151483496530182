import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_EXTERNAL_DOWNLOAD_LINK = gql`
  mutation createExternalDownloadLink(
    $repoId: Int
    $pmxActivityId: Int
    $versionIds: [String!]!
    $absolutePath: String
    $emailAddress: String!
    $additionalMessage: String
  ) {
    createExternalDownloadLink(
      input: {
        repoId: $repoId
        pmxActivityId: $pmxActivityId
        versionIds: $versionIds
        absolutePath: $absolutePath
        emailAddress: $emailAddress
        additionalMessage: $additionalMessage
      }
    )
  }
`;

export function useCreateExternalDownloadLink() {
  const [
    createExternalDownloadLink,
    {
      loading: isCreateExternalDownloadLinkLoading,
      error: errorCreateExternalDownloadLink,
      data: createExternalDownloadLinkData
    }
  ] = useMutation(MUTATION_CREATE_EXTERNAL_DOWNLOAD_LINK, {
    fetchPolicy: "no-cache"
  });

  return {
    createExternalDownloadLink,
    isCreateExternalDownloadLinkLoading,
    errorCreateExternalDownloadLink,
    createExternalDownloadLinkData
  };
}
