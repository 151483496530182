import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";

export const resetScrollSize = (selector: string) => {
  // on render, we re-define the table
  setTimeout(() => {
    let height = 0;
    const elements = document.querySelectorAll(selector + " .MuiDataGrid-row");
    [].map.call(elements, (elem: HTMLElement) => {
      if (elem) {
        height += elem.clientHeight;
      }
    });

    const rowElements = document.querySelectorAll(
      selector + " .MuiDataGrid-virtualScrollerContent"
    );

    if (height < 10) {
      height = 10;
    }

    [].map.call(rowElements, (elem: HTMLElement) => {
      if (elem) {
        elem.style.height = height + "px";
        elem.style.minHeight = height + "px";
        elem.style.maxHeight = height + "px";
      }
    });
  }, 150);
};

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "PHIL FILE Traceability Report",
          utf8WithBom: true,
          delimiter: ";"
        }}
      />
    </GridToolbarContainer>
  );
}
