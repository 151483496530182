import ShareIcon from "@mui/icons-material/Share";
import {
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  NavDropdown,
  Form,
  Button
} from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useState } from "react";
import { useCreateExternalUploadLink } from "../../backend/hooks/externalLink/mutationCreateExternalUploadLink";

// Create Upload Link, that can be used by external Users
export default function CreateExternalUploadLink(props: {
  currentSelectedPath: string;
  disabled: boolean;
  activityId: number;
}) {
  const [isLinkCreationModalVisible, setIsLinkCreationModalVisible] =
    useState(false);

  const {
    createExternalUploadLink,
    isCreateExternalUploadLinkLoading,
    errorCreateExternalUploadLink,
    createExternalUploadLinkData
  } = useCreateExternalUploadLink();

  //used to force a form of re-render
  const [wasMutationCalled, setWasMutationCalled] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [additionalMessage, setAdditionalMessage] = useState("");

  const closeModal = () => {
    setIsLinkCreationModalVisible(false);
    setEmailAddress("");
    setAdditionalMessage("");
    setWasMutationCalled(false);
  };

  const submitData = () => {
    setWasMutationCalled(true);
    createExternalUploadLink({
      variables: {
        pmxActivityId: props.activityId,
        absolutePath: props.currentSelectedPath,
        emailAddress,
        additionalMessage
      }
    });
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        data-testid="uploadExternalLinkItem"
        onClick={() => {
          setIsLinkCreationModalVisible(true);
        }}
        disabled={props.disabled}
        title={"Upload Analysis Data Files"}
        id="uploadExternalLinkItem"
      >
        <ShareIcon /> Create External Upload Link
      </NavDropdown.Item>
      <Modal
        show={isLinkCreationModalVisible}
        onHide={closeModal}
        dialogClassName="modal-75w"
        aria-labelledby="uploadAdfModalTitle"
      >
        <Modal.Header closeButton={!isCreateExternalUploadLinkLoading}>
          <Modal.Title id="uploadAdfModalTitle">
            Create External Link for upload into &quot;
            {props.currentSelectedPath ? props.currentSelectedPath : "/"}&quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {isCreateExternalUploadLinkLoading ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Generating Link...</p>
              </div>
            ) : createExternalUploadLinkData &&
              wasMutationCalled &&
              emailAddress ? (
              <>
                <Row>
                  <Col xs={8}>
                    <div>
                      <h3>The upload link is:</h3>
                      <p>
                        <b id="createExternalUploadLink">
                          {
                            createExternalUploadLinkData?.createExternalUploadLink
                          }
                        </b>
                      </p>
                      <p>
                        The external person just needs to access this link via
                        browser.
                      </p>
                      <p>
                        The link will be valid for only 10 days and file(s) can
                        only be uploaded once.
                      </p>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
              </>
            ) : (
              <Form>
                <Row>
                  <Col xs={8}>
                    <Form.Label>Recipient Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      data-testid="emailAddressExternalLink"
                      placeholder="Enter an Email Address"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      id="emailAddressExternalLink"
                    />
                  </Col>{" "}
                </Row>
                <br />
                <Row>
                  <Col xs={8}>
                    <Form.Label>Additional Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      data-testid="additionalMessage"
                      placeholder="Enter additional message"
                      value={additionalMessage}
                      onChange={(e) => setAdditionalMessage(e.target.value)}
                      id="additionalMessage"
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col>
                    <Button
                      className="float-end"
                      variant="primary"
                      disabled={!emailAddress}
                      id="createLinkButton"
                      type="submit"
                      onClick={() => {
                        submitData();
                      }}
                    >
                      Send Link
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      {errorCreateExternalUploadLink && (
        <Error error={errorCreateExternalUploadLink} />
      )}
    </>
  );
}
