import { gql, useQuery } from "@apollo/client";
export const LIST_ENVIRONMENT_TYPES = gql`
  query listInstanceTypes {
    listInstanceTypes {
      instanceTypeId
      cpu
      memory
      architectures
    }
  }
`;

export function useListInstanceTypesQuery() {
  const {
    data: environmentTypes,
    loading: isLoadingEnvironmentTypes,
    error: errorListEnvironmentTypes
  } = useQuery(LIST_ENVIRONMENT_TYPES, {
    context: { clientName: "interactive" }
  });
  return {
    environmentTypes,
    isLoadingEnvironmentTypes,
    errorListEnvironmentTypes
  };
}
