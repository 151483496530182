import { gql, useLazyQuery, useQuery } from "@apollo/client";

export const GET_ACTIVITY_TEMPLATES_QUERY = gql`
  {
    getActivityTemplates {
      __typename
      name
      description
      directories
    }
  }
`;

export function useGetActivityTemplatesQuery() {
  const [getActivityTemplates, { error, loading, data }] = useLazyQuery(
    GET_ACTIVITY_TEMPLATES_QUERY,
    { fetchPolicy: "no-cache" }
  );

  return {
    getActivityTemplates,
    activityTemplatesLoading: loading,
    errorActivityTemplates: error,
    activityTemplates: data
  };
}
