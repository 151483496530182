import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation, useParams } from "react-router-dom";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useUserHelper } from "../../hooks/userHelper";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";
import { useCompareRepoObjectQuery } from "../../backend/hooks/interactive/queryCompareRepoObjects";
import { RepoObjectDiff } from "../../backend/interactiveTypes";
import { parseDiff, Diff, Hunk } from "react-diff-view";
import Error from "../abstractComponents/error";
import { useImportFilesMutation } from "../../backend/hooks/interactive/mutationImportFiles";
import { useHistory } from "react-router-dom";

export type ComparisonInput = RepoObjectDiff & {
  phil: string;
  conflict: string;
  [key: string]: any;
};

export function InteractiveComparison() {
  const history = useHistory();
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const [comparisonInput, setComparisonInput] =
    useState<ComparisonInput | null>(null);

  const { environmentId } = useParams<{
    environmentId: string;
  }>();
  const { search } = useLocation();
  const { isBayerUser } = useUserHelper();
  const { environments, isLoadingEnvironments, errorListEnvironments } =
    useQueryEnvironmentsQuery(activityId, false, environmentId);

  const {
    getFileComparison,
    interactiveFileComparison,
    isLoadingFileComparison,
    errorFileComparison
  } = useCompareRepoObjectQuery();

  const { importFiles, idImportFiles, isLoadingImportFiles } =
    useImportFilesMutation();

  useEffect(() => {
    if (comparisonInput) {
      getFileComparison({
        variables: {
          environmentId: environmentId,
          repoObject: {
            repoId: comparisonInput?.repoId,
            repoName: comparisonInput?.repoName,
            path: comparisonInput?.path
          }
        }
      });
    }
  }, [comparisonInput]);

  useEffect(() => {
    let comparisonInput: ComparisonInput = {} as ComparisonInput;
    const url = decodeURIComponent(search).replace("?", "").split("&");
    url.forEach((item) => {
      const [key, value] = item.split("=");
      comparisonInput[key] = value;
    });
    setComparisonInput(comparisonInput);
  }, [search]);

  useEffect(() => {
    if (idImportFiles?.importFiles) {
      history.push(`/activity/${activityId}/interactive/${environmentId}`, {
        refetchDiffState: true
      });
    }
  }, [idImportFiles]);

  const onHandleAcceptChanges = () => {
    importFiles({
      variables: {
        environmentId: environmentId,
        paths: `${comparisonInput?.repoName}/${comparisonInput?.path}`
      }
    });
  };

  const parseDifference = () => {
    const [diff] = parseDiff(interactiveFileComparison, {
      nearbySequences: "zip"
    });

    return diff;
  };

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/interactive`}>
            Interactive Environments
          </Link>
          <Link to={`/activity/${activityId}/interactive/${environmentId}`}>
            {environments?.listEnvironments[0].environmentName}
          </Link>
          <Typography>Compare</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        {isBayerUser && (
          <>
            <ErrorBoundary>
              <Container fluid>
                <>
                  {(isLoadingImportFiles ||
                    isLoadingFileComparison ||
                    isLoadingEnvironments) && (
                    <div className="center_div">
                      <br />
                      <Spinner animation="border" className="spinner_color" />
                      <p>Loading...</p>
                    </div>
                  )}
                  {comparisonInput?.phil === "false" &&
                    comparisonInput?.conflict === "false" && (
                      <div className="d-flex justify-content-end mb-4">
                        <Button
                          onClick={onHandleAcceptChanges}
                          disabled={
                            isLoadingImportFiles || isLoadingFileComparison
                          }
                        >
                          Accept Changes
                        </Button>
                      </div>
                    )}
                  <Row className="mb-4 gap-4">
                    <h4>
                      {comparisonInput?.repoName}/{comparisonInput?.path}
                    </h4>
                    <Col>
                      <h5>PHIL</h5>
                    </Col>
                    <Col>
                      <h5>Interactive</h5>
                    </Col>
                    <Diff
                      viewType="split"
                      diffType="modify"
                      hunks={
                        interactiveFileComparison ? parseDifference().hunks : []
                      }
                    >
                      {(hunks) =>
                        hunks.map((hunk) => (
                          <Hunk key={hunk.content} hunk={hunk} />
                        ))
                      }
                    </Diff>
                  </Row>
                </>
              </Container>
            </ErrorBoundary>

            {(errorFileComparison || errorListEnvironments) && (
              <Error
                error={
                  errorFileComparison?.message || errorListEnvironments?.message
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
