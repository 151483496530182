import { gql, useSubscription } from "@apollo/client";
import { useAuth } from "../../../components/authContextProvider";

const GET_NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription getNotifications($email: String!) {
    getNotifications(email: $email) {
      email
      notifications {
        id
        message
        associationId
        author
        createdOn
        notificationType
        outcomeType
        read
      }
    }
  }
`;

export function useGetNotificationsSubscription() {
  const { decodedToken } = useAuth();

  const {
    data: notificationData,
    loading: isLoadingNotification,
    error: notificationError
  } = useSubscription(GET_NOTIFICATIONS_SUBSCRIPTION, {
    variables: {
      email: decodedToken["email"]
    },
    context: { clientName: "appsync" }
  });

  return {
    notificationData,
    isLoadingNotification,
    notificationError
  };
}
