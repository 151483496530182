import { gql, useMutation } from "@apollo/client";

export const MOVE_OBJECTS_MUTATION = gql`
  mutation moveObjects(
    $activityId: ID!
    $absolutePaths: [String]!
    $destinationPath: String!
    $override: Boolean
    $comment: String
  ) {
    moveObjects(
      input: {
        activityId: $activityId
        absolutePaths: $absolutePaths
        destinationPath: $destinationPath
        override: $override
        comment: $comment
      }
    )
  }
`;

export function useMoveObjectsMutation() {
  const [
    moveObjectsMutation,
    { loading: isMoveObjectsLoading, error: moveOjectsError }
  ] = useMutation(MOVE_OBJECTS_MUTATION);

  return {
    moveObjectsMutation,
    isMoveObjectsLoading,
    moveOjectsError
  };
}
