import { gql, useQuery } from "@apollo/client";
export const GET_GROUP_LIST_QUERY = gql`
  query getUsers($forceRefresh: Boolean) {
    getUsers(input: { forceRefresh: $forceRefresh }) {
      email
      userGroup
      roleArn
      isPmx
      company
    }
  }
`;

export function useGetUsersQuery(shouldRefrechUserList: boolean) {
  const {
    data: userList,
    loading: isLoadingGroupQuery,
    refetch: refetchUserQuery
  } = useQuery(GET_GROUP_LIST_QUERY, {
    variables: { forceRefresh: shouldRefrechUserList },
    notifyOnNetworkStatusChange: true
  });

  return {
    userList,
    isLoadingGroupQuery,
    refetchUserQuery
  };
}
