import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import Error from "../../abstractComponents/error";
import Success from "../../abstractComponents/success";
import { PmxActivityDetailsContext } from "../../../contexts/pmxActivityDetailsProvider";
import { useCreateArchiveMutation } from "../../../backend/hooks/importExportMenu/mutationCreateArchive";
import { formatDateWithUnderscore } from "../../../helpers/stringHelper";
import { ActionStatus } from "../../../backend/types";
import { RepoObject } from "../../../backend/types";

export default function CompressModal(props: {
  isCompressModalOpen: boolean;
  closeCompressModal: any;
  currentSelectedPath: string;
  selectedRows: string[];
  onFinish: Function;
  currentSubArray: any[] | undefined;
}) {
  const MAX_ROWS_DISPLAYED = 5;
  const { activityData } = useContext(PmxActivityDetailsContext);

  const {
    createArchiveMutation,
    isArchiveLoading,
    createArchiveError,
    compressData
  } = useCreateArchiveMutation();

  const defaultCompressedFileName = () => {
    return formatDateWithUnderscore(new Date()).concat("_archive.zip");
  };

  const [compressedFileName, setCompressedFileName] = useState(
    defaultCompressedFileName()
  );

  useEffect(
    () => setCompressedFileName(defaultCompressedFileName),
    [props.isCompressModalOpen]
  );

  const submitData = () => {
    createArchiveMutation({
      variables: {
        parentFolderInputs: props.currentSelectedPath,
        relativePathInputs: props.selectedRows.map((element) =>
          props.currentSelectedPath !== "/"
            ? element.replaceAll(props.currentSelectedPath.toString(), "")
            : element
        ),
        activityId: Number(activityData.id),
        archiveName: compressedFileName
      }
    }).then(() => {
      props.onFinish();
      props.closeCompressModal();
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.isCompressModalOpen}
        onHide={props.closeCompressModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Compress Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isArchiveLoading && (
            <Spinner animation="border" className="spinner_color" />
          )}
          <p>This will compress the following files:</p>
          <ul>
            {props.selectedRows.map((row, index) =>
              index < MAX_ROWS_DISPLAYED ? <li key={index}>{row}</li> : <></>
            )}
            {props.selectedRows.length > MAX_ROWS_DISPLAYED && <li>...</li>}
          </ul>
          <br />
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>
              Name of Compressed File <span className="red_form">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name of compressed file"
              value={compressedFileName}
              onChange={(e) => setCompressedFileName(e.target.value)}
            />
          </Form.Group>
          {props.currentSubArray?.find(
            (repoObject: RepoObject) => repoObject.name === compressedFileName
          ) && <p className="red_form">File already exists</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeCompressModal}>
            Cancel
          </Button>
          <Button onClick={submitData}>Compress</Button>
        </Modal.Footer>
      </Modal>

      {createArchiveError && <Error error={createArchiveError} />}
      {compressData?.createArchive &&
        compressData?.createArchive.actionStatus === ActionStatus.Pending && (
          <Success message="Compress started. It can take up to 15 Minutes." />
        )}
    </>
  );
}
