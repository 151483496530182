import { useCompleteMultipartUpload } from "../backend/hooks/importExportMenu/mutationCompleteMultipartUpload";
import { useCreateMultipartUpload } from "../backend/hooks/importExportMenu/mutationCreateMultipartUpload";
import { useGetMultipartUploadLink } from "../backend/hooks/importExportMenu/mutationGetMultipartUploadLinks";
import { ActionFileType } from "../backend/types";
import {
  MAX_FILE_SIZE_FOR_SINGLE_UPLOAD,
  MAX_UPLOADS_PER_BATCH,
  splitFileIntoChunks
} from "../helpers/fileHelper";
import {
  QualityCheckStatus,
  getQualityCheckStatusKeyFromValue
} from "../helpers/stringHelper";

const WAIT_TIME_FOR_RETRY_MILISECONDS = 10000;

export function useUploadMultipartFile(
  currentSelectedPath: string,
  files: any[],
  setFilesToBeUploaded: Function,
  setMaxNumberOfUploads: Function,
  getAbsolutePathOfFile: Function,
  activityId: number,
  qualityCheckStatus: QualityCheckStatus | null,
  uncompress: boolean,
  callbackUpload: Function,
  setErrorFromUploading: Function,
  filesToBeUploaded: number
) {
  const { createMulipartUpload } = useCreateMultipartUpload();
  const {
    getMultipartUploadLink,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkError
  } = useGetMultipartUploadLink();
  const {
    completeMultipartUpload,
    completeMultipartUploadLoading,
    completeMultipartUploadError
  } = useCompleteMultipartUpload();

  const executeMultipartUploadBatch = (
    file: any,
    chunkedFile: Blob[],
    eTagArray: string[],
    uploadId: string,
    currentMainIndex: number = 1
  ) => {
    if (files.length === 0) {
      //we have canceled the transaction
      return;
    }
    const processingParts: number[] = [];
    const maxPart =
      chunkedFile.length <= MAX_UPLOADS_PER_BATCH + currentMainIndex
        ? chunkedFile.length
        : MAX_UPLOADS_PER_BATCH + currentMainIndex;
    for (let i = currentMainIndex; i <= maxPart; i++) {
      processingParts.push(i);
    }

    getMultipartUploadLink({
      variables: {
        absolutePath: getAbsolutePathOfFile(currentSelectedPath, file.file),
        activityId,
        uploadId,
        partNumbers: processingParts
      }
    })
      .then((multipartLinks) => {
        const presignedURLs =
          multipartLinks.data.getMultipartUploadLinks.presignedUrls;
        let currentIndex = 0;

        const runFetch = (index: number): any => {
          if (files.length === 0) {
            //we have canceled the transaction
            return;
          }

          const fileFromBlob = new File(
            [chunkedFile[presignedURLs[index].partNumber - 1]],
            presignedURLs[index].partNumber
          );

          return fetch(presignedURLs[index].url, {
            method: "PUT",
            body: fileFromBlob
          })
            .then((response) => {
              setErrorFromUploading(null); //reset if there was a previous error
              eTagArray[presignedURLs[index].partNumber - 1] = response.headers
                .get("ETag")!
                .replace('"', "");
              setFilesToBeUploaded((prevState: number) => {
                if (prevState > 1) {
                  return prevState - 1;
                } else if (currentMainIndex + index === chunkedFile.length) {
                  return 0;
                }
              });

              //we still have another part
              if (currentIndex < presignedURLs.length - 1) {
                currentIndex++;

                return runFetch(currentIndex);
              } else if (processingParts[currentIndex] === chunkedFile.length) {
                return completeMultipartUpload({
                  variables: {
                    absolutePath: getAbsolutePathOfFile(
                      currentSelectedPath,
                      file.file
                    ),
                    activityId,
                    uploadId,
                    partNumbers: chunkedFile.map(
                      (currentValue, index) => Number(index + 1) //so we start with number 1
                    ),
                    eTags: eTagArray
                  }
                })
                  .then(() => {
                    //Wait for complete React Render, and S3 file creation
                    setTimeout(() => {
                      if (filesToBeUploaded === 0) {
                        callbackUpload();
                      }
                    }, 3000);
                  })
                  .catch((err) => {
                    console.error(err);
                    setErrorFromUploading(String(err));
                  });
              } else {
                return executeMultipartUploadBatch(
                  file,
                  chunkedFile,
                  eTagArray,
                  uploadId,
                  currentMainIndex + MAX_UPLOADS_PER_BATCH
                );
              }
            })
            .catch((e) => {
              console.error(e);
              setErrorFromUploading(
                String(e) + " -- retrying multipart (CROS might have issues)"
              );
              setTimeout(() => {
                runFetch(index);
              }, WAIT_TIME_FOR_RETRY_MILISECONDS);
            });
        };

        runFetch(currentIndex);
      })
      .catch((e) => {
        console.error(e);
        setErrorFromUploading(String(e) + " -- retrying");
        setTimeout(() => {
          executeMultipartUploadBatch(
            file,
            chunkedFile,
            eTagArray,
            uploadId,
            currentMainIndex
          );
        }, WAIT_TIME_FOR_RETRY_MILISECONDS);
      });
  };

  const uploadMultipart = (
    file: any,
    actionFileType: ActionFileType,
    actionId: number
  ) => {
    if (file.size > MAX_FILE_SIZE_FOR_SINGLE_UPLOAD) {
      const currentFile = Array.from(files).find((actualFile) => {
        return file.file.endsWith(actualFile.name);
      });
      const chunkedFile = splitFileIntoChunks(currentFile);
      setFilesToBeUploaded(
        (prevState: number) => prevState + chunkedFile.length
      );
      setMaxNumberOfUploads(
        (prevState: number) => prevState + chunkedFile.length
      );

      const eTagArray: string[] = [];

      createMulipartUpload({
        variables: {
          absolutePath: getAbsolutePathOfFile(currentSelectedPath, file.file),
          md5sum: file.hash,
          activityId,
          actionFileType: actionFileType,
          qualityCheckStatus:
            getQualityCheckStatusKeyFromValue(qualityCheckStatus),
          uncompressOnUpload: uncompress,
          actionId
        }
      })
        .then((data) => {
          executeMultipartUploadBatch(
            file,
            chunkedFile,
            eTagArray,
            data.data.createMultipartUpload!.uploadId!
          );
        })
        .catch((error) => {
          console.error(error);
          setErrorFromUploading(String(error));
        });
    }
  };

  return {
    uploadMultipart,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkError,
    completeMultipartUploadLoading,
    completeMultipartUploadError
  };
}
