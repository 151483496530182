import { gql, useMutation } from "@apollo/client";

export const CREATE_ARCHIVE_MUTATION = gql`
  mutation createArchive(
    $parentFolderInputs: String!
    $relativePathInputs: [String]!
    $activityId: ID!
    $archiveName: String
  ) {
    createArchive(
      input: {
        activityId: $activityId
        parentFolderInputs: $parentFolderInputs
        relativePathInputs: $relativePathInputs
        archiveName: $archiveName
      }
    ) {
      actionId
      actionStatus
      archive {
        name
      }
    }
  }
`;

export function useCreateArchiveMutation() {
  const [
    createArchiveMutation,
    { data: compressData, loading: isArchiveLoading, error: createArchiveError }
  ] = useMutation(CREATE_ARCHIVE_MUTATION);

  return {
    createArchiveMutation,
    compressData,
    isArchiveLoading,
    createArchiveError
  };
}
