import { gql, useLazyQuery } from "@apollo/client";
export const LIST_INTERACTIVE_FILES = gql`
  query listInteractiveFiles($activityId: ID!, $environmentId: ID!) {
    listInteractiveFiles(
      input: { activityId: $activityId, environmentId: $environmentId }
    ) {
      interactiveFiles {
        repoId
        repoName
        files
      }
    }
  }
`;

export function useQueryListInteractiveFilesQuery() {
  const [
    getListIntreactiveFiles,
    {
      data: interactiveFiles,
      loading: isLoadingInteractiveFiles,
      error: errorLoadingInteractiveFiles
    }
  ] = useLazyQuery(LIST_INTERACTIVE_FILES, {
    fetchPolicy: "no-cache",
    context: { clientName: "interactive" }
  });
  return {
    getListIntreactiveFiles,
    interactiveFiles: interactiveFiles?.listInteractiveFiles?.interactiveFiles,
    isLoadingInteractiveFiles,
    errorLoadingInteractiveFiles
  };
}
