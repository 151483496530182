import { gql, useQuery } from "@apollo/client";
export const GET_FILE_PARTIAL_CONTENT = gql`
  query getFilePartialContent(
    $absolutePath: String!
    $repoId: ID!
    $versionId: ID
  ) {
    getFilePartialContent(
      input: {
        absolutePath: $absolutePath
        repoId: $repoId
        versionId: $versionId
      }
    ) {
      content
      isTruncated
      isPreviewAvailable
    }
  }
`;

export function useGetFilePartialContentQuery(
  repoId: string,
  absolutePath: string,
  versionId: string
) {
  const {
    data: fileDetails,
    loading: isLoading,
    error,
    refetch: refetchFilePartialContent
  } = useQuery(GET_FILE_PARTIAL_CONTENT, {
    variables: {
      repoId: repoId,
      absolutePath: absolutePath,
      versionId: versionId
    },
    notifyOnNetworkStatusChange: true
  });

  return {
    fileDetails,
    isLoading,
    error,
    refetchFilePartialContent
  };
}
