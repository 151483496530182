import { useEffect, useState, useContext } from "react";
import { useAddActionMutation } from "../backend/hooks/action/mutationCreateAction";
import { useCreateUploadLinkMutation } from "../backend/hooks/importExportMenu/mutationCreateUploadLinks";
import { useUpdateActionStatusMutation } from "../backend/hooks/action/mutationUpdateActionStatus";
import { BrowsePmxActivityContext } from "../contexts/browsePmxActivityProvider";
import {
  Action,
  ActionFileType,
  ActionStatus,
  ActionType as ActionTypeBackend,
  RepoObject,
  ActionSource
} from "../backend/types";
import { useAuth } from "../components/authContextProvider";
import {
  getChecksums,
  MAX_FILE_SIZE_FOR_SINGLE_UPLOAD
} from "../helpers/fileHelper";
import { useUploadMultipartFile } from "./multipartUploadHook";
import {
  ActionType,
  ActionTypeType,
  getActionTypeKeyFromValue,
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../helpers/stringHelper";
import { useGetRepoObjectsForMd5 } from "../backend/hooks/pmxActivity/queryGetRepoObjectForMd5";
import { useConsumingActionsLazyQuery } from "../backend/hooks/contextMenu/queryGetConsumingActions";

const getAbsolutePathOfFile = (
  currentSelectedPath: string,
  fileName: string
) => {
  return currentSelectedPath !== "/" &&
    currentSelectedPath !== "\\" &&
    currentSelectedPath !== " "
    ? currentSelectedPath + fileName
    : fileName;
};

export function useUploadExternalActionHook(
  currentSelectedPath: string,
  activityId: number,
  refetchRepoObjects: Function,
  currentSubArray: any
) {
  const { decodedToken } = useAuth();
  const { treeHierarchyGlobal } = useContext(BrowsePmxActivityContext);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus | null>(null);
  const [outputFiles, setOutputFiles] = useState<any[]>([]);
  const [filesHashedOutput, setFilesHashedOutput] = useState<any[]>([]);
  const [describingFiles, setDescribingFiles] = useState<any[]>([]);
  const [filesHashedDescribing, setFilesHashedDescribing] = useState<any[]>([]);
  const [filesHashedInput, setFilesHashedInput] = useState<any[]>([]);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState(0);
  const [uncompress, setUncompress] = useState(false);
  const [wasMutationCalled, setWasMutationCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxNumberOfUploads, setMaxNumberOfUploads] = useState(0);
  const [errorFromUploading, setErrorFromUploading] = useState("");
  const [actionType, setActionType] = useState<ActionType | undefined>(
    undefined
  );
  const [files, setFiles] = useState<any[]>([]);
  const {
    getUploadLinksForFiles,
    uploadData,
    uploadLoading,
    uploadLinksError
  } = useCreateUploadLinkMutation();

  const { createAction, createActionError, createActionLoading } =
    useAddActionMutation();

  const { getRepoObjectMd5, isRepoObjectLoading, repoObjectError } =
    useGetRepoObjectsForMd5();
  const {
    getConsumingActions,
    isLoadingConsumingActions,
    errorConsumingActions
  } = useConsumingActionsLazyQuery();

  const { updateActionStatus } = useUpdateActionStatusMutation();

  const closeModal = () => {
    setIsUploadModalVisible(false);
    setOutputFiles([]);
    setFilesHashedOutput([]);
    setDescribingFiles([]);
    setFilesHashedDescribing([]);
    setFilesHashedInput([]);
    setFilesToBeUploaded(0);
    setDescription("");
    setUncompress(false);
    setFiles([]);
    setWasMutationCalled(false);
    setMaxNumberOfUploads(0);
    refetchRepoObjects();
    setQualityCheckStatus(null);
  };

  const {
    uploadMultipart,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkError,
    completeMultipartUploadLoading,
    completeMultipartUploadError
  } = useUploadMultipartFile(
    currentSelectedPath,
    files,
    setFilesToBeUploaded,
    setMaxNumberOfUploads,
    getAbsolutePathOfFile,
    activityId,
    qualityCheckStatus,
    uncompress,
    closeModal,
    setErrorFromUploading,
    filesToBeUploaded
  );

  function changeActionType(event: React.ChangeEvent<HTMLSelectElement>) {
    event.preventDefault();
    setActionType(ActionType[event.target.value as ActionTypeType]);
  }

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  //Runs after upload links are pulled
  useEffect(() => {
    if (typeof uploadData !== "undefined") {
      if (uploadData.createUploadLinks.actionStatus === "Pending") {
        //Wrap all uploads in a promise so we can close the window only at the end
        const promiseArray: any = [];
        uploadData.createUploadLinks?.presignedUrls.forEach(
          async (uploadDataParse: any) => {
            const fields = JSON.parse(uploadDataParse.fields);
            const formData = new FormData();
            Object.entries(fields).forEach((element: any) => {
              const [key, value] = element;
              formData.append(key, value);
            });
            //Attach the proper file to the form
            formData.append(
              "file",
              Array.from(files).find((file) => {
                return fields.key.endsWith(file.name);
              })
            );
            promiseArray.push(
              fetch(uploadDataParse.url, {
                method: "POST",
                body: formData
              })
                .then(() => {
                  setFilesToBeUploaded((prevState) => prevState - 1);
                })
                .catch((err) => {
                  throw new Error(`failed to upload: ${err}`);
                })
            );
          }
        );

        Promise.all(promiseArray)
          .then(() => {
            updateActionStatus({
              variables: {
                actionId: uploadData.createUploadLinks.actionId,
                actionStatus: ActionStatus.Success
              }
            }).then(() => {
              setFilesHashedOutput([]);
              setFilesHashedDescribing([]);
              setFilesHashedInput([]);
              //Wait for React state hooks to finish
            });
          })
          .catch((err) => {
            updateActionStatus({
              variables: {
                actionId: uploadData.createUploadLinks.actionId,
                actionStatus: ActionStatus.Error,
                actionStatusDescription: err.toString()
              }
            });
            console.error(err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);

  useEffect(() => {
    if (filesToBeUploaded <= 0) {
      closeModal();
    }
  }, [filesToBeUploaded]);

  const saveFilesDescribing = (e: any) => {
    setIsLoading(true);
    getChecksums(e).then((array) => {
      const arrayFinal: any = [];
      array.forEach((checkedFile) => {
        let willOverwrite = false;
        currentSubArray.forEach((currentFile: RepoObject) => {
          if (checkedFile.file === currentFile.name) {
            willOverwrite = true;
          }
        });
        arrayFinal.push({ ...checkedFile, willOverwrite });
      });

      setFilesHashedDescribing((prevHashedDescribingFiles) => {
        return [...prevHashedDescribingFiles, ...arrayFinal];
      });

      setDescribingFiles((prevDescribing) => {
        return [...prevDescribing, ...e["target"]["files"]];
      });
      setFiles((prevCount) => [...prevCount, ...e["target"]["files"]]);

      //reset the input element
      const describingElement = document.getElementById(
        "adfDescriptionFileSelectFormControl"
      ) as HTMLInputElement | null;
      if (describingElement) {
        describingElement.value = "";
      }

      setIsLoading(false);
    });
  };

  const removeCurrentFileFromDescribing = (fileName: string) => {
    setDescribingFiles((prevDescribing) => {
      return [...prevDescribing.filter((file) => file.name !== fileName)];
    });
    setFiles((prevFiles) => {
      return [...prevFiles.filter((file) => file.name !== fileName)];
    });
    setFilesHashedDescribing((prevDescribing) => {
      return [...prevDescribing.filter((file) => file.file !== fileName)];
    });
  };

  const saveFilesOutput = (e: any) => {
    setIsLoading(true);
    getChecksums(e).then((array) => {
      const arrayFinal: any = [];
      array.forEach((checkedFile) => {
        let willOverwrite = false;
        currentSubArray.forEach((currentFile: RepoObject) => {
          if (checkedFile.file === currentFile.name) {
            willOverwrite = true;
          }
        });
        arrayFinal.push({ ...checkedFile, willOverwrite });
      });

      setFilesHashedOutput((prevHashedOutputFiles) => {
        return [...prevHashedOutputFiles, ...arrayFinal];
      });
      setOutputFiles((prevOutput) => {
        return [...prevOutput, ...e["target"]["files"]];
      });
      setFiles((prevCount) => [...prevCount, ...e["target"]["files"]]);

      //reset the input element
      const outputElement = document.getElementById(
        "adfOutputFileFormControl"
      ) as HTMLInputElement | null;
      if (outputElement) {
        outputElement.value = "";
      }
    });
    setIsLoading(false);
  };

  const removeCurrentFileFromOutput = (fileName: string) => {
    setOutputFiles((prevOutput) => {
      return [...prevOutput.filter((file) => file.name !== fileName)];
    });
    setFiles((prevFiles) => {
      return [...prevFiles.filter((file) => file.name !== fileName)];
    });
    setFilesHashedOutput((prevOutput) => {
      return [...prevOutput.filter((file) => file.file !== fileName)];
    });
  };

  const importAll = () => {
    if (
      (filesHashedDescribing.filter((file) => file.willOverwrite).length > 0 ||
        filesHashedOutput.filter((file) => file.willOverwrite).length > 0) &&
      !window.confirm("Are you sure that you want to overwrite files?")
    ) {
      return;
    }

    setWasMutationCalled(true);
    const actionFileInputType: any = [];

    filesHashedInput.forEach((file) =>
      actionFileInputType.push({
        actionFileType: ActionFileType.Input,
        versionId: file.versionId
      })
    );

    // We first create the action with the inputs, and add the action data in the upploads. The rest of the action is filled in the backend
    createAction({
      variables: {
        actionType: getActionTypeKeyFromValue(actionType),
        activityId,
        description: description,
        actionFiles: actionFileInputType,
        actionSource: ActionSource.ExternalSystem,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus)
      }
    }).then((actionData: any) => {
      const fileList: any[] = [];
      const hashList: any[] = [];
      const actionFileTypes: any[] = [];

      filesHashedOutput.forEach((file) => {
        if (file.size <= MAX_FILE_SIZE_FOR_SINGLE_UPLOAD) {
          fileList.push(getAbsolutePathOfFile(currentSelectedPath, file.file));
          hashList.push(file.hash);
          actionFileTypes.push(ActionFileType.Output);
        } else {
          uploadMultipart(
            file,
            ActionFileType.Output,
            Number(actionData.data.createAction.id)
          );
        }
      });

      setFilesToBeUploaded((prevState) => prevState + hashList.length);
      setMaxNumberOfUploads((prevState) => prevState + hashList.length);

      filesHashedDescribing.forEach((file) => {
        if (file.size <= MAX_FILE_SIZE_FOR_SINGLE_UPLOAD) {
          fileList.push(getAbsolutePathOfFile(currentSelectedPath, file.file));
          hashList.push(file.hash);
          actionFileTypes.push(ActionFileType.Describing);
        } else {
          uploadMultipart(
            file,
            ActionFileType.Describing,
            Number(actionData.data.createAction.id)
          );
        }
      });

      getUploadLinksForFiles({
        variables: {
          absolutePaths: fileList,
          md5sums: hashList,
          activityId,
          actionId: Number(actionData.data.createAction.id),
          actionFileTypes,
          qualityCheckStatus:
            getQualityCheckStatusKeyFromValue(qualityCheckStatus),
          uncompressOnUpload: uncompress
        }
      });
    });
  };

  const saveInputFiles = (e: any) => {
    setIsLoading(true);
    getChecksums(e).then((array) => {
      array.forEach((hashedFile) => {
        getRepoObjectMd5({
          variables: {
            pmxActivityId: activityId,
            md5: hashedFile.hash
          }
        }).then((data) => {
          const foundFile = data.data?.getRepoObjectForMd5;

          if (!foundFile) {
            setFilesHashedInput((prevHashedInputFiles) => {
              return [
                ...prevHashedInputFiles,
                {
                  ...hashedFile,
                  found: false,
                  downloaded: false,
                  versionId: null,
                  absolutePath: hashedFile.file,
                  qualityCheckStatus: null,
                  lastDownloadedDate: null,
                  repo: {
                    name: ""
                  }
                }
              ];
            });
          } else {
            getConsumingActions({
              variables: {
                versionIds: [foundFile.versionId]
              }
            }).then((actions: any) => {
              let downloadedActions: any = null;
              if (
                actions &&
                actions?.data?.getConsumingActions &&
                actions?.data?.getConsumingActions.length > 0
              ) {
                downloadedActions = actions?.data?.getConsumingActions?.filter(
                  (action: Action) => {
                    if (action.actionType !== ActionTypeBackend.Download) {
                      return false;
                    }
                    if (action!.user?.email !== decodedToken["email"]) {
                      return false;
                    }
                    return true;
                  }
                );
              }

              if (!downloadedActions || downloadedActions.length === 0) {
                setFilesHashedInput((prevHashedInputFiles) => {
                  return [
                    ...prevHashedInputFiles,
                    {
                      ...hashedFile,
                      found: true,
                      downloaded: false,
                      versionId: foundFile.versionId,
                      absolutePath: foundFile.name,
                      isValid: foundFile.isValid,
                      lastDownloadedDate: null,
                      repo: {
                        name: foundFile?.repo?.name
                      }
                    }
                  ];
                });
              } else {
                downloadedActions.sort(function (a: Action, b: Action) {
                  return (
                    Date.parse(a.startDatetime!) > Date.parse(b.startDatetime!)
                  );
                });

                setFilesHashedInput((prevHashedInputFiles) => {
                  return [
                    ...prevHashedInputFiles,
                    {
                      ...hashedFile,
                      found: true,
                      downloaded: true,
                      versionId: foundFile.versionId,
                      absolutePath: foundFile.name,
                      isValid: foundFile.isValid,
                      lastDownloadedDate: downloadedActions[0]?.startDatetime,
                      repo: {
                        name: foundFile?.repo?.name
                      }
                    }
                  ];
                });
              }
            });
          }
        });
      });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    //reset the input element
    const inputElement = document.getElementById(
      "adfInputFileSelectFormControl"
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
    }
  }, [filesHashedInput]);

  const removeCurrentFileFromInput = (fileName: string) => {
    setFilesHashedInput((prevInput) => {
      return [...prevInput.filter((file) => file.file !== fileName)];
    });
  };

  return {
    saveFilesOutput,
    isLoading,
    setIsUploadModalVisible,
    isUploadModalVisible,
    closeModal,
    wasMutationCalled,
    createActionLoading,
    uploadLoading,
    filesToBeUploaded,
    actionType,
    changeActionType,
    filesHashedOutput,
    removeCurrentFileFromOutput,
    saveFilesDescribing,
    filesHashedDescribing,
    removeCurrentFileFromDescribing,
    saveInputFiles,
    removeCurrentFileFromInput,
    filesHashedInput,
    uncompress,
    setUncompress,
    description,
    setDescription,
    qualityCheckStatus,
    setQualityCheckStatus,
    importAll,
    createActionError,
    uploadLinksError,
    maxNumberOfUploads,
    errorFromUploading,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkError,
    completeMultipartUploadLoading,
    completeMultipartUploadError,
    outputFiles,
    describingFiles,
    changeQualityCheckedStatus,
    treeHierarchyGlobal,
    repoObjectError,
    errorConsumingActions,
    isRepoObjectLoading,
    isLoadingConsumingActions
  };
}
