import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { RepoObject as RepoObjectEE } from "../../backend/executionTypes";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { CreateJob } from "../abstractComponents/createJob";

interface ExtendRepoObjects extends RepoObjectEE {
  absolutePath: string;
}

export function ExecutionNew() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);

  return (
    <>
      <NavBarPmxActivity activeIndex={2} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/executions`}>Execution Jobs</Link>,
          <Typography>Create New Job</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <CreateJob />
      </div>
    </>
  );
}
