import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_SCHEMA_FROM_SPECTRUM = gql`
  mutation importSpectrumSchema(
    $username: String!
    $encryptedPassword: String!
    $schemas: [String!]!
    $format: String!
    $asof: String!
    $dataType: String!
    $snapshotLabel: String
    $qualityCheckStatus: QualityCheckStatus
    $description: String
    $pmxActivityId: Int!
    $absolutePath: String!
  ) {
    importSpectrumSchema(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        schemas: $schemas
        format: $format
        asof: $asof
        dataType: $dataType
        snapshotLabel: $snapshotLabel
        pmxActivityId: $pmxActivityId
        absolutePath: $absolutePath
        qualityCheckStatus: $qualityCheckStatus
        description: $description
      }
    )
  }
`;

export function useImportSpectrumSchemaMutation() {
  const [
    importSpectrumSchema,
    {
      data: importSpectrumSchemaReturned,
      loading: spectrumImportSchemaLoading,
      error: errorSpectrumSchemaImport
    }
  ] = useMutation(MUTATION_IMPORT_SCHEMA_FROM_SPECTRUM, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    importSpectrumSchema,
    importSpectrumSchemaReturned,
    spectrumImportSchemaLoading,
    errorSpectrumSchemaImport
  };
}
