import { gql, useMutation } from "@apollo/client";

export const MUTATION_EDIT_COMMENT = gql`
  mutation editComment(
    $id: ID!
    $text: String!
    $referencedFiles: [String]
    $referencedActions: [String]
  ) {
    editComment(
      input: {
        id: $id
        text: $text
        referencedFiles: $referencedFiles
        referencedActions: $referencedActions
      }
    ) {
      id
    }
  }
`;

export function useEditCommentMutation() {
  const [
    editCommentMutation,
    { loading: isLoadingEditCommentMutation, error: errorEditCommentMutation }
  ] = useMutation(MUTATION_EDIT_COMMENT, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    editCommentMutation,
    isLoadingEditCommentMutation,
    errorEditCommentMutation
  };
}
