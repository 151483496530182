import SparkMD5 from "spark-md5";

export const FILE_CHUNK_SIZE_MB = 8;
export const FILE_CHUNK_SIZE_BIT = FILE_CHUNK_SIZE_MB * 1024 * 1024;
export const MAX_FILE_SIZE_FOR_SINGLE_UPLOAD = FILE_CHUNK_SIZE_BIT * 2;
export const MAX_UPLOADS_PER_BATCH = 15;

export const computeChecksumMd5 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function (): void {
      reject("MD5 computation failed - error reading the file");
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start: number): void {
      const chunk_end = Math.min(file.size, chunk_start + FILE_CHUNK_SIZE_BIT);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = function (e: any): void {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += FILE_CHUNK_SIZE_BIT; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        // This returns the base64 encoded md5 hash, which is what
        // Rails ActiveStorage or cloud services expect
        resolve(spark.end());
        // If you prefer the hexdigest form (looking like
        // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
        // resolve(spark.end());
      }
    };

    processChunk(0);
  });
};

export const splitFileIntoChunks = (file: File): any[] => {
  const accumulationFileArray: any[] = [];
  let cursor = 0; // current cursor in file
  while (cursor < file.size) {
    const chunk_end = Math.min(file.size, cursor + FILE_CHUNK_SIZE_BIT);
    accumulationFileArray.push(file.slice(cursor, chunk_end));
    cursor += FILE_CHUNK_SIZE_BIT; // Move past this chunk
  }

  return accumulationFileArray;
};

export const getChecksums = async (
  e: React.ChangeEvent<HTMLInputElement>
): Promise<any[]> => {
  const input = e.target;
  const checksums: any[] = [];
  if (input.files != null) {
    await Promise.all(
      Array.from(input.files).map(async (file) => {
        await computeChecksumMd5(file)
          .then((value) => {
            checksums.push({ file: file.name, hash: value, size: file.size });
          })
          .catch((error) => {
            console.warn(error);
          });
      })
    );
  }

  return checksums;
};

export const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
export const compressedFileExtensions = [".zip", ".rar", ".tar", ".gz", ".7z"];
export const moviesExtensions = [".mp4", ".wmv", ".mpg", ".mov", ".dir"];
export const installersExtensions = [".exe", ".msi", ".deb", ".rpm", ".dmg"];
export const excelFormatsExtensions = [".csv", ".xls", ".xlsx"];

export const isFileEndsWith = (
  fileName: string,
  extensions: string[]
): boolean => {
  return extensions.some((ext) => fileName.endsWith(ext));
};
