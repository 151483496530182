import { ActionFile, ActionFileType } from "../../backend/types";
import {
  ActionType as ActionTypeHelper,
  ActionTypeType,
  QualityCheckStatus,
  QualityCheckStatusType,
  ActionSource,
  ActionSourceType
} from "../../helpers/stringHelper";
import {
  DataGridPro,
  GridSortDirection,
  GridSortModel,
  GridValueGetterParams,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import FileIcon from "../../components/abstractComponents/fileIcon";
import EditAction from "../editAction";
import { CustomToolbar } from "../../helpers/tableHelper";
import { customFilters } from "../../helpers/tableViewHelper";
import { Link } from "react-router-dom";

// Component used as a popup when an error comes from GraphQL
export default function ActionTable(props: {
  rowsActions: any[];
  onNodeSelect: Function;
  setIsPreselectedRevision?: Function | undefined;
  revisionsToId?: any | undefined;
  selectedRevision?: string | undefined;
  areFilesSplit?: boolean | undefined;
  isExportEnabled?: boolean | undefined;
  onClose: Function;
  refetch?: Function;
}) {
  const handleGetRowIdActions = (row: any) => {
    return row.id;
  };
  const [sortModelActions, setSortModelActions] = useState<GridSortModel>([
    {
      field: "startDatetime",
      sort: "desc" as GridSortDirection
    }
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: "revision",
        operatorValue: "contains",
        value: props.selectedRevision
      }
    ]
  });

  let columnsActions: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <>
          {params.row.id}{" "}
          <EditAction actionId={params.row.id} refetch={props.refetch} />
        </>
      )
    },
    props.revisionsToId && {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        props.revisionsToId[params.row.id as keyof typeof props.revisionsToId]
    },
    {
      field: "actionType",
      headerName: "Type",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        ActionTypeHelper[params.row.actionType as ActionTypeType]
    },
    //if files are split, we put additional columns
    props.areFilesSplit
      ? {
          field: "pmxActivity", //The library does not permit multiple columns on the same property. So we chose other properties that we do not use
          headerName: "Output Files",
          width: 300,
          filterOperators: customFilters,
          valueGetter: (params: GridValueGetterParams) =>
            params.row.actionFile
              .filter(
                (af: ActionFile) => af?.actionFileType === ActionFileType.Output
              )
              .map(
                (af: ActionFile) =>
                  af?.file &&
                  af?.file?.repo?.name +
                    "/" +
                    af?.file.name +
                    " - (" +
                    af?.file.revision +
                    ") " +
                    (af?.file.isValid ? "valid" : "not valid")
              )
              .join(";\n"),
          renderCell: (params: any) => (
            <div key={params.row.id} className="action_list_element">
              {params.row.actionFile
                .filter(
                  (af: ActionFile) =>
                    af?.actionFileType === ActionFileType.Output
                )
                .map((af: ActionFile, index: number) => {
                  if (af?.file) {
                    return (
                      <div
                        className="no_line_hight"
                        key={params.row.id + index}
                      >
                        <b>
                          <FileIcon
                            fileName={af?.file.name}
                            isValid={af?.file.isValid}
                            fontSize={"small"}
                          />{" "}
                          <span
                            className="link-decoration"
                            onClick={() => {
                              props.onNodeSelect(
                                af?.file?.name,
                                af?.file?.versionId,
                                af?.file?.revision,
                                af?.file?.repo?.id
                              );
                              props.onClose();
                            }}
                          >
                            <span className="repo_name_link">
                              {af?.file?.repo?.name}/
                            </span>
                            {af?.file.name}
                          </span>
                        </b>{" "}
                        ({af?.file.revision}) -{" "}
                        {af?.file.isValid ? "valid" : "not valid"}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              {params.row.hasAdditionalFilesLinked && (
                <div>And More Files...</div>
              )}
            </div>
          )
        }
      : {
          field: "files",
          headerName: "Files",
          flex: 6,
          width: 150,
          filterOperators: customFilters,
          valueGetter: (params: any) =>
            [...params.row.actionFile]
              .sort((af1: ActionFile, af2: ActionFile) => {
                return (
                  Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
                  Object.keys(ActionFileType).indexOf(af2?.actionFileType)
                );
              })
              .map(
                (af: ActionFile) =>
                  af?.file &&
                  af?.file?.repo?.name +
                    "/" +
                    af?.file.name +
                    " - (" +
                    af?.file.revision +
                    ") - " +
                    af?.actionFileType
              )
              .join("; "),
          renderCell: (params: any) => (
            <div key={params.row.id} className="action_list_element">
              {[...params.row.actionFile]
                .sort((af1: ActionFile, af2: ActionFile) => {
                  return (
                    Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
                    Object.keys(ActionFileType).indexOf(af2?.actionFileType)
                  );
                })
                .map((af: ActionFile, index: number) => {
                  if (af?.file) {
                    return (
                      <div
                        className="no_line_hight"
                        key={index + params.row.id}
                      >
                        <FileIcon
                          fileName={af?.file.name}
                          isValid={af?.file.isValid}
                          fontSize={"small"}
                        />{" "}
                        <b>
                          <button
                            className="like_a_button"
                            onClick={() => {
                              props.setIsPreselectedRevision &&
                                props.setIsPreselectedRevision(true);
                              af?.file &&
                                props.onNodeSelect(
                                  af?.file.name,
                                  af?.file.versionId,
                                  af?.file.revision,
                                  af?.file.repo?.id
                                );
                            }}
                          >
                            <span className="repo_name_link">
                              {af?.file?.repo?.name}/
                            </span>
                            {af?.file.name} - ({af?.file?.revision}){" "}
                          </button>
                        </b>{" "}
                        - {af?.actionFileType}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              {params.row.hasAdditionalFilesLinked && (
                <div>And More Files...</div>
              )}
            </div>
          )
        },
    props.areFilesSplit && {
      field: "inputFiles", //The library does not permit multiple columns on the same property. So we chose other properties that we do not use
      headerName: "Input Files",
      width: 300,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.actionFile
          .filter(
            (af: ActionFile) => af?.actionFileType === ActionFileType.Input
          )
          .map(
            (af: ActionFile) =>
              af?.file &&
              af?.file?.repo?.name +
                "/" +
                af?.file.name +
                " - (" +
                af?.file.revision +
                ") " +
                (af?.file.isValid ? "valid" : "not valid")
          )
          .join(";\n"),
      renderCell: (params: any) => (
        <div key={params.row.id} className="action_list_element">
          {params.row.actionFile
            .filter(
              (af: ActionFile) => af?.actionFileType === ActionFileType.Input
            )
            .map((af: ActionFile, index: number) => {
              if (af?.file) {
                return (
                  <div className="no_line_hight" key={params.row.id + index}>
                    <b>
                      <FileIcon
                        fileName={af?.file.name}
                        isValid={af?.file.isValid}
                        fontSize={"small"}
                      />{" "}
                      <span
                        className="link-decoration"
                        onClick={() => {
                          props.onNodeSelect(
                            af?.file?.name,
                            af?.file?.versionId,
                            af?.file?.revision,
                            af?.file?.repo?.id
                          );
                          props.onClose();
                        }}
                      >
                        <span className="repo_name_link">
                          {af?.file?.repo?.name}/
                        </span>
                        {af?.file.name}
                      </span>
                    </b>{" "}
                    ({af?.file.revision}) -{" "}
                    {af?.file.isValid ? "valid" : "not valid"}
                  </div>
                );
              } else {
                return "";
              }
            })}
        </div>
      )
    },
    props.areFilesSplit && {
      field: "actionFile",
      headerName: "Describing Files",
      width: 300,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.actionFile
          .filter(
            (af: ActionFile) => af?.actionFileType === ActionFileType.Describing
          )
          .map(
            (af: ActionFile) =>
              af?.file &&
              af?.file?.repo?.name +
                "/" +
                af?.file.name +
                " - (" +
                af?.file.revision +
                ") " +
                +(af?.file.isValid ? "valid" : "not valid")
          )
          .join(";\n"),
      renderCell: (params: any) => (
        <div key={params.row.id} className="action_list_element">
          {params.row.actionFile
            .filter(
              (af: ActionFile) =>
                af?.actionFileType === ActionFileType.Describing
            )
            .map((af: ActionFile, index: number) => {
              if (af?.file) {
                return (
                  <div className="no_line_hight" key={params.row.id + index}>
                    <b>
                      <FileIcon
                        fileName={af?.file.name}
                        isValid={af?.file.isValid}
                        fontSize={"small"}
                      />{" "}
                      <span
                        className="link-decoration"
                        onClick={() => {
                          props.onNodeSelect(
                            af?.file?.name,
                            af?.file?.versionId,
                            af?.file?.revision
                          );
                          props.onClose();
                        }}
                      >
                        <span className="repo_name_link">
                          {af?.file?.repo?.name}/
                        </span>
                        {af?.file.name}
                      </span>
                    </b>
                    ({af?.file.revision}) -{" "}
                    {af?.file.isValid ? "valid" : "not valid"}
                  </div>
                );
              } else {
                return "";
              }
            })}
        </div>
      )
    },
    {
      field: "actionQualityCheckStatus",
      headerName: "QC Status",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.actionQualityCheckStatus
          ? QualityCheckStatus[
              params.row.actionQualityCheckStatus as QualityCheckStatusType
            ]
          : "None"
    },
    {
      field: "startDatetime",
      headerName: "Executed At",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) => params.row.user.email
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "jobId",
      headerName: "Job",
      filterOperators: customFilters,
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        params.row.jobId ? (
          <Link
            className="job_id_action"
            to={`/activity/${params.row.pmxActivity.id}/execution/${params.row.jobId}`}
          >
            {params.row.jobId}
          </Link>
        ) : (
          ""
        )
    },
    {
      field: "sourceFolder",
      headerName: "External Folder",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "sourceFiles",
      headerName: "External Files",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "action",
      headerName: "Activity",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.trialNumber
    },
    {
      field: "Repo",
      headerName: "Repository",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.mainRepository.name
    },
    {
      field: "actionSource",
      headerName: "Environment",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        ActionSource[params.row.actionSource as ActionSourceType]
    },
    {
      field: "actionStatus",
      headerName: "Status",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "actionStatusDescription",
      headerName: "Status Description",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "additionalDetails",
      headerName: "Additional Details/Destination",
      filterOperators: customFilters,
      width: 180,
      hide: true
    }
  ];

  columnsActions = columnsActions.filter((column) => column !== undefined);

  return (
    <>
      {!props.rowsActions || !props.rowsActions ? (
        <>
          <br />
          <br />
          <p>No actions</p>
        </>
      ) : (
        <DataGridPro
          rows={props.rowsActions}
          className="styled_data_grid"
          columns={columnsActions}
          pageSize={25}
          disableSelectionOnClick
          autoHeight
          getRowHeight={() => "auto"}
          getRowId={handleGetRowIdActions}
          sortModel={sortModelActions}
          onSortModelChange={(model) => setSortModelActions(model)}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          components={
            props.isExportEnabled && {
              Toolbar: CustomToolbar
            }
          }
        />
      )}
    </>
  );
}
