import { gql, useMutation } from "@apollo/client";

export const MUTATION_EDIT_ACTION = gql`
  mutation editAction(
    $id: ID!
    $description: String
    $qualityCheckStatus: QualityCheckStatus
    $editedFields: [String!]!
  ) {
    editAction(
      input: {
        id: $id
        description: $description
        qualityCheckStatus: $qualityCheckStatus
        editedFields: $editedFields
      }
    )
  }
`;

export function useEditActionMutation() {
  const [
    editActionMutation,
    { loading: isLoadingEditCommentMutation, error: errorEditActionMutation }
  ] = useMutation(MUTATION_EDIT_ACTION, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    editActionMutation,
    isLoadingEditCommentMutation,
    errorEditActionMutation
  };
}
