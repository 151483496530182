import { gql } from "@apollo/client";

export const ADD_USER_MUTATION = gql`
  mutation addExternalUser(
    $email: String!
    $username: String!
    $company: String
    $name: String!
    $telephone: String!
  ) {
    addExternalUser(
      input: {
        email: $email
        username: $username
        company: $company
        name: $name
        telephone: $telephone
      }
    ) {
      email
    }
  }
`;
