import { gql, useLazyQuery } from "@apollo/client";
export const GET_ACTION = gql`
  query getAction($id: ID!) {
    getAction(input: { id: $id }) {
      __typename
      id
      actionType
      actionFile {
        actionFileType
        file {
          __typename
          name
          versionId
          revision
          isValid
          repo {
            name
            id
          }
        }
      }
      startDatetime
      sourceFolder
      sourceFiles
      user {
        email
      }
      description
      actionQualityCheckStatus
      pmxActivity {
        id
        trialNumber
        mainRepository {
          id
          name
        }
      }
      actionStatus
      actionSource
      actionStatusDescription
      actionHistory {
        id
        updated
        description
        actionQualityCheckStatus
        userId
      }
      historyLabel {
        __typename
        id
        name
      }
      hasAdditionalFilesLinked
      additionalDetails
      jobId
    }
  }
`;

export function useGetActionQuery() {
  const [
    getAction,
    {
      data: action,
      loading: isActionLoading,
      error: errorAction,
      refetch: refetchAction
    }
  ] = useLazyQuery(GET_ACTION, {
    fetchPolicy: "no-cache"
  });
  return {
    getAction,
    action,
    isActionLoading,
    errorAction,
    refetchAction
  };
}
