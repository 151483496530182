import { Navbar } from "react-bootstrap";
export default function NavBarExternal(): JSX.Element {
  return (
    <>
      <Navbar sticky="top" collapseOnSelect expand="lg" variant="light">
        <Navbar.Brand href="/" className="logo_navbar">
          <img src="/images/Bayer-PHIL.svg" alt="PHIL_logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar>
    </>
  );
}
