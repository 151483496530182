import { Button, Form, Row, Col } from "react-bootstrap";

import { useEffect } from "react";

interface Props {
  userData: {
    name: string;
  };
  token: string;
}
// used by internal team to get easy access to the authentication token
export default function Token(props: Props) {
  function handleCopyButtonClick() {
    const textArea = document.getElementById(
      "token-textarea"
    ) as HTMLTextAreaElement | null;
    if (textArea) {
      textArea.focus();
      textArea.value = props.token;
      textArea.select();
      document.execCommand("Copy");
    }
  }

  function handleCopyButtonAuthorizationClick() {
    const textArea = document.getElementById(
      "token-textarea"
    ) as HTMLTextAreaElement | null;
    if (textArea) {
      textArea.focus();
      textArea.value = `{"Authorization": "Bearer ${props.token}"}`;
      textArea.select();
      document.execCommand("Copy");
    }
  }

  useEffect(() => {
    document.title = "PHIL App - Token";
  }, []);

  return (
    <div className="app_content">
      <h2>
        <small>
          Dear {props.userData?.name}, you are signed in! <br />
        </small>
        <br />
        Token:
      </h2>
      <Form.Control
        id="token-textarea"
        as="textarea"
        rows={10}
        onClick={handleCopyButtonClick}
      >
        {props.token}
      </Form.Control>
      <br />
      <Row>
        <Col xs={2}>
          <Button variant="primary" onClick={handleCopyButtonClick}>
            Copy Token
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            variant="primary"
            onClick={handleCopyButtonAuthorizationClick}
          >
            Copy With Authorization
          </Button>
        </Col>
      </Row>

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

Token.defaultProps = {
  userData: null,
  token: null
};
