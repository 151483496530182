import { gql, useMutation } from "@apollo/client";

export const UNCOMPRESS_ARCHIVE_MUTATION = gql`
  mutation uncompressArchive(
    $pmxActivityId: ID!
    $versionId: String!
    $subfolder: String
  ) {
    uncompressArchive(
      input: {
        pmxActivityId: $pmxActivityId
        versionId: $versionId
        subfolder: $subfolder
      }
    )
  }
`;

export function useUncompressArchiveMutation() {
  const [
    uncompressArchiveMutation,
    {
      data: uncompressData,
      loading: isLoadingUncompressMutation,
      error: errorUncompressMutation
    }
  ] = useMutation(UNCOMPRESS_ARCHIVE_MUTATION);

  return {
    uncompressArchiveMutation,
    isLoadingUncompressMutation,
    errorUncompressMutation,
    uncompressData
  };
}
