import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_CONVERSATION = gql`
  mutation createConversation($name: String!, $activityId: ID!) {
    createConversation(input: { name: $name, activityId: $activityId }) {
      id
    }
  }
`;

export function useCreateConversationMutation() {
  const [
    addNewConversation,
    { loading: isLoadingMutation, error: errorMutation }
  ] = useMutation(MUTATION_CREATE_CONVERSATION);

  return {
    addNewConversation,
    isLoadingMutation,
    errorMutation
  };
}
