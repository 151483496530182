import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  EnvironmentVariableInput,
  ActionType as ExecutionActionType,
  RepoObject as RepoObjectEE
} from "../../backend/executionTypes";
import { useSubmitExecutionJobMutation } from "../../backend/hooks/execution/mutationSubmitJob";
import { useSubmitExecutionJobFromInteractiveMutation } from "../../backend/hooks/interactive/mutationSubmitJob";

import { useListExecutionImagesQuery } from "../../backend/hooks/execution/queryGetImages";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import {
  ActionType,
  ActionTypeType,
  QualityCheckStatus,
  QualityCheckStatusType,
  getActionTypeKeyFromValue,
  getQualityCheckStatusKeyFromValue
} from "../../helpers/stringHelper";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { FileSelectModal } from "../abstractComponents/fileSelectModal";
import { FileType } from "../abstractComponents/fileTreeView";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ApolloError } from "@apollo/client/errors";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import { useQueryGetExecImageRecommendations } from "../../backend/hooks/interactive/queryGetExecutionImageRecommendations";
import { useQueryGetExecInputRecommendations } from "../../backend/hooks/interactive/queryGetExecutionInputRecommendations";

interface ExtendRepoObjects extends RepoObjectEE {
  absolutePath: string;
}

export function CreateJob(props: {
  parentLoading?: boolean[];
  parentError?: ApolloError[];
  treeSelection?: {
    interactiveTreeSelection?: any;
    environmentId?: string;
    getFilesForSelection?:
      | LazyQueryExecFunction<any, OperationVariables>
      | undefined;
  };
}) {
  const history = useHistory();
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const { isBayerUser } = useUserHelper();
  const [executionName, setExecutionName] = useState<string>("");
  const [executionDescription, setExecutionDescription] = useState<string>("");
  const [executionImage, setExecutionImage] = useState<string>("");
  const [executionScript, setExecutionScript] = useState<ExtendRepoObjects>({
    repositoryId: "",
    versionId: "",
    absolutePath: ""
  });
  const [executionInputs, setExecutionInputs] = useState<any>([]);
  const [executionWorkingDir, setExecutionWorkingDir] = useState<string>("/");
  const [executionActionType, setExecutionActionType] = useState(
    ActionType.ADF_CREATION
  );
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus | null>(null);
  const [timeout, setTimeout] = useState<number>(14);
  const [nodes, setNodes] = useState<number>(1);
  const [vCPU, setVCPU] = useState<number>(4);
  const [memory, setMemory] = useState<number>(4);
  const [temporaryValue, setTemporaryValue] = useState<any>();
  const [modalConfig, setModalConfig] = useState<{
    open: boolean;
    fileType?: FileType;
    text?: string | any;
    location?: string;
  }>({
    open: false
  });
  const [customCommand, setCustomCommand] = useState<string>("");

  const [environmentVariables, setEnvironmentVariables] = useState<
    EnvironmentVariableInput[] | []
  >([]);
  const [environmentalVariableName, setEnvironmentalVariableName] =
    useState<string>("");
  const [environmentalVariableValue, setEnvironmentalVariableValue] =
    useState<string>("");

  const defaultIsCustomCommandVisible =
    localStorage.getItem("defaultIsCustomCommandVisible") &&
    localStorage.getItem("defaultIsCustomCommandVisible") === "true"
      ? Boolean(localStorage.getItem("defaultIsCustomCommandVisible"))
      : false;
  const [isCustomCommandVisible, setIsCustomCommandVisible] = useState(
    defaultIsCustomCommandVisible
  );

  const getCommandRecommendation = (executionImageId: string): string => {
    const recommendations = getImageById(executionImageId).recommendations;
    let command: string;
    if (recommendations.length == 1) {
      command = recommendations[0].command;
    } else {
      command = recommendations.find((recommendation: any) => {
        return recommendation.isMultinode === false;
      }).command;
    }

    return command;
  };

  const {
    executionImages,
    isLoadingGetExecutionImages,
    errorGetExecutionImages
  } = useListExecutionImagesQuery();

  const { submitJob, isSubmitExecutionJobLoading, errorSubmitExecutionJob } =
    useSubmitExecutionJobMutation();

  const {
    submitJobFromIE,
    isSubmitExecutionJobIELoading,
    errorSubmitExecutionJobIE
  } = useSubmitExecutionJobFromInteractiveMutation();

  const {
    getExecutionImageRecommendations,
    errrorExecutionImageRecommendations,
    isLoadingExecutionImageRecommendations
  } = useQueryGetExecImageRecommendations();

  const {
    getExecutionInputRecommendations,
    isLoadingExecutionInputRecommendations,
    errrorExecutionInputRecommendations
  } = useQueryGetExecInputRecommendations();

  const { refetchRepoObjects, isLoadingGetRepoObjects } = useContext(
    BrowsePmxActivityContext
  );

  const updateFileSelectionTemporary = (
    type: FileType,
    node: ExtendRepoObjects
  ) => {
    if (type === FileType.Script) {
      setTemporaryValue({
        repositoryId: node.repositoryId,
        versionId: node.versionId,
        absolutePath: node.absolutePath
      });
    } else if (type === FileType.Input) {
      setTemporaryValue(node);
    } else if (type === FileType.WorkingDir) {
      setTemporaryValue(node);
    }
  };

  const openModal = (fileType: FileType) => {
    if (
      props.treeSelection?.environmentId &&
      props.treeSelection?.getFilesForSelection
    ) {
      props?.treeSelection?.getFilesForSelection({
        variables: {
          environmentId: props.treeSelection?.environmentId,
          activityId: activityId
        }
      });
    }
    if (fileType === FileType.Input) {
      setModalConfig({
        open: true,
        fileType,
        text: "Select Input Files"
      });
    } else if (fileType === FileType.WorkingDir) {
      setModalConfig({
        open: true,
        fileType,
        text: "Select Working Directory"
      });
    } else if (fileType === FileType.Script) {
      setModalConfig({
        open: true,
        fileType,
        text: "Select Your Script File"
      });
    }
  };

  const onConfirmModal = () => {
    if (modalConfig.fileType === FileType.Script) {
      if (temporaryValue) {
        setExecutionScript(temporaryValue);

        if (temporaryValue.absolutePath) {
          const stripWorkingDirectory: any =
            temporaryValue.absolutePath.split("/");
          stripWorkingDirectory.pop();
          setExecutionWorkingDir(`${stripWorkingDirectory.join("/")}/`);
        }

        if (props.treeSelection?.environmentId) {
          const variables = {
            activityId: activityId,
            environmentId: props.treeSelection?.environmentId,
            path: temporaryValue.absolutePath
          };
          getExecutionInputRecommendations({
            variables: variables
          }).then(({ data }) => {
            // always main activity
            const dataInputs = data.getExecutionInputRecommendations.inputs.map(
              (file: string) => ({
                repositoryId: activityData.mainRepository?.id,
                versionId: "",
                absolutePath: file.substring(1)
              })
            );
            setExecutionInputs(dataInputs);
          });
          getExecutionImageRecommendations({
            variables: variables
          }).then(({ data }) => {
            const image =
              data.getExecutionImageRecommendations.images[0].toString();
            setExecutionImage(image);
            setCustomCommand(getCommandRecommendation(image));
          });
        }
      }
    } else if (modalConfig.fileType === FileType.Input) {
      temporaryValue
        ? setExecutionInputs(
            temporaryValue.filter(
              (item: any) => !item.absolutePath.endsWith("/")
            )
          )
        : setExecutionInputs((prevState: any) => prevState);
    } else if (modalConfig.fileType === FileType.WorkingDir) {
      temporaryValue
        ? setExecutionWorkingDir(temporaryValue)
        : setExecutionWorkingDir((prevState: any) => prevState);
    }
    setTemporaryValue("");
    setModalConfig({ open: false });
  };

  const getFileSelection = () => {
    if (modalConfig.fileType === FileType.Script) {
      return executionScript["absolutePath"];
    } else if (modalConfig.fileType === FileType.Input) {
      return executionInputs;
    } else if (modalConfig.fileType === FileType.WorkingDir) {
      return executionWorkingDir;
    }
  };

  const getImageById = (imageId: string) => {
    return executionImages?.find((x: any) => x.id === imageId);
  };

  const addEnvironmentalVariablesEntry = () => {
    setEnvironmentVariables([
      ...environmentVariables,
      {
        name: environmentalVariableName,
        value: environmentalVariableValue
      }
    ]);
    setEnvironmentalVariableName("");
    setEnvironmentalVariableValue("");
  };

  const handleDeleteEnvironmentalVariableChip = (name: String) => {
    setEnvironmentVariables(
      environmentVariables.filter((parameter: any) => parameter.name !== name)
    );
  };

  const resetJob = () => {
    setExecutionName("");
    setExecutionDescription("");
    setExecutionImage("");
    setExecutionScript({ repositoryId: "", versionId: "", absolutePath: "" });
    setExecutionInputs([]);
    setExecutionWorkingDir(`${activityData?.mainRepository?.name}/`);
    setExecutionActionType(ActionType.ADF_CREATION);
    setQualityCheckStatus(null);
    setTimeout(14);
    setNodes(1);
    setCustomCommand("");
    setEnvironmentVariables([]);
  };

  const isFileHiddenInTreeView = (file: any) => {
    const fileType = modalConfig.fileType;
    if (fileType === FileType.Script) {
      return file.isDir;
    }
    return !file.isDir;
  };

  const createExecutionJob = () => {
    const submitJobInput = {
      name: executionName,
      imageId: executionImage,
      activityId: activityData.id,
      actionType: getActionTypeKeyFromValue(executionActionType),
      qualityCheckStatus: getQualityCheckStatusKeyFromValue(qualityCheckStatus),
      workingDir: executionWorkingDir,
      description: executionDescription,
      command: customCommand,
      environmentVariables: environmentVariables,
      timeout: timeout * 86400,
      memory: memory * 1024,
      vCPU: vCPU,
      numOfNodes: getImageById(executionImage)?.recommendations.some(
        (e: { isMultinode: boolean }) => e.isMultinode
      )
        ? nodes
        : 1
    };
    if (props.treeSelection?.environmentId) {
      submitJobFromIE({
        variables: {
          ...submitJobInput,
          environmentId: props.treeSelection?.environmentId,
          entrypoint: executionScript.absolutePath,
          inputs: executionInputs
            .filter(
              (input: any) =>
                input.absolutePath !== executionScript.absolutePath
            )
            .map((input: any) => input.absolutePath)
        }
      }).then(({ data }) => {
        history.push({
          pathname: `/activity/${activityId}/execution/${data.submitExecutionJob.id}`,
          state: data.submitExecutionJob
        });
      });
    } else {
      submitJob({
        variables: {
          ...submitJobInput,
          entrypoint: {
            repositoryId: executionScript.repositoryId,
            versionId: executionScript.versionId
          },
          inputs: executionInputs
            .filter(
              (x: any) =>
                x.versionId !== "" &&
                x.absolutePath !== executionScript.absolutePath
            )
            .map((x: any) => ({
              repositoryId: x.repositoryId,
              versionId: x.versionId
            }))
        }
      }).then(({ data }) => {
        history.push({
          pathname: `/activity/${activityId}/execution/${data.submitJob.id}`,
          state: data.submitJob
        });
      });
    }
  };

  const handleImageChange = (event: SelectChangeEvent) => {
    setExecutionImage(event.target.value as string);
    setNodes(1);
    setCustomCommand(getCommandRecommendation(event.target.value));
  };

  const handleOnNodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0 && Number(event.target.value) <= 32) {
      setNodes(Number(event.target.value));
      let command: string;
      const recommendations = getImageById(executionImage).recommendations;

      if (Number(event.target.value) > 1) {
        command = command = recommendations.find((recommendation: any) => {
          return recommendation.isMultinode === true;
        }).command;
      } else {
        command = recommendations.find((recommendation: any) => {
          return recommendation.isMultinode === false;
        }).command;
      }

      setCustomCommand(command);
    }
  };

  const isLoadingConditions = [
    !activityData,
    isLoadingGetExecutionImages,
    isSubmitExecutionJobLoading,
    isLoadingGetRepoObjects,
    isSubmitExecutionJobIELoading,
    isLoadingExecutionImageRecommendations,
    isLoadingExecutionInputRecommendations,
    ...(props.parentLoading ?? [])
  ];
  const isLoading = isLoadingConditions.some((condition) => condition);

  const errorConditions = [
    errorGetExecutionImages,
    errorSubmitExecutionJob,
    errorSubmitExecutionJobIE,
    errrorExecutionImageRecommendations,
    errrorExecutionInputRecommendations,
    errorGetExecutionImages,
    ...(props.parentError ?? [])
  ];
  const error = errorConditions.find((condition) => condition);

  useEffect(() => {
    localStorage.setItem(
      "defaultIsCustomCommandVisible",
      String(isCustomCommandVisible)
    );
    if (!isCustomCommandVisible && executionImage) {
      setCustomCommand(getCommandRecommendation(executionImage));
    }
  }, [isCustomCommandVisible]);

  useEffect(() => {
    !props.treeSelection?.environmentId && refetchRepoObjects();
  }, []);

  return (
    <>
      {isBayerUser && (
        <ErrorBoundary>
          <Container fluid className="modal-75w">
            {isLoading && (
              <Row>
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              </Row>
            )}
            <>
              <div className="h4">Create New Job</div>
              <hr />
              <div className="mt-4">
                <TextField
                  required
                  id="job-script"
                  label="Script"
                  className="w-100"
                  defaultValue={executionScript.absolutePath}
                  onClick={() => openModal(FileType.Script)}
                  InputProps={{
                    onKeyDown: (event) => {
                      event.preventDefault();
                    },
                    ...(executionScript.absolutePath !== "" && {
                      startAdornment: (
                        <>
                          <Chip
                            className="chip"
                            label={executionScript.absolutePath}
                          />
                        </>
                      )
                    })
                  }}
                />
              </div>
              <div className="mt-4">
                <TextField
                  id="job-input-files"
                  label="Input Files"
                  className="w-100"
                  onClick={() => openModal(FileType.Input)}
                  InputProps={{
                    onKeyDown: (event) => {
                      event.preventDefault();
                    },
                    ...(executionInputs.length > 0 && {
                      startAdornment: (
                        <div>
                          {executionInputs.map((x: any, index: number) => (
                            <Chip
                              className="chip m-2"
                              key={index}
                              label={x.absolutePath}
                            />
                          ))}
                        </div>
                      )
                    })
                  }}
                />
              </div>
              <Grid container spacing={2} className="mt-2">
                <Grid item xs={4}>
                  <FormControl className="w-100" required>
                    <InputLabel>CPUs</InputLabel>
                    <OutlinedInput
                      label="CPUs"
                      type="number"
                      id="job-cpus"
                      endAdornment={
                        <InputAdornment position="end">CPU(s)</InputAdornment>
                      }
                      onChange={(e) =>
                        Number(e.target.value) > 0 &&
                        Number(e.target.value) <= 96 &&
                        setVCPU(Number(e.target.value))
                      }
                      value={vCPU}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl className="w-100" required>
                    <InputLabel>Memory</InputLabel>
                    <OutlinedInput
                      label="Memory"
                      type="number"
                      id="job-memory"
                      endAdornment={
                        <InputAdornment position="end">GB</InputAdornment>
                      }
                      inputProps={{
                        maxLength: 122.88,
                        step: "0.001"
                      }}
                      onChange={(e) =>
                        Number(e.target.value) > 0.512 &&
                        Number(e.target.value) <= 122.88 &&
                        setMemory(Number(e.target.value))
                      }
                      value={memory}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className="w-100"
                    disabled={
                      !getImageById(executionImage)?.requirements.isMultinode
                    }
                  >
                    <InputLabel>Nodes</InputLabel>
                    <OutlinedInput
                      label="Nodes"
                      type="number"
                      id="job-nodes"
                      endAdornment={
                        <InputAdornment position="end">Node(s)</InputAdornment>
                      }
                      onChange={(event: any) => handleOnNodeChange(event)}
                      value={nodes}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <h5>Command</h5>
              <FormGroup>
                <FormControlLabel
                  id="execution-command-input-switch"
                  control={
                    <Switch
                      checked={isCustomCommandVisible}
                      onChange={() => {
                        setIsCustomCommandVisible(!isCustomCommandVisible);
                      }}
                    />
                  }
                  label="Customize Command?"
                />
              </FormGroup>
              {isCustomCommandVisible && (
                <div className="my-4">
                  <TextField
                    required
                    multiline
                    id="execution-command-input"
                    label="Execute Command"
                    variant="outlined"
                    className="w-100"
                    value={customCommand}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCustomCommand(event.target.value);
                    }}
                  />
                </div>
              )}
              <div className="mt-4">
                <FormControl fullWidth required>
                  <InputLabel>Image</InputLabel>
                  <Select
                    required
                    id="execution-image"
                    value={executionImage}
                    label="Image"
                    onChange={(event: SelectChangeEvent) => {
                      handleImageChange(event);
                    }}
                  >
                    {executionImages?.map((image: any) => (
                      <MenuItem value={image.id} key={image.id}>
                        <Row id={`row-image-${image.id}`}>
                          <Typography
                            sx={{ width: "200px" }}
                            variant="button"
                            display="block"
                          >
                            {image.name}
                          </Typography>
                          <div className="d-flex gap-4 fs-12px">
                            <p className="mb-0">
                              Env Type: {image.environmentType}
                            </p>
                            <p className="mb-0">
                              Env Version: {image.environmentTypeVersion}
                            </p>
                            <p className="mb-0">
                              {" "}
                              Validation Type: {image.validationType}
                            </p>
                            <p className="mb-0">
                              Validation Type Version:{" "}
                              {image.validationTypeVersion}
                            </p>
                          </div>
                        </Row>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <TextField
                  required
                  id="execution-name"
                  label="Job Name"
                  className="w-100"
                  value={executionName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setExecutionName(event.target.value);
                  }}
                />
              </div>
              <div className="mt-4">
                <TextField
                  id="execution-description"
                  label="Description"
                  variant="outlined"
                  className="w-100"
                  value={executionDescription}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setExecutionDescription(event.target.value);
                  }}
                />
              </div>
              <div className="mt-4 d-flex gap-1">
                <FormControl required className="w-50">
                  <InputLabel>Action Type</InputLabel>
                  <Select
                    required
                    id="execution-action"
                    value={getActionTypeKeyFromValue(executionActionType)}
                    label="Action Type"
                    onChange={(event: SelectChangeEvent) => {
                      setExecutionActionType(
                        ActionType[event.target.value as ActionTypeType]
                      );
                    }}
                  >
                    {Object.entries(ActionType)
                      .filter((element) =>
                        Object.values(ExecutionActionType).includes(
                          element[0] as any
                        )
                      )
                      .map((element) => (
                        <MenuItem key={element[0]} value={element[0]}>
                          {element[1]}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl required className="w-50">
                  <InputLabel>Quality Control Status</InputLabel>
                  <Select
                    required
                    id="execution-quality"
                    value={String(
                      getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                    )}
                    label="Quality Control Status"
                    onChange={(event: SelectChangeEvent) => {
                      setQualityCheckStatus(
                        QualityCheckStatus[
                          event.target.value as QualityCheckStatusType
                        ]
                      );
                    }}
                  >
                    <MenuItem key={null} value="null">
                      None
                    </MenuItem>
                    {Object.entries(QualityCheckStatus).map((element) => (
                      <MenuItem key={element[0]} value={element[0]}>
                        {element[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <TextField
                  id="job-working-dir"
                  label="Working Directory"
                  className="w-100"
                  onClick={() => openModal(FileType.WorkingDir)}
                  disabled={executionScript.absolutePath === ""}
                  InputProps={{
                    onKeyDown: (event) => {
                      event.preventDefault();
                    },
                    startAdornment: (
                      <>
                        <Chip className="chip" label={executionWorkingDir} />
                      </>
                    )
                  }}
                />
              </div>
              <br />
              <h5>Environment Variables</h5>
              <Row className="my-4">
                <Col xs={4}>
                  <TextField
                    id="environmental-variables-name"
                    label="Parameter Name"
                    variant="outlined"
                    className="w-100"
                    value={environmentalVariableName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEnvironmentalVariableName(event.target.value);
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <TextField
                    id="environmental-variables-value"
                    label="Value"
                    variant="outlined"
                    className="w-100"
                    value={environmentalVariableValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEnvironmentalVariableValue(event.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    id="add-environmental-variable-entry"
                    className="mt-2"
                    disabled={
                      !environmentalVariableName || !environmentalVariableValue
                    }
                    onClick={addEnvironmentalVariablesEntry}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <div className="execution-parameter-chip-holder mt-2">
                {environmentVariables.map((parameter: any) => (
                  <Chip
                    avatar={
                      <Chip
                        className="avatar-parameter"
                        color="primary"
                        label={parameter.name}
                      />
                    }
                    label={parameter.value}
                    onDelete={() =>
                      handleDeleteEnvironmentalVariableChip(parameter.name)
                    }
                    key={"avatar-parameter" + parameter.name}
                  />
                ))}
              </div>
              <div className="mt-4 d-flex gap-1">
                <FormControl className="w-50">
                  <InputLabel>Timeout</InputLabel>
                  <OutlinedInput
                    label="Timeout"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">Day(s)</InputAdornment>
                    }
                    onChange={(e) =>
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) <= 14 &&
                      setTimeout(Number(e.target.value))
                    }
                    value={timeout}
                  />
                </FormControl>
              </div>
              <div className="mt-4 d-flex justify-content-end w-100">
                <div className="d-flex justify-content-end w-50">
                  <Button
                    variant="secondary"
                    id="job-reset-new-button"
                    className="w-25 me-2"
                    onClick={resetJob}
                    disabled={isSubmitExecutionJobLoading}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="primary"
                    id="job-create-new-button"
                    className="w-25"
                    onClick={createExecutionJob}
                    disabled={
                      executionName === "" ||
                      executionImage === "" ||
                      executionScript.versionId === "" ||
                      customCommand === "" ||
                      isSubmitExecutionJobLoading ||
                      isSubmitExecutionJobIELoading
                    }
                  >
                    Create
                  </Button>
                </div>
              </div>
            </>

            <FileSelectModal
              setModalAction={setModalConfig}
              updateFileSelection={updateFileSelectionTemporary}
              onConfirmModal={onConfirmModal}
              modelConfig={modalConfig}
              selectedFile={getFileSelection()}
              isFileHidden={isFileHiddenInTreeView}
              treeSelection={props.treeSelection?.interactiveTreeSelection}
              parentLoading={props.parentLoading}
            />
          </Container>
        </ErrorBoundary>
      )}

      {error && <Error error={error} />}
    </>
  );
}
