import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { GridColumns } from "@mui/x-data-grid";
import { DataGridPro, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { ActionHistory } from "../../backend/types";
import {
  QualityCheckStatus,
  QualityCheckStatusType
} from "../../helpers/stringHelper";
import { customFilters } from "../../helpers/tableViewHelper";

interface HistoryVersionListProps {
  actionHistory: ActionHistory[];
  isActionLoading: boolean;
  refetchAction: (
    variables?: Partial<OperationVariables> | undefined // eslint-disable-line no-unused-vars
  ) => Promise<ApolloQueryResult<any>>;
  actionId: string;
  isNonEditableAction: boolean;
}

export default function HistoryVersionList(props: HistoryVersionListProps) {
  const columns: GridColumns = [
    {
      field: "updated",
      headerName: "Date",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "userId",
      headerName: "User",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "description",
      headerName: "Description",
      filterOperators: customFilters,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.description;
      }
    },
    {
      field: "actionQualityCheckStatus",
      headerName: "QC Status",
      filterOperators: customFilters,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.actionQualityCheckStatus
          ? QualityCheckStatus[
              params.row.actionQualityCheckStatus as QualityCheckStatusType
            ]
          : "None"
    }
  ];

  return (
    <>
      <DataGridPro
        className="w-100"
        autoHeight
        rows={props.actionHistory as ActionHistory[]}
        getRowId={(row) => row.id}
        columns={columns}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        hideFooterRowCount
        hideFooterSelectedRowCount
      />
    </>
  );
}
