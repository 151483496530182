import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Reply from "@mui/icons-material/Reply";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import MuiButton from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DOMPurify from "dompurify";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useCreateCommentMutation } from "../../backend/hooks/pmxActivity/mutationCreateComment";
import { useCreateConversationMutation } from "../../backend/hooks/pmxActivity/mutationCreateConversation";
import { useDeleteCommentMutation } from "../../backend/hooks/pmxActivity/mutationDeleteComment";
import { useEditCommentMutation } from "../../backend/hooks/pmxActivity/mutationEditComment";
import { Comment, Maybe, RepoObject, Action } from "../../backend/types";
import FileIcon from "../../components/abstractComponents/fileIcon";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { popupImage } from "../../helpers/imgHelper";
import {
  formatStringToDateTime,
  removeTags,
  replaceLast,
  shortenString,
  quillModules,
  formats
} from "../../helpers/stringHelper";
import { COMMENT_ID_LABEL } from "../../helpers/urlHelper";
import { MAX_COMMENT_SIZE } from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import { useAuth } from "../authContextProvider";
import { useCreationalActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetCreationalActions";
import EditAction from "../editAction";
import UserManualLink from "../abstractComponents/usermanual";

export default function ViewConversations(props: {
  onNodeSelect: Function;
  setSelectedCommentIdFromMenu: Function;
  selectedCommentIdFromMenu: null | number | undefined;
  refetchRepoObjects: Function;
  hideText: boolean;
}) {
  const { getCreationalActions, creationalActions } =
    useCreationalActionsLazyQuery();

  const { activityData, isActivityLoading, refetchActivityDetails } =
    useContext(PmxActivityDetailsContext);

  const { decodedToken } = useAuth();

  const [isConversationWindowOpen, setIsConversationWindowOpen] =
    useState(false);
  const [conversationIndex, setConversationIndex] = useState("");
  const [newConversationName, setNewConversationName] = useState("");
  const [repliedToComment, setRepliedToComment] = useState<Comment | null>(
    null
  );
  const [editingComment, setEditingComment] = useState<Comment | null>(null);
  const [currentCommentList, setCurrentCommentList] = useState<
    Maybe<Comment>[]
  >([]);
  const [isAddNewConversationVisible, setIsAddNewConversationVisible] =
    useState(false);
  const [isWriteNewCommentVisible, setIsWriteNewCommentVisible] =
    useState(false);
  const [newCommentText, setNewCommentText] = useState("");
  const [referencedFiles, setReferencedFiles] = useState<RepoObject[]>([]);
  const [referencedActionIds, setReferencedActionIds] = useState<number[]>([]);
  const [isUserReferenceVisible, setIsUserReferenceVisible] = useState(false);
  const [currentSelectedActionId, setCurrentSelectedActionId] = useState<
    number | undefined
  >();

  const handleConversationChange = (event: SelectChangeEvent) => {
    setConversationIndex(event.target.value as string);
  };

  const {
    addNewComment,
    isLoadingCreateCommentMutation,
    errorCreateCommentMutation
  } = useCreateCommentMutation();

  const {
    deleteCommentMutation,
    loadingDeleteMutation,
    errorDeleteCommentMutation
  } = useDeleteCommentMutation();

  const {
    editCommentMutation,
    isLoadingEditCommentMutation,
    errorEditCommentMutation
  } = useEditCommentMutation();

  function deleteComment(comment: Comment) {
    deleteCommentMutation({
      variables: {
        id: comment.id
      }
    }).then(() => {
      if (comment.referencedFiles && comment.referencedFiles?.length > 0) {
        props.refetchRepoObjects(true);
      }
      refetchActivityDetails();
    });
  }

  function saveNewComment() {
    addNewComment({
      variables: {
        text: newCommentText,
        conversationId: Number(conversationIndex),
        repliedToCommentId: repliedToComment
          ? Number(repliedToComment?.id)
          : null,
        referencedFiles: referencedFiles.map(
          (file: RepoObject) => file.versionId
        ),
        referencedActions: referencedActionIds
      }
    }).then(() => {
      if (referencedFiles.length > 0) {
        props.refetchRepoObjects(true);
      }
      refetchActivityDetails();
      closeNewCommentWindow();
    });
  }

  //drawer will allow to browse the background if we can write a new comment
  useEffect(() => {
    //otherwise it gets overwritten by MUI the library
    setTimeout(() => {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      document
        .getElementById("conversation_drawer_element_paper")
        ?.removeAttribute("tabindex");

      if (isWriteNewCommentVisible) {
        const ele = document.getElementById("save_comment_button");
        if (ele) {
          document
            .getElementById("conversation_drawer_element_paper")!
            .scrollTo(ele!.offsetLeft, ele!.offsetTop);
        }
      }
    }, 100);
  }, [isWriteNewCommentVisible, isConversationWindowOpen]);

  useEffect(() => {
    if (
      conversationIndex === "" &&
      activityData.conversations?.length > 0 &&
      activityData.conversations[0]
    ) {
      setConversationIndex(String(activityData.conversations[0].id));
    }
  }, [activityData.conversations]);

  // when we provide a comment from the parent component
  useEffect(() => {
    if (props.selectedCommentIdFromMenu) {
      //we get the proper conversation ID and set it
      activityData.conversations.forEach((conversation) => {
        if (
          conversation &&
          conversation.comments.filter(
            (comment) =>
              comment && comment.id === String(props.selectedCommentIdFromMenu)
          ).length > 0
        ) {
          setConversationIndex(conversation.id);
        }
      });
      setIsConversationWindowOpen(true);
      setTimeout(() => {
        window.location.href =
          "#" + COMMENT_ID_LABEL + props.selectedCommentIdFromMenu;
      }, 100);
    }
  }, [props.selectedCommentIdFromMenu]);

  const { addNewConversation, isLoadingMutation, errorMutation } =
    useCreateConversationMutation();

  useEffect(() => {
    if (
      activityData.conversations?.find(
        (conversation) => conversation?.id === conversationIndex
      )?.comments
    ) {
      const filteredComments = activityData.conversations.find(
        (conversation) => conversation?.id === conversationIndex
      )?.comments;
      if (filteredComments) {
        const commentList = [...filteredComments];
        commentList.sort(function (a: Maybe<Comment>, b: Maybe<Comment>) {
          return !a || !b ? -1 : Number(a?.id) - Number(b?.id);
        });

        setCurrentCommentList(commentList);
      } else {
        setCurrentCommentList([]);
      }
    } else {
      setCurrentCommentList([]);
    }
    popupImage(".comment_conversation_inner");
  }, [activityData, conversationIndex, isConversationWindowOpen]);

  function saveNewConversation() {
    addNewConversation({
      variables: {
        activityId: Number(activityData.id),
        name: newConversationName
      }
    }).then(() => {
      setNewConversationName("");
      refetchActivityDetails();
      setIsAddNewConversationVisible(false);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      if (isUserReferenceVisible) {
        document.getElementById("autocomplete-user-label")?.focus();
      }
    }, 100); //allow time to render
  }, [isUserReferenceVisible]);

  const handleAutocompleteChange = (user: string | null) => {
    if (user) {
      const userSelected = activityData.allAccessUser.find((userLoop) =>
        user.endsWith(" - " + userLoop!.email)
      );
      setNewCommentText((prevComment) => {
        return replaceLast(
          "@",
          `<a href="mailto:${userSelected?.email}">${userSelected?.name}</a> `,
          prevComment
        );
      });
    }

    setIsUserReferenceVisible(false);
    document.getElementById("comment_editor_bottom")?.focus();
  };

  async function resizeBase64Image(base64Image: string) {
    return new Promise((resolve) => {
      const maxSizeInBytes = 170000;
      const img = new Image();
      img.src = base64Image;
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;
        const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio);
        const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio);
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx!.drawImage(img, 0, 0, newWidth, newHeight);
        let quality = 0.8;
        let dataURL = canvas.toDataURL("image/jpeg", quality);

        resolve(dataURL);
      };
    });
  }

  const handleCommentWriting = (commentText: string) => {
    // We resize every image, so we don't block our database
    const newHtmlOfComment = document.createElement("div");
    newHtmlOfComment.innerHTML = commentText;
    let imageObjects = newHtmlOfComment.getElementsByTagName("img");
    for (var i = 0; i < imageObjects.length; i++) {
      const img = imageObjects[i];
      if (img.getAttribute("src")!.length > MAX_COMMENT_SIZE) {
        resizeBase64Image(img.getAttribute("src")!).then((resized: string) => {
          img.setAttribute("src", resized);
          setNewCommentText(newHtmlOfComment.innerHTML);
        });
      }
    }

    if (commentText.includes("<p>@</p>") || commentText.includes(" @</p>")) {
      setIsUserReferenceVisible(true);
    } else if (isUserReferenceVisible) {
      setIsUserReferenceVisible(false);
    }

    if (newCommentText.length < MAX_COMMENT_SIZE) {
      setNewCommentText(commentText);
    } else {
      setNewCommentText(commentText.slice(0, MAX_COMMENT_SIZE));
    }
  };

  const getPreviewURL = (
    versionId: string,
    absolutePath: string,
    repoId: string,
    commentId: string
  ) => {
    return (
      "/activity/" +
      activityData.id +
      encodeURIComponent(
        "/" +
          absolutePath +
          "?versionId=" +
          versionId +
          "&repoId=" +
          repoId +
          "&commentId=" +
          commentId
      )
    );
  };

  const handlePreview = (
    versionId: string,
    absolutePath: string,
    repoId: string,
    commentId: string
  ) => {
    window.location.href = getPreviewURL(
      versionId,
      absolutePath,
      repoId,
      commentId
    );
  };

  function clickEditComment(comment: Comment) {
    setEditingComment(comment);
    setNewCommentText(comment.text);
    setIsWriteNewCommentVisible(true);
    setReferencedFiles(
      //@ts-ignore
      comment && comment.referencedFiles ? comment.referencedFiles : []
    );
    setReferencedActionIds(
      //@ts-ignore
      comment && comment.referencedActions ? comment.referencedActions : []
    );
  }

  function editComment() {
    editCommentMutation({
      variables: {
        text: newCommentText,
        id: editingComment?.id,
        referencedFiles: referencedFiles.map(
          (file: RepoObject) => file.versionId
        ),
        referencedActions: referencedActionIds.map((action: any) =>
          action.id ? action.id : action
        )
      }
    }).then(() => {
      refetchActivityDetails();
      closeNewCommentWindow();
      props.refetchRepoObjects(true);
    });
  }

  function allowDropThis(e: any) {
    e.preventDefault();
  }

  function dropFileToReference(e: any) {
    e.preventDefault();
    setReferencedFiles((prevState) => [
      ...prevState.filter(
        (stateFile: RepoObject) =>
          stateFile.versionId !== e.dataTransfer.getData("versionId")
      ),
      {
        id: e.dataTransfer.getData("versionId"),
        absolutePath: e.dataTransfer.getData("absolutePath"),
        isDir: e.dataTransfer.getData("isDir"),
        revision: e.dataTransfer.getData("revision"),
        name: e.dataTransfer.getData("absolutePath"),
        isValid: e.dataTransfer.getData("isValid"),
        size: e.dataTransfer.getData("size"),
        versionId: e.dataTransfer.getData("versionId"),
        createdBy: e.dataTransfer.getData("createdBy"),
        created: e.dataTransfer.getData("created"),
        md5: e.dataTransfer.getData("md5")
      }
    ]);
  }

  function dropFileToReferenceAction(e: any) {
    e.preventDefault();
    getCreationalActions({
      variables: {
        versionIds: [e.dataTransfer.getData("versionId")]
      }
    });
  }

  useEffect(() => {
    if (
      creationalActions &&
      creationalActions.getCreationalActions &&
      creationalActions.getCreationalActions[0]
    ) {
      setReferencedActionIds((prevState) => {
        return [
          ...prevState.filter(
            (actionId) =>
              actionId !== creationalActions.getCreationalActions[0].id
          ),
          creationalActions.getCreationalActions[0].id
        ];
      });
    }
  }, [creationalActions]);

  function closeNewCommentWindow() {
    setIsWriteNewCommentVisible(false);
    setNewCommentText("");
    setReferencedFiles([]);
    setReferencedActionIds([]);
    setRepliedToComment(null);
    setEditingComment(null);
  }

  const closeConversationDrawer = () => {
    setIsConversationWindowOpen(false);
    props.setSelectedCommentIdFromMenu(null);
  };

  const redirectToComment = (commentId: string) => {
    props.setSelectedCommentIdFromMenu(commentId);
    window.location.href = "#" + COMMENT_ID_LABEL + commentId;
  };

  useEffect(() => {
    document
      .querySelectorAll(".comment_conversation_wrapper a")
      .forEach((element: any) => {
        element.setAttribute("target", "_blank");
      });
  }, [currentCommentList]);

  //function to handle scroll to bottom of page
  function handleScroll() {
    window.location.replace("#comment_editor_bottom");
  }
  return (
    <>
      <Button
        variant="link"
        onClick={() => setIsConversationWindowOpen(true)}
        id="view_conversation_button"
      >
        <Badge
          className="comments_icon"
          badgeContent={
            activityData.conversations?.length > 0
              ? activityData.conversations?.length
              : "0"
          }
          color={activityData.conversations?.length > 0 ? "info" : "primary"}
        >
          <ChatBubbleOutline />
        </Badge>
        {!props.hideText && "Conversations"}
      </Button>

      <Drawer
        open={isConversationWindowOpen}
        onClose={() => closeConversationDrawer()}
        anchor={"right"}
        className="conversation_drawer"
        transitionDuration={200}
        hideBackdrop
        id="conversation_drawer_element"
        PaperProps={{ id: "conversation_drawer_element_paper" }}
      >
        <div className="d-inline-flex">
          <h3>Conversations</h3>
          <UserManualLink
            url="/usermanual/conversations/"
            testId="um-conversations"
          />
        </div>
        <Row className="clear_float">
          <Col xs={10}>
            <FormControl fullWidth>
              <InputLabel id="conversation_label_select">
                Select a conversation
              </InputLabel>
              <Select
                labelId="conversation_label_select"
                id="select_conversation_id"
                value={conversationIndex}
                label="Select a conversation"
                onChange={handleConversationChange}
              >
                {activityData.conversations &&
                  activityData.conversations.map((conversation) => {
                    return (
                      conversation && (
                        <MenuItem key={conversation.id} value={conversation.id}>
                          {conversation.name}
                        </MenuItem>
                      )
                    );
                  })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={1}>
            <MuiButton
              variant="outlined"
              onClick={() => setIsAddNewConversationVisible(true)}
              id="create_conversation_button"
            >
              +
            </MuiButton>
          </Col>
          <Col xs={1}>
            <MuiButton
              variant="text"
              onClick={() => closeConversationDrawer()}
              id="close_comment_drawer"
              className="float-end small_mui_button"
              size="small"
            >
              X
            </MuiButton>
          </Col>
        </Row>
        {isAddNewConversationVisible && (
          <Row className="row_for_adding_data">
            <Col xs={8}>
              <Form.Group className="mb-3" controlId="formConversationName">
                <Form.Label>
                  Conversation Name: <span className="red_form">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter Conversation Name"
                  value={newConversationName}
                  onChange={(e) => setNewConversationName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Button
                variant="secondary"
                onClick={() => setIsAddNewConversationVisible(false)}
              >
                Cancel
              </Button>{" "}
              <Button
                variant="primary"
                disabled={newConversationName === ""}
                onClick={saveNewConversation}
                id="create_new_conversation"
              >
                Create New
              </Button>
            </Col>
          </Row>
        )}
        {isLoadingMutation && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>Loading...</p>
          </div>
        )}
        {/**start of for each Comment loop**/}
        {!isLoadingMutation && conversationIndex !== "" && (
          <>
            {currentSelectedActionId && (
              <EditAction
                refetchActions={() => {}}
                actionId={currentSelectedActionId}
                openDirectly={true}
                onClose={() => {
                  setCurrentSelectedActionId(undefined);
                }}
              />
            )}
            {currentCommentList.map(
              (comment) =>
                comment?.id !== editingComment?.id && (
                  <div
                    key={comment?.id}
                    className={
                      String(props.selectedCommentIdFromMenu) === comment!.id
                        ? "comment_conversation_wrapper comment_selected_wrapper"
                        : "comment_conversation_wrapper"
                    }
                    id={COMMENT_ID_LABEL + comment?.id}
                  >
                    {comment &&
                      comment.referencedFiles &&
                      comment.referencedFiles.map((file: RepoObject) => (
                        <div key={comment?.id + file!.versionId}>
                          <MuiButton
                            size="small"
                            className="small_mui_button comment_conversation_reference_file_name"
                            onClick={() =>
                              handlePreview(
                                file?.versionId!,
                                file?.name!,
                                file?.repo?.id!,
                                comment?.id
                              )
                            }
                          >
                            {file.name} ({file.revision})
                          </MuiButton>
                          <br />
                        </div>
                      ))}
                    {comment &&
                      comment.referencedActions &&
                      comment.referencedActions.map((action: Action) => (
                        <div key={comment?.id + action.id}>
                          <MuiButton
                            size="small"
                            className="small_mui_button comment_conversation_reference_action_name"
                            onClick={() => {
                              setCurrentSelectedActionId(Number(action.id));
                            }}
                          >
                            <ReceiptIcon /> Action ID {action.id}
                          </MuiButton>
                          <br />
                        </div>
                      ))}
                    {comment?.replyComment && (
                      <MuiButton
                        variant="text"
                        className="comment_reply_link full_100_width"
                        onClick={() => {
                          redirectToComment(comment!.replyComment!.id);
                        }}
                      >
                        <blockquote className="blockquote">
                          Replied to: &quot;
                          {shortenString(
                            removeTags(comment?.replyComment.text)
                          )}
                          &quot;
                        </blockquote>
                      </MuiButton>
                    )}
                    <div
                      className="comment_conversation_inner clear_float"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(String(comment?.text))
                      }}
                    ></div>
                    <div className="comment_conversation_actions">
                      <span className="comment_user_details">
                        {comment?.createdBy} -{" "}
                        {formatStringToDateTime(comment?.created)}
                      </span>
                      {decodedToken["email"] === comment?.createdBy && (
                        <MuiButton
                          variant="text"
                          onClick={() => {
                            if (
                              comment &&
                              window.confirm(
                                "Are you sure you want to delete this comment?"
                              )
                            ) {
                              deleteComment(comment);
                            }
                          }}
                          id="delete_comment_button"
                          className="small_mui_button float-end"
                          size="small"
                        >
                          <Delete />
                        </MuiButton>
                      )}
                      {decodedToken["email"] === comment?.createdBy && (
                        <MuiButton
                          variant="text"
                          onClick={() => {
                            comment && clickEditComment(comment);
                          }}
                          id="edit_comment_button"
                          className="small_mui_button float-end"
                          size="small"
                        >
                          <Edit />
                        </MuiButton>
                      )}

                      <MuiButton
                        variant="text"
                        onClick={() => {
                          handleScroll();
                          setRepliedToComment(comment);
                          setIsWriteNewCommentVisible(true);
                        }}
                        className="small_mui_button float-end reply_to_comment_button"
                        size="small"
                      >
                        <Reply />
                      </MuiButton>
                    </div>
                  </div>
                )
            )}
          </>
        )}
        {(isLoadingCreateCommentMutation ||
          isLoadingEditCommentMutation ||
          loadingDeleteMutation ||
          isActivityLoading) && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>Loading...</p>
          </div>
        )}

        {isWriteNewCommentVisible ? (
          <>
            <br />
            {repliedToComment && (
              <Row>
                <Col>
                  <MuiButton
                    className="comment_reply_link full_100_width"
                    onClick={() => {
                      redirectToComment(repliedToComment!.id);
                    }}
                  >
                    <blockquote className="blockquote">
                      Reply: &quot;
                      {shortenString(removeTags(repliedToComment?.text))}&quot;
                      <MuiButton
                        variant="text"
                        onClick={() => setRepliedToComment(null)}
                        id="delete_replied_to_comment"
                        className="float-end small_mui_button"
                        size="small"
                      >
                        X
                      </MuiButton>
                    </blockquote>
                  </MuiButton>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                {referencedFiles.map((file: RepoObject) => (
                  <div
                    className="drop_comment_file"
                    key={`reference_${file.versionId}`}
                  >
                    <FileIcon
                      fileName={file.absolutePath}
                      isValid={file.isValid}
                      fontSize={"small"}
                    />{" "}
                    {file.absolutePath}
                    <MuiButton
                      variant="text"
                      onClick={() =>
                        setReferencedFiles((prevState) => [
                          ...prevState.filter(
                            (stateFile: RepoObject) =>
                              stateFile.versionId !== file.versionId
                          )
                        ])
                      }
                      className="float-end small_mui_button"
                      size="small"
                    >
                      X
                    </MuiButton>
                  </div>
                ))}
                {referencedActionIds.map((action: any) => (
                  <div
                    className="drop_comment_action"
                    key={`action_${action.id}`}
                  >
                    <ReceiptIcon /> Action ID {action.id}
                    <MuiButton
                      variant="text"
                      onClick={() =>
                        setReferencedActionIds((prevState: any) => [
                          ...prevState.filter((id: any) => id.id !== action.id)
                        ])
                      }
                      className="float-end small_mui_button"
                      size="small"
                    >
                      X
                    </MuiButton>
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div
                  className="drop_comment_file actual_drop_target"
                  onDragOver={allowDropThis}
                  onDrop={dropFileToReference}
                >
                  &quot;Drag a file here to reference it&quot;
                </div>
              </Col>
              <Col xs={6}>
                <div
                  className="drop_comment_action actual_drop_target"
                  onDragOver={allowDropThis}
                  onDrop={dropFileToReferenceAction}
                >
                  &quot;Drag a file here to reference actions&quot;
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {/*TODO: Move this to a separate component*/}
                <div data-text-editor="name">
                  <ReactQuill
                    className="comment_editor"
                    modules={quillModules}
                    formats={formats}
                    value={newCommentText}
                    onChange={handleCommentWriting}
                    placeholder="Enter your comment"
                    id="comment_editor_bottom"
                    bounds={`[data-text-editor="name"]`}
                  />
                  {isUserReferenceVisible && (
                    <Autocomplete
                      id="autocomplete-user"
                      onChange={(event, value) =>
                        handleAutocompleteChange(value)
                      }
                      options={activityData.allAccessUser.map(
                        (user) => `${user!.name} - ${user!.email}`
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a user"
                          variant="standard"
                          id="autocomplete-user-label"
                        />
                      )}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <div
              className={
                newCommentText.length >= MAX_COMMENT_SIZE
                  ? "comment_user_details red_form"
                  : "comment_user_details"
              }
            >
              {newCommentText.length}/{MAX_COMMENT_SIZE} characters
            </div>

            <br />
            <Row>
              <Col xs={8}>
                <Button variant="secondary" onClick={closeNewCommentWindow}>
                  Cancel
                </Button>
              </Col>
              <Col xs={4}>
                <Button
                  id="save_comment_button"
                  variant="primary"
                  disabled={!newCommentText || newCommentText === "<p><br></p>"} //default editor setting
                  onClick={() => {
                    if (editingComment) {
                      return editComment();
                    } else {
                      return saveNewComment();
                    }
                  }}
                >
                  Save Comment
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <br />
            {conversationIndex && (
              <Button
                variant="primary"
                onClick={() => setIsWriteNewCommentVisible(true)}
                id="write_new_comment"
                disabled={isAddNewConversationVisible}
              >
                Write Comment
              </Button>
            )}
          </>
        )}
      </Drawer>
      {(errorMutation ||
        errorCreateCommentMutation ||
        errorEditCommentMutation ||
        errorDeleteCommentMutation) && (
        <Error
          error={
            errorMutation ||
            errorCreateCommentMutation ||
            errorEditCommentMutation ||
            errorDeleteCommentMutation
          }
        />
      )}

      <div id="myModal" className="conversation-modal">
        <span className="close">&times;</span>
        <img className="conversation-modal-content" alt="" id="img01" />
        <div id="caption"></div>
      </div>
    </>
  );
}
