import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { InteractiveFileTransfer } from "./interactiveFileTransfer";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { useImportFilesMutation } from "../../backend/hooks/interactive/mutationImportFiles";
import { useConnectEnvironmentMutation } from "../../backend/hooks/interactive/mutationConnectEnvironment";
import {
  InstanceType,
  Software,
  Environment,
  EnvironmentState,
  EnvironmentStatus
} from "../../backend/interactiveTypes";
import { useListInstanceTypesQuery } from "../../backend/hooks/interactive/queryListInstanceTypes";
import { useUpdateEnvironmentStatusMutation } from "../../backend/hooks/interactive/mutationUpdateEnvironmentStatus";
import { useUpdateEnvironmentSettingsMutation } from "../../backend/hooks/interactive/mutationUpdateEnvironmentSettings";
import CableIcon from "@mui/icons-material/Cable";
import {
  formatStringToDateTime,
  INTERACTIVE_ALERT_WARNING
} from "../../helpers/stringHelper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Success from "../abstractComponents/success";
import { useAuth } from "../authContextProvider";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { useHistory } from "react-router-dom";

interface ParamsAction {
  environmentId: string;
}

export function Interactive() {
  const { token } = useAuth();
  const history = useHistory();

  const { activityId, activityData, isActivityLoading, errorActivity } =
    useContext(PmxActivityDetailsContext);
  const { isBayerUser } = useUserHelper();
  const environmentId = (useParams() as ParamsAction).environmentId;
  const {
    environments,
    isLoadingEnvironments,
    errorListEnvironments,
    refetchEnvironments
  } = useQueryEnvironmentsQuery(activityId, true, environmentId);

  const { importFiles, isLoadingImportFiles, errorImportFiles } =
    useImportFilesMutation();

  const [currentEnvironment, setCurrentEnvironment] = useState<any | null>(
    null
  );

  useEffect(() => {
    if (environments?.listEnvironments) {
      setCurrentEnvironment(environments?.listEnvironments[0]);
    }
  }, [environments]);

  const {
    connectEnvironment,
    connectionUrl,
    isLoadingConnectionUrl,
    errorConnectionUrl
  } = useConnectEnvironmentMutation();

  const {
    updateEnvironmentStatus,
    isLoadingUpdateEnvironmentStatus,
    errorLoadingUpdateEnvironmentStatus,
    updateEnvironmentStatusData
  } = useUpdateEnvironmentStatusMutation();

  const {
    updateEnvironmentSettings,
    isLoadingEnvironmentSettings,
    errorEnvironmentSettings,
    updateEnvironmentSettingsData
  } = useUpdateEnvironmentSettingsMutation();

  useEffect(() => {
    if (connectionUrl && connectionUrl.connectEnvironment) {
      window.open(
        connectionUrl.connectEnvironment +
          "?id_token=" +
          encodeURIComponent(token!) +
          "&environment_id=" +
          environmentId,
        "_blank"
      );
    }
  }, [connectionUrl]);

  const syncFiles = () => {
    if (
      window.confirm("This will push all files to the interactive environemnt")
    ) {
      importFiles({
        variables: {
          environmentId: environmentId,
          versionIds: []
        }
      });
    }
  };

  const {
    environmentTypes,
    isLoadingEnvironmentTypes,
    errorListEnvironmentTypes
  } = useListInstanceTypesQuery();

  const connectToEnvironment = () => {
    connectEnvironment({ variables: { environmentId: environmentId } });
  };

  const setNewSize = (sizeId: string) => {
    updateEnvironmentSettings({
      variables: {
        environmentId: environmentId,
        instanceTypeId: sizeId
      }
    }).then(() => {
      refetchEnvironments();
    });
  };

  const setNewStatus = (statusId: string) => {
    if (
      statusId !== "delete" ||
      (statusId === "delete" && window.confirm(INTERACTIVE_ALERT_WARNING))
    ) {
      updateEnvironmentStatus({
        variables: {
          environmentId: environmentId,
          environmentStatus: statusId
        }
      }).then(() => {
        refetchEnvironments();
      });
    }
  };

  const displayState = () => {
    let displayState = currentEnvironment?.state;

    if (
      currentEnvironment?.state === EnvironmentState.Running &&
      (currentEnvironment?.instanceStatus !== EnvironmentStatus.Ok ||
        currentEnvironment?.systemStatus !== EnvironmentStatus.Ok)
    ) {
      displayState = EnvironmentState.Pending;
    }

    if (
      currentEnvironment?.instanceStatus ===
        EnvironmentStatus.InsufficientData ||
      currentEnvironment?.instanceStatus === EnvironmentStatus.Impaired ||
      currentEnvironment?.systemStatus === EnvironmentStatus.InsufficientData ||
      currentEnvironment?.systemStatus === EnvironmentStatus.Impaired
    ) {
      displayState = "error";
    }

    return displayState;
  };

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/interactive`}>
            Interactive Environments
          </Link>
          ,<Typography>{currentEnvironment?.environmentName}</Typography>
        </Breadcrumbs>
        <Button
          onClick={() => refetchEnvironments()}
          id="refresh_list_of_env"
          variant="outline-secondary"
          className="float-end"
          size="sm"
        >
          <RefreshIcon /> Refresh Status
        </Button>
        <br />
        {currentEnvironment?.actionId && (
          <h4>
            <br />
            Historical Environment from action ID {currentEnvironment?.actionId}
          </h4>
        )}
        <hr />
        {isBayerUser && (
          <ErrorBoundary>
            <Container fluid>
              {isLoadingEnvironments ||
              isActivityLoading ||
              isLoadingImportFiles ||
              isLoadingConnectionUrl ||
              isLoadingEnvironmentTypes ||
              isLoadingUpdateEnvironmentStatus ||
              isLoadingEnvironmentSettings ? (
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading Details...</p>
                </div>
              ) : (
                <>
                  {currentEnvironment && (
                    <Row>
                      <Col xs={2} className="details_cell text-break">
                        {currentEnvironment?.image.imageName}{" "}
                        {currentEnvironment?.image.software &&
                          currentEnvironment?.image.software.length > 0 && (
                            <OverlayTrigger
                              placement="right"
                              overlay={(props) => (
                                <Tooltip {...props} id="info-image-tooltip">
                                  {currentEnvironment?.image.software.map(
                                    (software: Software) => (
                                      <>
                                        {software.name} - {software.version}{" "}
                                        <br />
                                      </>
                                    )
                                  )}
                                </Tooltip>
                              )}
                            >
                              <Badge
                                className="ineractive_info_badge"
                                bg="info"
                              >
                                Info
                              </Badge>
                            </OverlayTrigger>
                          )}
                        <br />
                        <span className="comment_user_details">
                          {currentEnvironment?.createdBy}
                          {" - "}
                          {formatStringToDateTime(
                            currentEnvironment?.createdAt
                          )}
                        </span>
                      </Col>
                      <Col xs={6}>
                        <div className="d-flex gap-2">
                          <FormControl
                            fullWidth
                            id="interactive-modal-form-instance-type"
                            size="small"
                          >
                            <InputLabel id="interactive-modal-input-instance-type">
                              Instance Type
                            </InputLabel>
                            <Select
                              labelId="interactive-modal-input-instance-type"
                              id="interactive-modal-select-instance-type"
                              data-testid="instance-type"
                              value={
                                currentEnvironment?.instanceType?.instanceTypeId
                              }
                              label="Select a Size"
                              onChange={(event) => {
                                setNewSize(event.target.value);
                              }}
                              disabled={
                                currentEnvironment?.state !==
                                EnvironmentState.Stopped
                              }
                              title="Server must be stopped for this to change"
                            >
                              {environmentTypes?.listInstanceTypes.map(
                                (envType: InstanceType) => (
                                  <MenuItem
                                    key={envType.instanceTypeId}
                                    value={envType.instanceTypeId}
                                    id={`instance-type-${envType.instanceTypeId}`}
                                  >
                                    <Row
                                      id={`row-instance-type-${envType.instanceTypeId}`}
                                    >
                                      <Typography
                                        sx={{
                                          width: "130px",
                                          fontWeight: "bold"
                                        }}
                                        variant="button"
                                        display="block"
                                      >
                                        {envType.instanceTypeId}
                                      </Typography>
                                      <Typography sx={{ width: "50px" }}>
                                        {envType.cpu}xCPU
                                      </Typography>
                                      <Typography sx={{ width: "50px" }}>
                                        {envType.memory}GB RAM
                                      </Typography>
                                    </Row>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <FormControl
                            fullWidth
                            id="change_status_interactive_form"
                            size="small"
                            title={`instanceStatus:${currentEnvironment.instanceStatus}, systemStatus:${currentEnvironment.systemStatus}`}
                          >
                            <InputLabel id="change_status_interactive_label">
                              State
                            </InputLabel>
                            <Select
                              labelId="change_status_interactive"
                              id="change_status_interactive"
                              data-testid="change_status_interactive"
                              defaultValue=""
                              value={displayState()}
                              label="State"
                              native
                              disabled={
                                currentEnvironment.state ===
                                  EnvironmentState.Stopping ||
                                currentEnvironment.state ===
                                  EnvironmentState.ShuttingDown ||
                                currentEnvironment.state ===
                                  EnvironmentState.Pending ||
                                (currentEnvironment.state ===
                                  EnvironmentState.Running &&
                                  (currentEnvironment.instanceStatus !==
                                    EnvironmentStatus.Ok ||
                                    currentEnvironment.systemStatus !==
                                      EnvironmentStatus.Ok))
                              }
                              onChange={(event) => {
                                setNewStatus(event.target.value);
                              }}
                            >
                              <optgroup label="Current Status" disabled>
                                <option selected value="">
                                  {displayState()}
                                </option>
                              </optgroup>
                              <optgroup label="New Status">
                                <option
                                  value={"start"}
                                  disabled={
                                    currentEnvironment?.state ===
                                    EnvironmentState.Running
                                  }
                                  key={"start"}
                                >
                                  Start
                                </option>
                                <option
                                  value={"stop"}
                                  disabled={
                                    currentEnvironment?.state ===
                                    EnvironmentState.Stopped
                                  }
                                  key={"stop"}
                                >
                                  Stop
                                </option>
                                <option value={"delete"} key={"delete"}>
                                  Delete
                                </option>
                              </optgroup>
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex gap-2 justify-content-end">
                          <Button
                            id="interactive_file_sync"
                            size="sm"
                            onClick={syncFiles}
                            variant="outline-primary"
                            disabled={
                              currentEnvironment?.state !==
                              EnvironmentState.Running
                            }
                          >
                            <CloudSyncIcon /> Copy All Files
                          </Button>{" "}
                          <Button
                            id="run-execution-job-button"
                            size="sm"
                            onClick={() => {
                              history.push(
                                `/activity/${activityData.id}/interactive/${environmentId}/IEPlugin`
                              );
                            }}
                            hidden={
                              currentEnvironment?.state !==
                                EnvironmentState.Running ||
                              currentEnvironment.instanceStatus !==
                                EnvironmentStatus.Ok ||
                              currentEnvironment.systemStatus !==
                                EnvironmentStatus.Ok
                            }
                          >
                            <ScheduleSendOutlinedIcon />
                            Run Job
                          </Button>
                          <Button
                            id="connect_to_env"
                            size="sm"
                            onClick={connectToEnvironment}
                            disabled={
                              currentEnvironment?.state !==
                                EnvironmentState.Running ||
                              currentEnvironment.instanceStatus !==
                                EnvironmentStatus.Ok ||
                              currentEnvironment.systemStatus !==
                                EnvironmentStatus.Ok
                            }
                          >
                            <CableIcon /> Connect
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <hr />
              <br />
              <ErrorBoundary>
                {currentEnvironment?.state === EnvironmentState.Running &&
                  currentEnvironment?.instanceStatus === EnvironmentStatus.Ok &&
                  currentEnvironment?.systemStatus === EnvironmentStatus.Ok && (
                    <InteractiveFileTransfer environmentId={environmentId} />
                  )}
              </ErrorBoundary>
            </Container>
          </ErrorBoundary>
        )}
      </div>

      {(errorListEnvironments ||
        errorActivity ||
        errorImportFiles ||
        errorConnectionUrl ||
        errorListEnvironmentTypes ||
        errorLoadingUpdateEnvironmentStatus ||
        errorEnvironmentSettings) && (
        <Error
          error={
            errorListEnvironments ||
            errorActivity ||
            errorImportFiles ||
            errorConnectionUrl ||
            errorListEnvironmentTypes ||
            errorLoadingUpdateEnvironmentStatus ||
            errorEnvironmentSettings
          }
        />
      )}

      {(updateEnvironmentStatusData || updateEnvironmentSettingsData) && (
        <Success message="The update will take up to one minute" />
      )}
    </>
  );
}
