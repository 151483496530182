import { gql, useMutation } from "@apollo/client";

export const ADD_ACTIVITY_MUTATION = gql`
  mutation createPmxActivity(
    $trialNumber: String!
    $bayNumber: String
    $projectId: String
    $trialShortDescription: String
    $isStandard: Boolean
    $readOnlyRepositories: [Int]
    $source: ActivitySourceInput
  ) {
    createPmxActivity(
      input: {
        trialNumber: $trialNumber
        bayNumber: $bayNumber
        trialShortDescription: $trialShortDescription
        projectId: $projectId
        isStandard: $isStandard
        readOnlyRepositories: $readOnlyRepositories
        source: $source
      }
    ) {
      id
    }
  }
`;

export function useAddActivityMutation() {
  const [
    addNewActivity,
    { loading: isLoadingMutation, error: errorMutation, data: dataMutation }
  ] = useMutation(ADD_ACTIVITY_MUTATION);

  return {
    addNewActivity,
    isLoadingMutation,
    errorMutation,
    dataMutation
  };
}
