import { Card, CardContent, Typography } from "@mui/material";
import {
  Addon,
  Image,
  Package,
  ValidatedPackage
} from "../../backend/executionTypes";
import { Accordion, Col, Row } from "react-bootstrap";
import { convertLocalTimeZone } from "./executionHelpers";

interface ExecutionImageDetailsProps {
  images: Image[];
}
export function ExecutionImageDetails(props: ExecutionImageDetailsProps) {
  const { images } = props;

  return (
    <>
      {images.map((image: Image) => (
        <div className="mt-2 mb-3" key={image?.id}>
          <Card>
            <CardContent className="exeuction-heading">
              <Typography variant="h5" component="div">
                {image?.name}
              </Typography>
            </CardContent>
            <CardContent>
              <Row className="mb-3">
                <Col>
                  <span className="execution-subtitle">Image ID</span>
                  <br />
                  {image?.id}
                </Col>
                <Col>
                  <span className="execution-subtitle">Name</span>
                  <br />
                  <div id="image-name">{image?.name}</div>
                </Col>
                <Col>
                  <span className="execution-subtitle">Updated On</span>
                  <br />
                  <div id="image-updated-on">
                    {convertLocalTimeZone(image?.updatedOn as string)}
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <div>
                  <span className="execution-subtitle">Description</span>
                  <br />
                  {image?.description}
                </div>
              </Row>
              <Row className="mb-3">
                <div>
                  <span className="execution-subtitle">Digest</span>
                  <br />
                  {image?.digest}
                </div>
              </Row>
              <Row className="mb-3">
                <Col>
                  <span className="execution-subtitle">Environment Type</span>
                  <br />
                  <div id="image-environment-type">
                    {image?.environmentType}
                  </div>
                </Col>
                <Col>
                  <span className="execution-subtitle">
                    Environment Type Version
                  </span>
                  <br />
                  <div id="image-environment-version">
                    {image?.environmentTypeVersion}
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <span className="execution-subtitle">Validation Version</span>
                  <br />
                  {image?.validationTypeVersion}
                </Col>
                <Col>
                  <span className="execution-subtitle">Validation Type</span>
                  <br />
                  <div id="image-validation-type">{image?.validationType}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Accordion>
                    {image?.packages && (
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Packages</Accordion.Header>
                        <Accordion.Body>
                          {image?.packages.map((pkg: Package) => (
                            <div key={`${pkg.name}@${pkg.version}`}>
                              {pkg.name} - {pkg.version}
                              <br />
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {image?.validatedPackages && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Validated Packages</Accordion.Header>
                        <Accordion.Body>
                          {image?.validatedPackages.map(
                            (pkg: ValidatedPackage) => (
                              <div key={`${pkg.name}@${pkg.version}`}>
                                {pkg.name} - {pkg.version}
                                <br />
                              </div>
                            )
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {image?.addons && (
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Add Ons</Accordion.Header>
                        <Accordion.Body>
                          {image?.addons.map((addon: Addon) => (
                            <div key={addon.name}>
                              {addon.name}
                              <br />
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </div>
      ))}
    </>
  );
}
