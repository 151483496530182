import { gql, useLazyQuery } from "@apollo/client";

export const COMPARE_REPO_OBJECTS = gql`
  query compareRepoObjects($environmentId: ID!, $repoObject: RepoObjectDiff!) {
    compareRepoObjects(
      input: { environmentId: $environmentId, repoObject: $repoObject }
    ) {
      differences
    }
  }
`;

export function useCompareRepoObjectQuery() {
  const [
    getFileComparison,
    {
      data: interactiveFileComparison,
      loading: isLoadingFileComparison,
      error: errorFileComparison
    }
  ] = useLazyQuery(COMPARE_REPO_OBJECTS, {
    context: { clientName: "interactive" },
    fetchPolicy: "no-cache"
  });

  return {
    getFileComparison,
    interactiveFileComparison:
      interactiveFileComparison?.compareRepoObjects.differences,
    isLoadingFileComparison,
    errorFileComparison
  };
}
