import { gql, useLazyQuery } from "@apollo/client";
export const GET_DOWNLOAD_LINKS = gql`
  query getDownloadLinks(
    $activityId: ID!
    $repoId: ID
    $absolutePaths: [String]!
    $versionIds: [ID]!
  ) {
    getDownloadLinks(
      input: {
        activityId: $activityId
        absolutePaths: $absolutePaths
        versionIds: $versionIds
        repoId: $repoId
      }
    ) {
      actionId
      actionStatus
      presignedUrls {
        url
        absolutePath
      }
    }
  }
`;

export function useDownloadLinksQuery() {
  const [
    getDownloadLinkForFiles,
    { data: downloadUrls, error: errorDownload }
  ] = useLazyQuery(GET_DOWNLOAD_LINKS);
  return {
    getDownloadLinkForFiles,
    downloadUrls,
    errorDownload
  };
}
