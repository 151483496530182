import { gql, useQuery } from "@apollo/client";
import { Sort } from "../../executionTypes";

export const GET_JOB_LOGS = gql`
  query getExecutionJobLogs(
    $jobId: ID!
    $limit: Int!
    $cursor: String
    $sort: Sort
    $node: Int
  ) {
    getJobLogs(
      input: {
        jobId: $jobId
        limit: $limit
        cursor: $cursor
        sort: $sort
        node: $node
      }
    ) {
      logs {
        message
        timestamp
      }
      nextCursor
    }
  }
`;

export function useGetJobLogs(
  jobId: string,
  limit: number = 20,
  cursor: string | null | undefined = null,
  sort: Sort = Sort.DESC,
  node: Number = 0,
  skip: boolean
) {
  const {
    data: getJobLogs,
    loading: isLoadingJobLogs,
    error: jobLogsError,
    fetchMore: fetchMoreJobLogs
  } = useQuery(GET_JOB_LOGS, {
    variables: { jobId, limit, cursor, sort, node },
    context: { clientName: "execution" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip
  });

  return {
    getJobLogs,
    isLoadingJobLogs,
    jobLogsError,
    fetchMoreJobLogs
  };
}
