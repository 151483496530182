import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MaterialButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGridPro, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useDeletePmxActivityMutation } from "../../backend/hooks/pmxActivity/mutationDeletePmxActivity";
import { PmxActivity } from "../../backend/types";
import { useAuth } from "../authContextProvider";
import AccessRightDetails from "./accessRightDetails";
import EditActivityModal from "./editActivityModal";

// Component that lists all the PMX activities provided by the parent
export function ListActivities(props: {
  activities: [PmxActivity] | undefined;
  refetchActivities: Function;
}) {
  const { isAdminUser } = useAuth();

  const handleClickFolder = (
    event: React.MouseEvent<HTMLButtonElement>,
    pmxActivity: PmxActivity
  ) => {
    setAnchorElFolder(event.currentTarget);
    setCurrentPmxActivity(pmxActivity);
  };

  const [currentPmxActivity, setCurrentPmxActivity] =
    useState<PmxActivity | null>(null);
  const [anchorElFolder, setAnchorElFolder] = useState<null | HTMLElement>(
    null
  );
  const [isAccessRightOpen, setIsAccessRightOpen] = useState(false);
  const [isEditActivityOpen, setIsEditActivityOpen] = useState(false);

  const { deletePmxActivityMutation } = useDeletePmxActivityMutation();

  function deletePmxActivity() {
    setAnchorElFolder(null);
    if (
      window.confirm(
        `Do you confirm that you want to delete ${currentPmxActivity?.trialNumber} activity?`
      )
    )
      deletePmxActivityMutation({
        variables: {
          id: Number(currentPmxActivity?.id)
        }
      }).then(() => {
        props.refetchActivities();
      });
  }

  const columns = [
    {
      field: "trialNumber",
      headerName: "Activity Name",
      flex: 6,
      renderCell: (params: any) => (
        <div key={params.row.trialNumber} style={{ width: "100%" }}>
          <div className="button_without_text_transform float-start">
            <a
              className="link_center_align"
              href={`/activity/${params.row.id}`}
            >
              {params.row.mainRepository.isStandard ? (
                <LocalActivityIcon
                  className="secondary_color is_standard_activity"
                  style={{ fontSize: 40 }}
                />
              ) : (
                <LocalActivityOutlinedIcon
                  className="secondary_color is_private_activity"
                  style={{ fontSize: 40 }}
                />
              )}
              {params.row.trialNumber}
            </a>
          </div>
          <MaterialButton
            variant="text"
            className="float-end inner_menu_button"
            onClick={(e) => handleClickFolder(e, params.row)}
            data-testid="inner_menu_button"
            data-activityname={params.row.trialNumber}
          >
            <MoreVertIcon
              className="dot_menu_browse_repository grey_color"
              data-testid="dot_menu_browse_repository"
            />
          </MaterialButton>
        </div>
      )
    },
    {
      field: "bayNumber",
      headerName: "BayNumber",
      flex: 2
    },
    {
      field: "projectId",
      headerName: "Project ID",
      flex: 2
    },
    {
      field: "repository",
      headerName: "Type",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.mainRepository.isStandard ? "Standard" : "Private"
    },
    {
      field: "trialShortDescription",
      headerName: "Trial Description",
      flex: 2,
      hide: true
    }
  ];

  const handleCloseFolder = () => {
    setAnchorElFolder(null);
  };

  const openAccessRightModal = () => {
    setIsAccessRightOpen(true);
    setAnchorElFolder(null);
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  };

  const openEditActivityModal = () => {
    setIsEditActivityOpen(true);
    setAnchorElFolder(null);
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  };

  return (
    <div className="list_repositories_container">
      {props.activities && props.activities.length > 0 ? (
        <DataGridPro
          rows={props.activities}
          columns={columns}
          pageSize={50}
          rowHeight={60}
          disableSelectionOnClick
          autoHeight
          getRowHeight={() => "auto"}
        />
      ) : (
        <div className="no_repositories_available_div">
          No Activities are accessible
        </div>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorElFolder}
        open={Boolean(anchorElFolder)}
        onClose={handleCloseFolder}
        PaperProps={{
          style: {
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            marginTop: "15px",
            marginLeft: "5px"
          }
        }}
        data-testid="simple-menu"
      >
        {isAdminUser && (
          <MenuItem id="delete_repository" onClick={deletePmxActivity}>
            Delete Activity
          </MenuItem>
        )}
        <MenuItem
          id="view_access_rights"
          data-testid="view_access_rights"
          onClick={openAccessRightModal}
        >
          Access Rights
        </MenuItem>
        <MenuItem
          id="edit_activity_menu_item"
          data-testid="edit_activity_menu_item"
          onClick={openEditActivityModal}
        >
          Edit PMX Activity
        </MenuItem>
      </Menu>
      {isAccessRightOpen && (
        <div>
          <AccessRightDetails
            repo={currentPmxActivity?.mainRepository}
            setIsAccessRightOpen={setIsAccessRightOpen}
          />
        </div>
      )}
      {isEditActivityOpen && (
        <EditActivityModal
          isEditWindowOpen={isEditActivityOpen}
          setIsEditWindowOpen={setIsEditActivityOpen}
          refetchRepoObjects={props.refetchActivities}
          activityData={currentPmxActivity}
        />
      )}
    </div>
  );
}
