import { gql, useLazyQuery } from "@apollo/client";
export const GET_DOWNLOAD_LINK = gql`
  query getExternalDownloadLink($id: ID!) {
    getExternalDownloadLink(input: { id: $id }) {
      url
      absolutePath
    }
  }
`;

export function useDownloadExternalLinkQuery() {
  const [
    getDownloadLink,
    { data: downloadUrls, loading: downloadIsLoading, error: errorDownload }
  ] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "no-cache",
    context: {
      clientName: process.env.REACT_APP_ENV !== "local" ? "appsyncapi" : ""
    },
    onError(err: any) {
      console.error(err);
    }
  });
  return {
    getDownloadLink,
    downloadUrls,
    errorDownload,
    downloadIsLoading
  };
}
