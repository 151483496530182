import { gql, useMutation } from "@apollo/client";

export const MUTATION_GET_MULTIPLART_UPLOAD_LINK = gql`
  mutation completeMultipartUpload(
    $activityId: ID!
    $absolutePath: String!
    $uploadId: String!
    $partNumbers: [Int]!
    $eTags: [String]!
  ) {
    completeMultipartUpload(
      input: {
        absolutePath: $absolutePath
        uploadId: $uploadId
        activityId: $activityId
        partNumbers: $partNumbers
        eTags: $eTags
      }
    ) {
      bucket
      absolutePath
      versionId
      actionId
      actionStatus
    }
  }
`;

export function useCompleteMultipartUpload() {
  const [
    completeMultipartUpload,
    {
      loading: completeMultipartUploadLoading,
      data: completeMultipartUploadData,
      error: completeMultipartUploadError
    }
  ] = useMutation(MUTATION_GET_MULTIPLART_UPLOAD_LINK, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    completeMultipartUpload,
    completeMultipartUploadLoading,
    completeMultipartUploadData,
    completeMultipartUploadError
  };
}
