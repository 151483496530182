import {
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  Form,
  Button
} from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useState } from "react";
import { useCreateExternalDownloadLink } from "../../backend/hooks/externalLink/mutationCreateExternalDownloadLink";
import { GetRepoObjectQueryData, RepoObject } from "../../backend/types";

// Create Download Link, that can be used by external Users
export default function CreateExternalDownloadLink(props: {
  currentSelectedPath: string;
  activityId: number;
  repoId: string | null;
  selectedRows: string[];
  onCloseExternalDownloadModal: Function;
  currentFileList: GetRepoObjectQueryData;
}) {
  const {
    createExternalDownloadLink,
    isCreateExternalDownloadLinkLoading,
    errorCreateExternalDownloadLink,
    createExternalDownloadLinkData
  } = useCreateExternalDownloadLink();

  const submitData = () => {
    createExternalDownloadLink({
      variables: {
        pmxActivityId: props.activityId,
        absolutePath: props.currentSelectedPath,
        repoId: Number(props.repoId),
        emailAddress,
        additionalMessage,
        versionIds: props.currentFileList.repoObjects
          .filter((file: RepoObject) => props.selectedRows.includes(file.name))
          .map((file: RepoObject) => file.versionId)
      }
    });
  };
  const [emailAddress, setEmailAddress] = useState("");
  const [additionalMessage, setAdditionalMessage] = useState("");

  const closeModal = () => {
    props.onCloseExternalDownloadModal();
    setEmailAddress("");
    setAdditionalMessage("");
  };

  return (
    <>
      <Modal
        show={true}
        onHide={closeModal}
        dialogClassName="modal-80w"
        aria-labelledby="uploadAdfModalTitle"
      >
        <Modal.Header closeButton={!isCreateExternalDownloadLinkLoading}>
          <Modal.Title id="uploadAdfModalTitle">
            Create External Link for Downloading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {isCreateExternalDownloadLinkLoading ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Generating Link...</p>
              </div>
            ) : createExternalDownloadLinkData && emailAddress ? (
              <>
                <Row>
                  <Col xs={5}>
                    <div>
                      <h3>The link for Download is:</h3>
                      <p>
                        <b id="createExternalDownloadLink">
                          {
                            createExternalDownloadLinkData?.createExternalDownloadLink
                          }
                        </b>
                      </p>
                      <p>
                        The external person just needs to access this link via
                        browser.
                      </p>
                      <p>
                        The link will be valid for only 10 days and file(s) can
                        only be downloaded once
                      </p>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    Generating Link for downloading files:
                    <ul className="file_list_ul">
                      {props.selectedRows.map((fileName) => (
                        <li key={`selected_row_${fileName}`}>{fileName}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={8}>
                    <Form.Label>Recipient Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      data-testid="emailAddressExternalLink"
                      placeholder="Enter an Email Address"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      id="emailAddressExternalLink"
                    />
                  </Col>{" "}
                </Row>
                <br />
                <Row>
                  <Col xs={8}>
                    <Form.Label>Additional Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      data-testid="additionalMessage"
                      placeholder="Enter additional message"
                      value={additionalMessage}
                      onChange={(e) => setAdditionalMessage(e.target.value)}
                      id="additionalMessage"
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col>
                    <Button
                      className="float-end"
                      variant="primary"
                      disabled={!emailAddress}
                      id="createLinkButton"
                      onClick={() => {
                        submitData();
                      }}
                    >
                      Send Link
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      {errorCreateExternalDownloadLink && (
        <Error error={errorCreateExternalDownloadLink} />
      )}
    </>
  );
}
