import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_MODSPACE_CONTENT = gql`
  query getModspacePaths(
    $username: String!
    $encryptedPassword: String!
    $modspaceFolder: String!
    $modspaceRevision: String
  ) {
    getModspacePaths(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        modspaceFolder: $modspaceFolder
        modspaceRevision: $modspaceRevision
      }
    ) {
      modspacePaths {
        name
        size
      }
    }
  }
`;

export function useGetModspaceContentQuery() {
  const [getModspacePaths, { error, loading, data }] = useLazyQuery(
    QUERY_GET_MODSPACE_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getModspacePaths,
    error,
    loading,
    data
  };
}
