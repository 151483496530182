import { gql, useMutation } from "@apollo/client";

export const MUTATION_RESTORE_FILE_PATH = gql`
  mutation restoreFilePath(
    $activityId: ID!
    $historicDatetime: String
    $paths: [String!]!
    $comment: String
  ) {
    restoreFilePath(
      input: {
        activityId: $activityId
        historicDatetime: $historicDatetime
        paths: $paths
        comment: $comment
      }
    ) {
      actionId
      versionIds
    }
  }
`;

export function useRestoreFilePathMutation() {
  const [
    restoreFilePathMutation,
    {
      loading: isLoadingRestoreMutation,
      error: errorRestoreMutation,
      data: dataMutation
    }
  ] = useMutation(MUTATION_RESTORE_FILE_PATH);

  return {
    restoreFilePathMutation,
    isLoadingRestoreMutation,
    errorRestoreMutation,
    dataMutation
  };
}
