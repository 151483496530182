import { gql, useLazyQuery } from "@apollo/client";
export const GET_FILE_DIFFERENCES = gql`
  query getFileDifferences(
    $sourceVersionId: ID!
    $sourceRepoId: ID!
    $targetVersionId: ID!
    $targetRepoId: ID!
  ) {
    getRepoObjectsDifference(
      input: {
        source: { repoId: $sourceRepoId, versionId: $sourceVersionId }
        target: { repoId: $targetRepoId, versionId: $targetVersionId }
      }
    ) {
      differences
    }
  }
`;

export function useGetFileDifferences() {
  const [
    getFileDifferences,
    { error, data: fileDifferences, loading: isLoading }
  ] = useLazyQuery(GET_FILE_DIFFERENCES);
  return {
    getFileDifferences,
    fileDifferences,
    isLoadingFileDifferences: isLoading,
    errorFileDifferences: error
  };
}
