import { gql, useLazyQuery } from "@apollo/client";
export const GET_TABLE_QUERY_DATA = gql`
  query getTableQueryData(
    $username: String!
    $encryptedPassword: String!
    $schema: String!
  ) {
    getTableQueryData(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        schema: $schema
      }
    ) {
      schemaName
      tables {
        name
        sasName
        sasLabel
        oracleName
        type
        audited
        blinded
        description
      }
    }
  }
`;

export function useQueryTableQueryData() {
  const [
    getTableQueryData,
    { data: tableData, loading: isTableDataLoading, error: errorTableData }
  ] = useLazyQuery(GET_TABLE_QUERY_DATA, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  return {
    getTableQueryData,
    tableData,
    isTableDataLoading,
    errorTableData
  };
}
