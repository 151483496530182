import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_AUDIT_TRAIL_EVENTS = gql`
  query getAuditTrailEvents(
    $page: Int
    $pageSize: Int
    $sort: [Sort]
    $filter: Filter
  ) {
    getAuditTrailEvents(
      input: { page: $page, pageSize: $pageSize, sort: $sort, filter: $filter }
    ) {
      auditTrail {
        __typename
        id
        created
        userId
        repository {
          id
          name
        }
        data
        actionType
      }
      totalRowCount
    }
  }
`;

export function useGetAuditTrailEventsQuery() {
  const [auditQuery, { data: auditTrailEvent, loading: isLoading, error }] =
    useLazyQuery(QUERY_GET_AUDIT_TRAIL_EVENTS, {
      fetchPolicy: "no-cache"
    });

  return { auditQuery, auditTrailEvent, isLoading, error };
}
