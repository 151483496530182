import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

export default function UserManualLink(props: {
  testId?: string;
  url: string;
  className?: string;
}) {
  return (
    <a
      data-testid={props.testId}
      className={props.className ? props.className : ""}
      href={`${process.env.REACT_APP_USER_MANUAL_URL}${props.url}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <HelpOutlineOutlinedIcon />
    </a>
  );
}
