import RefreshIcon from "@mui/icons-material/Refresh";
import { Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetJobLogs } from "../../backend/hooks/execution/queryGetJobLogs";
import Error from "../abstractComponents/error";
import { generateRandom } from "../../helpers/stringHelper";
import { Job, Sort } from "../../backend/executionTypes";
import { convertLocalTimeZone } from "./executionHelpers";

interface ExecutionLogProps {
  job: Job;
}

export function ExecutionLogs(props: ExecutionLogProps) {
  const { job } = props;
  const { executionId } = useParams<{ executionId: string }>();
  const [cursor, setCursor] = useState<string | null>(null);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [newLimit, setNewLimit] = useState<number>(20);
  const [node, setNode] = useState<number>(0);
  const limit = 20;

  const LOG_COLUMNS = [
    {
      field: "timestamp",
      headerName: "Date",
      flex: 1,
      renderCell: (params: any) => (
        <div>{convertLocalTimeZone(params.row.timestamp)}</div>
      )
    },
    {
      field: "message",
      headerName: "Log",
      flex: 5,
      renderCell: (params: any) => <div>{params.row.message}</div>
    }
  ];

  const { getJobLogs, isLoadingJobLogs, jobLogsError, fetchMoreJobLogs } =
    useGetJobLogs(
      executionId,
      limit,
      cursor,
      Sort.ASC,
      node,
      job.status.toString() !== "RUNNING" &&
        job.status.toString() !== "CANCELED" &&
        job.status.toString() !== "SUCCEEDED" &&
        job.status.toString() !== "FAILED"
    );

  useEffect(() => {
    setNextCursor(getJobLogs?.getJobLogs.nextCursor);
  }, [getJobLogs]);

  const loadMore = (reset: boolean = false) => {
    const updatedLimit = newLimit !== limit ? newLimit : limit;
    fetchMoreJobLogs({
      variables: {
        jobId: executionId,
        limit: reset ? limit : updatedLimit,
        cursor: reset ? null : nextCursor,
        node: node
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.getJobLogs.logs = reset
          ? [...fetchMoreResult.getJobLogs.logs]
          : [...prevResult.getJobLogs.logs, ...fetchMoreResult.getJobLogs.logs];
        return fetchMoreResult;
      }
    });
  };

  return (
    <div className="job-logs-container mt-2">
      <Card className="job-logs">
        <CardContent className="exeuction-heading d-flex flex-row justify-content-between">
          <Typography variant="h5" component="div">
            Job Logs
          </Typography>
          <div>
            {(job.status.toString() === "RUNNING" ||
              job.status.toString() === "CANCELED" ||
              job.status.toString() === "SUCCEEDED" ||
              job.status.toString() === "FAILED") && (
              <RefreshIcon
                role="button"
                onClick={() => {
                  loadMore(true);
                  setNewLimit(limit);
                  setCursor(null);
                }}
              />
            )}
          </div>
        </CardContent>
        <CardContent>
          <div className="logs-text">
            <DataGridPro
              rows={
                getJobLogs?.getJobLogs.logs ? getJobLogs?.getJobLogs.logs : []
              }
              rowCount={getJobLogs?.getJobLogs.totalCount}
              columns={LOG_COLUMNS}
              getRowId={(row) => `${row.timestamp}${generateRandom()}`}
              autoHeight
              loading={isLoadingJobLogs}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {getJobLogs?.getJobLogs.logs.length > 0
                      ? "Logs have not yet started streaming for this job."
                      : "There are no logs"}
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Local filter returns no result
                  </Stack>
                )
              }}
            />
            <div className="d-flex justify-content-end gap-4">
              {job.numOfNodes > 1 && (
                <TextField
                  label="Request Node"
                  variant="standard"
                  value={node}
                  disabled={isLoadingJobLogs}
                  onChange={(e) =>
                    Number(e.target.value) >= 0 &&
                    Number(e.target.value) < job.numOfNodes &&
                    setNode(Number(e.target.value))
                  }
                  type="number"
                />
              )}
              <TextField
                disabled={isLoadingJobLogs || !nextCursor}
                label="Request Limit"
                variant="standard"
                value={newLimit}
                onChange={(e) =>
                  Number(e.target.value) > 0 &&
                  setNewLimit(Number(e.target.value))
                }
                type="number"
              />
              <Button
                id="more_logs"
                variant="primary"
                onClick={() => loadMore()}
                disabled={isLoadingJobLogs || !nextCursor}
              >
                Show More
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      {jobLogsError && <Error error={jobLogsError} />}
    </div>
  );
}
