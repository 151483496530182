import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useUserHelper } from "../../hooks/userHelper";
import ImportFromModspace from "./importFromModspace";
import ImportFromSvn from "./importFromSvn";
import ImportFromSpectrum from "./importFromSpectrum";
import ImportFromSwan from "./importFromSwan";
import UploadExternalAction from "./uploadExternalAction";
import UploadFilesModal from "./uploadFilesModal";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GetRepoObjectQueryData } from "../../backend/types";
import CreateExternalUploadLink from "../externalLink/createExternalUploadLink";
import CreateHistoryLabel from "../history/createHistoryLabel";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import UserManualLink from "../abstractComponents/usermanual";

export function TableViewListFilesInRepoNavbar(props: {
  tree: any[];
  files: any[];
  currentSelectedPath: string;
  setIsCreateFolderWindowOpen: Function;
  isCreateFolderWindowOpen: boolean;
  repository: any;
  refetchRepoObjects: Function;
  activityId: Number;
  setForceRefresh: Function;
  selectedRows: any[];
  currentSubArray: any;
  downloadMultipleFiles: Function;
  setIsDownloadExternalLinkVisible: Function;
  currentFileList: GetRepoObjectQueryData;
}) {
  const [importAnchorEl, setImportAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openImport = Boolean(importAnchorEl);
  const handleImportClick = (event: React.MouseEvent<HTMLElement>) => {
    setImportAnchorEl(event.currentTarget);
  };

  const [exportAnchorEl, setExportAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openExport = Boolean(exportAnchorEl);
  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const [createAnchorEl, setCreateAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openCreate = Boolean(createAnchorEl);
  const handleCreateClick = (event: React.MouseEvent<HTMLElement>) => {
    setCreateAnchorEl(event.currentTarget);
  };

  const { isOtherUser } = useUserHelper();
  const { activityData } = React.useContext(PmxActivityDetailsContext);

  return (
    <>
      <Navbar
        id="tableViewListFilesInRepoNavbar"
        className="table_view_navbar"
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
      >
        <Navbar.Toggle aria-controls="responsive-table-view-navbar-nav" />
        <Navbar.Collapse id="responsive-table-view-navbar-nav">
          {!props.currentFileList.isReadOnlyRepository ? (
            <Nav>
              <NavDropdown
                title="Create"
                id="navbarCreateDropdown"
                aria-controls={openCreate ? "create-button-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openCreate ? "true" : undefined}
                onClick={handleCreateClick}
              >
                <Nav.Item
                  onClick={() => {
                    props.setIsCreateFolderWindowOpen(true);
                  }}
                  id="createNewFolderItem"
                  className="nav-link nav_item_button"
                >
                  <CreateNewFolderIcon className="table_view_svg" /> New Folder
                </Nav.Item>
                <CreateHistoryLabel activityId={props.activityId} />
              </NavDropdown>
              <NavDropdown
                title="Import"
                id="navbarImportDropdown"
                aria-controls={openImport ? "import-button-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openImport ? "true" : undefined}
                onClick={handleImportClick}
              >
                <UploadFilesModal
                  currentSelectedPath={props.currentSelectedPath}
                  currentSubArray={props.currentSubArray}
                  repository={props.repository}
                  refetchRepoObjects={props.refetchRepoObjects}
                  disabled={props.selectedRows.length !== 0}
                />
                <UploadExternalAction
                  refetchRepoObjects={props.refetchRepoObjects}
                  currentSelectedPath={props.currentSelectedPath}
                  disabled={props.selectedRows.length !== 0 || !props.files}
                  currentSubArray={props.currentSubArray}
                  repository={props.repository}
                />

                <CreateExternalUploadLink
                  currentSelectedPath={props.currentSelectedPath}
                  disabled={props.selectedRows.length !== 0}
                  activityId={props.activityId}
                />
                <NavDropdown.Divider />
                <ImportFromModspace
                  currentFolder={props.currentSelectedPath}
                  disabled={isOtherUser || props.selectedRows.length !== 0}
                  currentSubArray={props.currentSubArray}
                />
                <ImportFromSvn
                  currentFolder={props.currentSelectedPath}
                  disabled={isOtherUser || props.selectedRows.length !== 0}
                  currentSubArray={props.currentSubArray}
                />
                <ImportFromSpectrum
                  refetchRepoObjects={props.refetchRepoObjects}
                  currentFolder={props.currentSelectedPath}
                  disabled={isOtherUser || props.selectedRows.length !== 0}
                  currentSubArray={props.currentSubArray}
                  repository={props.repository}
                />
                <ImportFromSwan
                  refetchRepoObjects={props.refetchRepoObjects}
                  currentFolder={props.currentSelectedPath}
                  disabled={isOtherUser || props.selectedRows.length !== 0}
                  currentSubArray={props.currentSubArray}
                />
              </NavDropdown>
              <NavDropdown
                title="Export"
                id="navbarExportDropdown"
                aria-controls={openExport ? "export-button-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openExport ? "true" : undefined}
                onClick={handleExportClick}
                disabled={props.selectedRows.length === 0}
              >
                <NavDropdown.Item
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    props.downloadMultipleFiles();
                  }}
                  disabled={
                    props.selectedRows.length === 0 ||
                    props.selectedRows.filter((path: string) =>
                      path.endsWith("/")
                    ).length !== 0
                  }
                  id="downloadItem"
                >
                  <CloudDownloadIcon /> Download
                </NavDropdown.Item>
                <NavDropdown.Item
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    props.setIsDownloadExternalLinkVisible(true);
                  }}
                  disabled={
                    props.selectedRows.length === 0 ||
                    props.selectedRows.filter((path: string) =>
                      path.endsWith("/")
                    ).length !== 0 ||
                    !activityData?.mainRepository?.isStandard
                  }
                  id="generateExternalDownload"
                >
                  <SendAndArchiveIcon /> Generate External Download Link
                </NavDropdown.Item>
              </NavDropdown>
              <UserManualLink
                url="/usermanual/fileexplorer/"
                testId="um-fileexplorer"
              />
            </Nav>
          ) : (
            <Nav>
              <NavDropdown
                title="Export"
                id="navbarExportDropdown"
                aria-controls={openExport ? "export-button-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openExport ? "true" : undefined}
                onClick={handleExportClick}
                disabled={props.selectedRows.length === 0}
              >
                <NavDropdown.Item
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    props.downloadMultipleFiles();
                  }}
                  disabled={
                    props.selectedRows.length === 0 ||
                    props.selectedRows.filter((path: string) =>
                      path.endsWith("/")
                    ).length !== 0
                  }
                  id="downloadItem"
                >
                  <CloudDownloadIcon /> Download
                </NavDropdown.Item>
              </NavDropdown>
              <UserManualLink url="/usermanual/fileexplorer/" />
            </Nav>
          )}
        </Navbar.Collapse>
        <Nav.Item
          onClick={() => {
            props.setForceRefresh(true);
          }}
          title="Clear cache and refresh file list"
          id="refreshItem"
          className="refresh_item"
        >
          <RefreshIcon className="table_view_svg" /> Refresh File List
        </Nav.Item>
      </Navbar>
    </>
  );
}
