import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_DATA_FROM_SPECTRUM = gql`
  mutation importSpectrumData(
    $username: String!
    $encryptedPassword: String!
    $tables: [String!]!
    $schema: String!
    $format: String!
    $asof: String!
    $dataType: String!
    $qualityCheckStatus: QualityCheckStatus
    $description: String
    $pmxActivityId: Int!
    $absolutePath: String!
  ) {
    importSpectrumData(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        tables: $tables
        schema: $schema
        format: $format
        asof: $asof
        dataType: $dataType
        pmxActivityId: $pmxActivityId
        absolutePath: $absolutePath
        qualityCheckStatus: $qualityCheckStatus
        description: $description
      }
    )
  }
`;

export function useImportSpectrumDataMutation() {
  const [
    importSpectrumData,
    {
      data: importSpectrumDataReturned,
      loading: spectrumImportLoading,
      error: errorSpectrumImport
    }
  ] = useMutation(MUTATION_IMPORT_DATA_FROM_SPECTRUM, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    importSpectrumData,
    spectrumImportLoading,
    errorSpectrumImport,
    importSpectrumDataReturned
  };
}
