import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/authContextProvider";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";

// TODO: have providers in <App />?
//       so that when we write tests, we can just render <App />
ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
