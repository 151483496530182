import { Route, Switch } from "react-router-dom";
import { BrowsePmxActivityProvider } from "../contexts/browsePmxActivityProvider";
import { PmxActivityDetailsProvider } from "../contexts/pmxActivityDetailsProvider";
import { Comment } from "./abstractComponents/comment";
import ErrorBoundary from "./abstractComponents/errorBoundary";
import { Actions } from "./actions";
import { AuditTrail } from "./audit/auditTrail";
import { useAuth } from "./authContextProvider";
import { ExecutionInfo } from "./execution/executionInfo";
import { ExecutionNew } from "./execution/executionNew";
import { ExecutionEnvironments } from "./execution/executions";
import External from "./external";
import { Interactive } from "./interactive/interactiveEnvironment";
import { InteractiveList } from "./interactive/interactiveList";
import { ActionsTab } from "./pmxActivity/actionsTab";
import Activities from "./pmxActivity/activities";
import { FileExplorer } from "./pmxActivity/fileExplorer";
import Support from "./support";
import Token from "./token";
import { HistoryView } from "./history/historyView";
import { HistoryPick } from "./history/historyPick";
import { CompareFile } from "./compareFile/CompareFile";
import { InteractiveComparison } from "./interactive/interactiveComparison";
import { InteractiveCreateJob } from "./interactive/interactiveCreateJob";

// Component for React Router. This decides which components get rendered
export default function AuthenticatedApp(): JSX.Element {
  const { userData, token, isActivatedUser } = useAuth();

  return (
    <>
      {isActivatedUser ? (
        <Switch>
          <Route path="/" exact>
            <ErrorBoundary>
              <Activities />
            </ErrorBoundary>
          </Route>
          <Route path="/token">
            <ErrorBoundary>
              <Token token={token} userData={userData} />
            </ErrorBoundary>
          </Route>
          <Route path="/activities">
            <ErrorBoundary>
              <Activities />
            </ErrorBoundary>
          </Route>
          <Route path="/actions">
            <ErrorBoundary>
              <Actions />
            </ErrorBoundary>
          </Route>
          <Route path="/action/:actionId">
            <ErrorBoundary>
              <Actions />
            </ErrorBoundary>
          </Route>
          <Route path="/comment/:commentId">
            <ErrorBoundary>
              <Comment />
            </ErrorBoundary>
          </Route>
          <Route path="/audit">
            <ErrorBoundary>
              <AuditTrail />
            </ErrorBoundary>
          </Route>
          <Route path="/support">
            <ErrorBoundary>
              <Support />
            </ErrorBoundary>
          </Route>
          <Route path="/external">
            <ErrorBoundary>
              <External />
            </ErrorBoundary>
          </Route>
          <PmxActivityDetailsProvider>
            <BrowsePmxActivityProvider>
              <Route path="/activity/:activityId" exact>
                <ErrorBoundary>
                  <FileExplorer />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/compare" exact>
                <ErrorBoundary>
                  <CompareFile />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/interactive" exact>
                <ErrorBoundary>
                  <InteractiveList />
                </ErrorBoundary>
              </Route>
              <Route
                path="/activity/:activityId/interactive/:environmentId"
                exact
              >
                <ErrorBoundary>
                  <Interactive />
                </ErrorBoundary>
              </Route>
              <Route
                path="/activity/:activityId/interactive/:environmentId/compare"
                exact
              >
                <ErrorBoundary>
                  <InteractiveComparison />
                </ErrorBoundary>
              </Route>
              <Route
                path="/activity/:activityId/interactive/:environmentId/IEPlugin"
                exact
              >
                <ErrorBoundary>
                  <InteractiveCreateJob />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/actions" exact>
                <ErrorBoundary>
                  <ActionsTab />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/executions" exact>
                <ErrorBoundary>
                  <ExecutionEnvironments />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/executions/new" exact>
                <ErrorBoundary>
                  <ExecutionNew />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/execution/:executionId" exact>
                <ErrorBoundary>
                  <ExecutionInfo />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/history" exact>
                <ErrorBoundary>
                  <HistoryPick />
                </ErrorBoundary>
              </Route>
              <Route path="/activity/:activityId/history/fileExplorer" exact>
                <ErrorBoundary>
                  <HistoryView />
                </ErrorBoundary>
              </Route>
            </BrowsePmxActivityProvider>
          </PmxActivityDetailsProvider>
        </Switch>
      ) : (
        <Switch>
          <Route path="/">
            <External />
          </Route>
        </Switch>
      )}
    </>
  );
}
