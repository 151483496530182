import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { useEffect, useState } from "react";
import { GetRepoObjectQueryData } from "../../backend/types";
import { StyledTreeItem, renderTreeNodes } from "../../helpers/treeHelper";
import { extractTreeViewArrayFromURL } from "../../helpers/urlHelper";

export function TreeViewListFilesInRepo(props: {
  repo: GetRepoObjectQueryData;
  onNodeSelect: Function;
  currentSelectedPath: string;
  setCurrentSelectedRepoId: Function;
  currentSelectedRepoId: number | null;
}) {
  useEffect(() => {
    document
      .querySelectorAll(".MuiTreeItem-root")
      .forEach((e) => e.removeAttribute("tabindex"));
  }, [props]);

  const [expanded, setExpanded] = useState(["/"]);
  const [selected, setSelected] = useState(["/"]);

  const handleSelect = (event: any, value: any) => {
    setSelected(value);

    event.persist();
    const iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (!iconClicked) {
      let actualValue;
      if (value.includes("__-*-__")) {
        actualValue = value.split("__-*-__")[1];
      } else {
        actualValue = value;
      }

      setSelected(value);
      props.onNodeSelect(
        actualValue,
        event.target.getAttribute("data-versionid")
      );
      props.setCurrentSelectedRepoId(props.repo.repo.id);
    }
  };

  useEffect(() => {
    if (props.currentSelectedPath && props.currentSelectedPath !== "") {
      const actualValue =
        props.currentSelectedRepoId +
        "__-*-__" +
        String(props.currentSelectedPath);
      setExpanded((prevExpanded) => [
        ...prevExpanded,
        ...extractTreeViewArrayFromURL(actualValue)
      ]);
      setSelected([actualValue]);
    } else {
      setExpanded(["/"]);
      setSelected(["/"]);
    }
  }, [props.currentSelectedPath]);

  //only expand if icon was clicked
  const handleToggle = (event: any, nodeIds: any) => {
    event.persist();
    const iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  return (
    <>
      <SimpleTreeView
        slots={{
          expandIcon: AddBoxOutlinedIcon,
          collapseIcon: IndeterminateCheckBoxOutlinedIcon
        }}
        onSelectedItemsChange={handleSelect}
        onExpandedItemsChange={handleToggle}
        defaultExpandedItems={["/"]}
        expandedItems={expanded}
        selectedItems={selected}
        className={[
          `mt-2 tree-view`,
          `${props.repo.isReadOnlyRepository ? "opacity-60" : ""}`
        ].join(" ")}
      >
        <StyledTreeItem
          key={"/"}
          itemId={"/"}
          label={
            <>
              <CenterFocusStrongIcon className="grey_color" />{" "}
              {props.repo.repo.name}{" "}
            </>
          }
        >
          {renderTreeNodes(props.repo.repoObjects, props.repo.repo.id)}
        </StyledTreeItem>
      </SimpleTreeView>
    </>
  );
}
