import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_MODSPACE_REVISIONS = gql`
  query getModspaceRevisions(
    $username: String!
    $encryptedPassword: String!
    $modspaceFolder: String!
  ) {
    getModspaceRevisions(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        modspaceFolder: $modspaceFolder
      }
    ) {
      modspaceRevisions
    }
  }
`;

export function useGetModspaceRevisionsQuery() {
  const [getModspaceRevisions, { error, loading, data }] = useLazyQuery(
    QUERY_GET_MODSPACE_REVISIONS,
    { fetchPolicy: "no-cache" }
  );

  return {
    getModspaceRevisions,
    errorModspaceRevisions: error,
    loadingModspaceRevisions: loading,
    modspaceRevisions: data
  };
}
