import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_SWAN_CONTENT = gql`
  query getSwanContent(
    $swanUser: String!
    $encryptedPassword: String!
    $absolutePath: String!
  ) {
    getSwanContent(
      input: {
        swanUser: $swanUser
        encryptedPassword: $encryptedPassword
        absolutePath: $absolutePath
      }
    ) {
      name
      size
    }
  }
`;

export function useGetSwanContentQuery() {
  const [getSwanContent, { error, data, loading }] = useLazyQuery(
    QUERY_GET_SWAN_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getSwanContent,
    loading,
    data,
    error
  };
}
