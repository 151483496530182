import ChatBubble from "@mui/icons-material/ChatBubble";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Badge from "@mui/material/Badge";
import MaterialButton from "@mui/material/Button";
import { GridColumns } from "@mui/x-data-grid-pro";
import { createContext, useContext } from "react";
import { RepoObject } from "../backend/types";
import QcActionFlag from "../components/abstractComponents/qcActionFlag";
import FileIcon from "../components/abstractComponents/fileIcon";
import { formatFileSize } from "../helpers/stringHelper";
import {
  GridColDef,
  getGridStringOperators,
  getGridDateOperators,
  getGridNumericOperators,
  GridFilterItem,
  GridFilterInputValue
} from "@mui/x-data-grid-pro";

/****MENU CONTEXT****/
export function dragThis(e: any) {
  e.dataTransfer.setData("versionId", e.target.getAttribute("data-versionid"));
  e.dataTransfer.setData(
    "absolutePath",
    e.target.getAttribute("data-absolutepath")
  );
}

export const MenuContext = createContext<{
  handleClickFolder: (
    event: React.MouseEvent<HTMLButtonElement>,
    absolutePath: string
  ) => void;
  handleClickFile: (
    event: React.MouseEvent<HTMLButtonElement>,
    absolutePath: string
  ) => void;
  handleClickComment: (
    event: React.MouseEvent<HTMLButtonElement>,
    comments: any[]
  ) => void;
  onNodeSelect: Function;
}>({
  handleClickFolder: () => {},
  handleClickFile: () => {},
  handleClickComment: () => {},
  onNodeSelect: () => {}
});

export const IdCell = (props: { row: RepoObject }) => {
  const { handleClickFolder } = useContext(MenuContext);
  const { handleClickFile } = useContext(MenuContext);
  const { handleClickComment } = useContext(MenuContext);

  return (
    <div
      key={props.row.absolutePath + props.row.versionId}
      style={{ width: "100%" }}
    >
      {props.row.isDir ? (
        <MaterialButton
          className="float-end inner_menu_button"
          onClick={(e) => handleClickFolder(e, props.row.absolutePath)}
        >
          <MoreVertIcon className="dot_menu_browse_repository grey_color" />
        </MaterialButton>
      ) : (
        <>
          <MaterialButton
            className="float-end inner_menu_button"
            onClick={(e) => handleClickFile(e, props.row.absolutePath)}
          >
            <MoreVertIcon className="dot_menu_browse_repository grey_color" />
          </MaterialButton>{" "}
          {props.row.comments && props.row.comments.length > 0 && (
            <MaterialButton
              className="float-end inner_menu_button"
              onClick={(e) => handleClickComment(e, props.row.comments!)}
            >
              <Badge
                className="comments_icon"
                badgeContent={props.row.comments.length}
                color="info"
              >
                <ChatBubble className="table_view_comment_menu" />
              </Badge>
            </MaterialButton>
          )}
        </>
      )}
      <br style={{ clear: "both" }} />
    </div>
  );
};

export const NameCell = (props: {
  row: RepoObject;
  allowElementExplorer: Boolean;
  refetch?: Function;
  hideContextMenu?: Boolean;
  hideQcFlagMenu?: Boolean;
}) => {
  const { onNodeSelect } = useContext(MenuContext);

  const clickElement = (event: any) => {
    if (
      (!props.allowElementExplorer && props.row.isDir) ||
      props.allowElementExplorer
    ) {
      const target = event.target as HTMLElement;
      const parent = target.parentNode as HTMLElement;
      const hyperParent = parent.parentNode as HTMLElement;
      const ultraParent = hyperParent.parentNode as HTMLElement;

      if (
        !hyperParent!.classList.contains("qc_badge") &&
        !target!.classList.contains("qc_badge") &&
        !parent!.classList.contains("qc_badge") &&
        !ultraParent!.classList.contains("qc_badge")
      ) {
        onNodeSelect(
          props.row.absolutePath,
          props.row.versionId,
          props.row.revision
        );
      }
    }
  };

  return (
    <div
      key={props.row.absolutePath + props.row.versionId}
      style={{ width: "100%" }}
    >
      <div
        role="button"
        onClick={(event) =>
          ((props.allowElementExplorer && props.row.isDir) ||
            (props.allowElementExplorer && !props.row.isDir) ||
            props.row.isDir) &&
          clickElement(event)
        }
        className="button_without_text_transform float-start"
      >
        {props.row.isDir ? (
          <>
            <FolderIcon
              className="grey_color folder_icon"
              style={{ fontSize: 40 }}
            />
            <span className="folder_name_table_view_span">
              {props.row.name}
            </span>
          </>
        ) : (
          <div
            draggable="true"
            data-versionid={props.row.versionId}
            data-absolutepath={props.row.absolutePath}
            onDragStart={dragThis}
            title={props.row.isValid ? "File is valid" : "File is not valid"}
          >
            {props.hideQcFlagMenu ? (
              <FileIcon
                fileName={props.row.name}
                isValid={props.row.isValid}
                fontSize={"large"}
                className={"description_icon"}
              />
            ) : (
              <QcActionFlag
                versionId={String(props.row.versionId)}
                refetch={props.refetch}
                qualityCheckStatus={String(props.row.qualityCheckStatus)}
              >
                <FileIcon
                  fileName={props.row.name}
                  isValid={props.row.isValid}
                  fontSize={"large"}
                  className={"description_icon"}
                />
              </QcActionFlag>
            )}

            <span className="file_name_table_view_span">{props.row.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const columnsRender = (
  refetch?: Function = () => {},
  hideContextMenu?: Boolean = false,
  allowElementExplorer?: Boolean = true,
  hideQcFlagMenu?: Boolean = false
): GridColumns<any> => {
  const idCellColumn = {
    field: "md5",
    headerName: "",
    width: 90,
    renderCell: (params: any) => <IdCell row={params.row} />
  };

  return [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      renderCell: (params: any) => (
        <NameCell
          row={params.row}
          allowElementExplorer={allowElementExplorer}
          refetch={refetch}
          hideContextMenu={hideContextMenu}
          hideQcFlagMenu={hideQcFlagMenu}
        />
      ),
      valueGetter: (params: any) => params.row.name
    },
    ...(hideContextMenu ? [] : [idCellColumn]),
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      valueGetter: (params: any) => params.row.size,
      renderCell: (params: any) =>
        params.row.isDir ? "" : formatFileSize(params.row.size),
      sortComparator: (v1: any, v2: any, param1: any, param2: any) =>
        (param1.api.getCellValue(param1.id, "size") as number) -
        (param2.api.getCellValue(param2.id, "size") as number)
    },
    {
      field: "created",
      headerName: "Last Updated Date",
      flex: 2
    },
    {
      field: "createdBy",
      headerName: "Last Updated By",
      flex: 2,
      renderCell: (params: any) => params.row.createdBy
    }
  ];
};

export const customFilters = [
  ...getGridStringOperators().filter(
    (filter) => filter.value !== "isAnyOf" // very difficult to get through GrqphQL
  ),
  {
    label: "does not contain",
    value: "noContain",
    getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const filterRegex = new RegExp(filterItem.value, "i");
      return (params: any): boolean => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return !filterRegex.test(rowValue?.toString() || "");
      };
    },
    InputComponent: GridFilterInputValue
  }
];

export const customDateFilters = [
  ...getGridDateOperators().filter((filter) =>
    ["after", "before"].includes(filter.value)
  )
];

export const customNumberFilters = [
  ...getGridNumericOperators().filter(
    (filter) => !["isAnyOf", ">", "<"].includes(filter.value)
  )
];
