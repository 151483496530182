import { Button } from "react-bootstrap";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

// Component used to explain to users how to get help regarding the PHIL app
export default function Support() {
  //TODO: This should not be hardcoded, maybe shouldd be present at a secret vault and environment dependent.
  const EMAIL =
    "mailto:PHIL - Support <54888d1d.bayergroup.onmicrosoft.com@emea.teams.ms>";
  const CHANNEL =
    "https://teams.microsoft.com/l/channel/19%3ad5a247acd11c415184b8723a5ed13f96%40thread.tacv2/Support?groupId=c3873937-3205-4ddc-9b84-6009df259a9b&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78";
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const onClickUrl =
    (url: string): (() => void) =>
    () =>
      openInNewTab(url);

  return (
    <div className="app_content">
      {
        <div>
          <h3>Welcome to the PHIL contact page!</h3>
          <Stack direction="column" spacing={3}>
            <Card sx={{ maxWidth: 640 }}>
              <CardMedia></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Teams Channel
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Please contact the PHIL support team through our Microsoft
                  Teams Support Channel. The recommended communication channel
                  for Bayer users.
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={onClickUrl(CHANNEL)}>Bayer User</Button>
              </CardActions>
            </Card>
            <Card sx={{ maxWidth: 640 }}>
              <CardMedia></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  E-mail
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Please contact the PHIL support team through our dedicated
                  PHIL support e-mail. The recommended communication channel for
                  external users.
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={onClickUrl(EMAIL)}>External User</Button>
              </CardActions>
            </Card>
          </Stack>
        </div>
      }
    </div>
  );
}
