import { gql, useLazyQuery } from "@apollo/client";
export const GET_CONSUMING_ACTIONS = gql`
  query getConsumingActions($versionIds: [ID!]!) {
    getConsumingActions(input: { versionIds: $versionIds }) {
      __typename
      id
      actionType
      actionFile {
        actionFileType
        file {
          __typename
          name
          versionId
          revision
          isValid
          repo {
            name
            id
          }
        }
      }
      startDatetime
      sourceFolder
      sourceFolder
      user {
        email
      }
      description
      actionQualityCheckStatus
      pmxActivity {
        id
        trialNumber
        mainRepository {
          id
          name
        }
      }
      actionStatus
      actionSource
      actionStatusDescription
      actionHistory {
        updated
        description
        actionQualityCheckStatus
        userId
      }
      historyLabel {
        __typename
        id
        name
      }
      hasAdditionalFilesLinked
      additionalDetails
      jobId
    }
  }
`;

export function useConsumingActionsLazyQuery() {
  const [
    getConsumingActions,
    {
      data: consumingActions,
      loading: isLoadingConsumingActions,
      error: errorConsumingActions,
      refetch: refetchConsumingActions
    }
  ] = useLazyQuery(GET_CONSUMING_ACTIONS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  }); // TODO: Query results is not stored in the cache to avoid a mix up in actionFile
  return {
    getConsumingActions,
    refetchConsumingActions,
    consumingActions,
    isLoadingConsumingActions,
    errorConsumingActions
  };
}
