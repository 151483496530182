import { gql, useMutation } from "@apollo/client";

export const MUTATION_DOWNLOAD_AUDIT_EVENTS = gql`
  mutation download_audits_trail {
    downloadAuditTrailEvents {
      actionStatus
    }
  }
`;

export function useDownloadAuditEvents() {
  const [
    downloadAuditEventsMutation,
    {
      data: downloadAuditEventsMutationData,
      loading: isLoadingDownloadMutation,
      error: errorDownloadAuditEventsMutation
    }
  ] = useMutation(MUTATION_DOWNLOAD_AUDIT_EVENTS, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    downloadAuditEventsMutation,
    downloadAuditEventsMutationData,
    isLoadingDownloadMutation,
    errorDownloadAuditEventsMutation
  };
}
