import { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useDeleteAccessRightsMutation } from "../../backend/hooks/pmxActivity/mutationDeleteAccessRights";
import { useEditAccessRightsMutation } from "../../backend/hooks/pmxActivity/mutationEditAccessRights";
import { useGetAccessRightsQuery } from "../../backend/hooks/pmxActivity/queryGetAccessRights";
import { useGetUsersQuery } from "../../backend/hooks/queryGetUserList";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Error from "../abstractComponents/error";
import { useUserHelper } from "../../hooks/userHelper";
import { useAuth } from "../authContextProvider";
import FormControl from "@mui/material/FormControl";

// component used for reading and editing access into the repositories
export default function AccessRightDetails(props: {
  repo: any;
  setIsAccessRightOpen: Function;
}) {
  const [shouldRefrechUserList, setShouldRefrechUserList] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { accessRightDetails, isLoadingAccess, error, refetchAccessQuery } =
    useGetAccessRightsQuery(props.repo.id);

  const { userList, isLoadingGroupQuery, refetchUserQuery } = useGetUsersQuery(
    shouldRefrechUserList
  );

  const { isBayerUser } = useUserHelper();
  const { isDataAccessManager } = useAuth();

  // force refresh user list when button is clicked
  useEffect(() => {
    if (shouldRefrechUserList) {
      refetchUserQuery().then(() => {
        setShouldRefrechUserList(false);
      });
    }
  }, [shouldRefrechUserList]);

  const { editAccessRightsMutation, isLoadingMutation, errorMutation } =
    useEditAccessRightsMutation();

  const {
    deleteAccessRightsMutation,
    isLoadingDeleteMutation,
    errorDeleteMutation
  } = useDeleteAccessRightsMutation();

  function addAccessRights() {
    editAccessRightsMutation({
      variables: {
        repoId: props.repo.id,
        contributor: selectedUsers.map(
          (selectedUser: any) =>
            userList?.getUsers.find((user: any) => user.email === selectedUser)
              .roleArn
        )
      }
    }).then(() => {
      refetchAccessQuery();
      setSelectedUsers([]);
    });
  }

  function deleteAccessRights() {
    deleteAccessRightsMutation({
      variables: {
        repoId: props.repo.id,
        contributor: selectedUsers.map(
          (selectedUser: any) =>
            userList?.getUsers.find((user: any) => user.email === selectedUser)
              .roleArn
        )
      }
    }).then(() => {
      refetchAccessQuery();
      setSelectedUsers([]);
    });
  }

  function changeUser(event: any) {
    setSelectedUsers(event);
  }

  return (
    <Modal
      onHide={() => props.setIsAccessRightOpen(false)}
      show
      dialogClassName="modal-80w access_rights_modal"
      data-testid="access_rights_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Manage Access Rights for {props.repo.name} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(isLoadingAccess || isLoadingMutation || isLoadingDeleteMutation) && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>Loading...</p>
          </div>
        )}
        {!isLoadingAccess && !error && (
          <Container fluid>
            <div className="list_access_rights_details">
              <Row>
                <Col>
                  <h6>Contributors:</h6>

                  {accessRightDetails?.getAccessRights &&
                    accessRightDetails?.getAccessRights.contributor.map(
                      (email: any) => {
                        return <Col key={email}>{email}</Col>;
                      }
                    )}

                  <br></br>
                </Col>
                <Col>
                  <h6>Readers:</h6>

                  {accessRightDetails?.getAccessRights &&
                    accessRightDetails?.getAccessRights.reader.map(
                      (email: any) => {
                        return <Col key={email}>{email}</Col>;
                      }
                    )}
                </Col>
              </Row>

              {((isBayerUser && props.repo.isStandard) ||
                (isDataAccessManager && !props.repo.isStandard)) && (
                <>
                  <Row>
                    <Col xs="auto">
                      <Button
                        id="refersh_user_list"
                        variant="outline-secondary"
                        onClick={() => {
                          setShouldRefrechUserList(true);
                        }}
                      >
                        Refresh User List
                      </Button>
                    </Col>
                    <Col xs="auto">
                      {isLoadingGroupQuery && (
                        <Spinner animation="border" className="spinner_color" />
                      )}
                    </Col>
                  </Row>

                  <Col xs="auto">
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      <InputLabel id="input_select_users_repo">
                        User Email
                      </InputLabel>
                      <Select
                        value={selectedUsers}
                        onChange={(e) => {
                          const {
                            target: { value }
                          } = e;
                          changeUser(
                            typeof value === "string" ? value.split(",") : value
                          );
                        }}
                        multiple
                        id="select_users_repo"
                        placeholder="Select users to add/remove as contributors"
                        input={<OutlinedInput label="User Email" />}
                      >
                        {!props.repo.isStandard && (
                          <MenuItem disabled value="">
                            <em>Internal Users</em>
                          </MenuItem>
                        )}
                        {!props.repo.isStandard &&
                          userList?.getUsers
                            .filter((user: any) => user.isPmx)
                            ?.map((user) => (
                              <MenuItem key={user.email} value={user.email}>
                                {user.email}
                              </MenuItem>
                            ))}

                        <MenuItem disabled value="">
                          <em>External Users</em>
                        </MenuItem>
                        {userList?.getUsers
                          .filter((user: any) => !user.isPmx)
                          ?.map((user) => (
                            <MenuItem key={user.email} value={user.email}>
                              {user.email}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <br></br>
                  <Row>
                    <Col xs="auto">
                      <Button
                        variant="primary"
                        id="add_user_button"
                        onClick={addAccessRights}
                      >
                        Add
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        id="remove_user_button"
                        variant="primary"
                        onClick={deleteAccessRights}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Container>
        )}
        {(error || errorMutation || errorDeleteMutation) && (
          <Error error={error || errorMutation || errorDeleteMutation} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => props.setIsAccessRightOpen(false)}
          id="close_access_window"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
