import { useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import {
  encryptStringWithRsaPublicKey,
  MAXIMUM_CLEAR_PASSWORD
} from "../../helpers/stringHelper";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGetSvnMetadataQuery } from "../../backend/hooks/importExportMenu/queryGetSvnMetadata";
import { useGetModspaceMetadataQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceMetadata";
import Error from "../abstractComponents/error";
import { customFilters } from "../../helpers/tableViewHelper";
import FolderIcon from "@mui/icons-material/Folder";
import {
  DataGridPro,
  GridRowId,
  GridSelectionModel
} from "@mui/x-data-grid-pro";
import {
  InputMaybe,
  ModspaceSourceInput,
  SpmReMeta,
  ModspaceMeta,
  SpmReSourceInput,
  PmxActivity
} from "../../backend/types";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useGetSpmReRevisionsQuery } from "../../backend/hooks/importExportMenu/queryGetSvnRevisions";
import { useGetModspaceRevisionsQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceRevisions";

export enum SVNType {
  SPMRE = "SPMRE",
  Modspace = "Modspace",
  Template = "Template",
  Empty = "Empty"
}
interface SvnImportProps {
  setSvnDetails: React.Dispatch<React.SetStateAction<any>>;
  svnDetails: any;
  svnType: SVNType;
  modspaceFolder?: InputMaybe<string>;
  activities?: PmxActivity[];
}

interface SvnAttributes {
  name: string;
  revisions: string[];
}

export default function SvnImport(props: SvnImportProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [revisions, setRevisions] = useState<string[]>([]);
  const [loginUsername, setLoginUsername] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");
  const [selectedSvn, setSelectedSvn] = useState<
    SpmReMeta | ModspaceMeta | null
  >(null);
  const [selectedSvnName, setSelectedSvnName] = useState<string | null>(null);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [search, setSearch] = useState<string>("");

  const { getSpmReFolders, errorMetadata, loadingMetadata, metadata } =
    useGetSvnMetadataQuery();

  const {
    getModspaceFolders,
    errorModspaceMetadata,
    loadingModspaceMetadata,
    modspaceMetadata
  } = useGetModspaceMetadataQuery();

  const updateSvnDetails = (
    values: {
      key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
      value: string;
    }[]
  ) => {
    return props.setSvnDetails((prevState: any) => {
      const newState = values.reduce(
        (state, newValue) => {
          state[newValue.key] = newValue.value as string & InputMaybe<string>[];
          return state;
        },
        { ...prevState }
      );
      return newState;
    });
  };

  // todo: fix columns for modspace
  const columnsMetadata = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <div key={params.row.name} style={{ width: "100%" }}>
          <FolderIcon
            className="grey_color folder_icon"
            style={{ fontSize: 50 }}
          />
          <span className="folder_name_table_view_span">{params.row.name}</span>
          {props.activities?.find((activity: PmxActivity) => {
            const source = JSON.parse(activity?.source);
            return (
              source?.modspaceFolder === params.row.name ||
              source?.spmReFolder === params.row.name
            );
          }) && <span className="red_form"> (Already Imported)</span>}
        </div>
      ),
      valueGetter: (params: any) => params.row.name
    }
  ];

  // todo: fix footer for modspace
  const CustomFooter = () => {
    if (props.svnType === SVNType.SPMRE) {
      return (
        <div className="my-4 d-flex justify-content-between">
          <div>{selectionModel[0]?.toString()}</div>
          {metadata?.getSpmReFolders && (
            <div>
              Total Rows: {metadata?.getSpmReFolders?.spmReFolders.length}
            </div>
          )}
        </div>
      );
    } else if (props.svnType === SVNType.Modspace) {
      return (
        <div className="my-4 d-flex justify-content-between">
          <div>{selectionModel[0]?.toString()}</div>
          {modspaceMetadata?.getModspaceFolders && (
            <div>
              Total Rows:{" "}
              {modspaceMetadata?.getModspaceFolders?.modspaceFolders.length}
            </div>
          )}
        </div>
      );
    }
  };

  const encryptAndSetPassword = () => {
    const encryptedPassword = encryptStringWithRsaPublicKey(loginPassword);
    setLoginPassword(encryptedPassword);
    if (props.svnType === SVNType.SPMRE) {
      localStorage.setItem("svnUsername", loginUsername);
      localStorage.setItem("svnPassword", encryptedPassword);
    } else if (props.svnType === SVNType.Modspace) {
      localStorage.setItem("modspaceUsername", loginUsername);
      localStorage.setItem("modspacePassword", encryptedPassword);
    }

    updateSvnDetails([
      { key: "username", value: loginUsername },
      { key: "encryptedPassword", value: encryptedPassword }
    ]);

    return encryptedPassword;
  };

  const handleOnRowClick = (grid: GridSelectionModel) => {
    if (
      (props.svnType === SVNType.SPMRE &&
        grid[0] === (selectedSvn as SpmReMeta)?.spmReFolder) ||
      (props.svnType === SVNType.Modspace &&
        grid[0] === (selectedSvn as ModspaceMeta)?.modspaceFolder)
    ) {
      setSelectionModel([]);
      setSelectedSvn(null);
      setSelectedSvnName(null);

      updateSvnDetails([
        { key: "spmReFolder", value: "" },
        { key: "spmReRevision", value: "" },
        { key: "modspaceFolder", value: "" },
        { key: "modspaceRevision", value: "" }
      ]);
    } else {
      setSelectionModel(grid);
      if (props.svnType === SVNType.SPMRE) {
        updateSvnDetails([{ key: "spmReFolder", value: grid[0] as string }]);
      } else {
        updateSvnDetails([{ key: "modspaceFolder", value: grid[0] as string }]);
      }
      setSelectedSvnName(grid[0]);
    }
  };

  const showRevision = () => {
    if (props.svnType === SVNType.SPMRE) {
      return props.svnDetails?.spmReRevision
        ? props.svnDetails?.spmReRevision
        : "HEAD";
    } else {
      return props.svnDetails?.modspaceRevision
        ? props.svnDetails?.modspaceRevision
        : "HEAD";
    }
  };

  function resetFields(): void {
    setSelectionModel([]);
    setSelectedSvn(null);
    setSelectedSvnName(null);
    props.setSvnDetails(null);
    setSearch("");
  }

  const searchSVN = (
    encryptedPassword: string
  ): {
    key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
    value: string;
  } => {
    if (props.svnType === SVNType.SPMRE) {
      getSpmReFolders({
        variables: {
          username: loginUsername,
          encryptedPassword: encryptedPassword,
          search: search
        }
      });

      return { key: "spmReRevision", value: "HEAD" };
    } else {
      getModspaceFolders({
        variables: {
          username: loginUsername,
          encryptedPassword: encryptedPassword,
          search: search
        }
      });

      return { key: "modspaceRevision", value: "HEAD" };
    }
  };

  useEffect(() => {
    if (search !== "" && loginUsername !== "" && loginPassword !== "") {
      if (
        search.length > 2 &&
        loginUsername.length > 0 &&
        loginPassword.length > 0
      ) {
        // wait 1 sec before the call
        const timeOutId = setTimeout(() => {
          let encryptedPassword = loginPassword;
          if (
            loginPassword?.length < MAXIMUM_CLEAR_PASSWORD &&
            !loginPassword.endsWith("=")
          ) {
            encryptedPassword = encryptAndSetPassword();
          }

          const revisionType: {
            key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
            value: string;
          } = searchSVN(encryptedPassword);
          const updateValues: {
            key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
            value: string;
          }[] = [
            revisionType,
            { key: "username", value: loginUsername },
            { key: "encryptedPassword", value: encryptedPassword }
          ];

          updateSvnDetails(updateValues);
        }, 1000);

        return () => clearTimeout(timeOutId);
      }
    }
  }, [search, loginUsername, loginPassword]);

  useEffect(() => {
    resetFields();
    if (props.svnType === SVNType.SPMRE) {
      setLoginUsername(
        localStorage.getItem("svnUsername")
          ? (localStorage.getItem("svnUsername") as string)
          : ""
      );
      setLoginPassword(
        localStorage.getItem("svnPassword")
          ? (localStorage.getItem("svnPassword") as string)
          : ""
      );
    } else {
      setLoginUsername(
        localStorage.getItem("modspaceUsername")
          ? (localStorage.getItem("modspaceUsername") as string)
          : ""
      );
      setLoginPassword(
        localStorage.getItem("modspacePassword")
          ? (localStorage.getItem("modspacePassword") as string)
          : ""
      );
    }
  }, [props.svnType]);

  const {
    getModspaceRevisions,
    errorModspaceRevisions,
    loadingModspaceRevisions,
    modspaceRevisions
  } = useGetModspaceRevisionsQuery();

  const {
    getSpmReRevisions,
    errorRevisions,
    loadingRevisions,
    revisions: svnRevisions
  } = useGetSpmReRevisionsQuery();

  useEffect(() => {
    if (svnRevisions?.getSpmReRevisions) {
      setRevisions(svnRevisions?.getSpmReRevisions?.spmReRevisions.reverse());
    } else if (modspaceRevisions?.getModspaceRevisions) {
      setRevisions(
        modspaceRevisions?.getModspaceRevisions?.modspaceRevisions.reverse()
      );
    } else {
      setRevisions([]);
    }
  }, [svnRevisions, modspaceRevisions]);

  const loadRevisions = () => {
    if (props.svnType === SVNType.SPMRE) {
      getSpmReRevisions({
        variables: {
          username: loginUsername,
          encryptedPassword: loginPassword,
          spmReFolder: selectedSvnName
        }
      });
    } else {
      getModspaceRevisions({
        variables: {
          username: loginUsername,
          encryptedPassword: loginPassword,
          modspaceFolder: selectedSvnName
        }
      });
    }
  };

  return (
    <>
      <div
        className={
          loginPassword?.length > MAXIMUM_CLEAR_PASSWORD
            ? "login_grey_color_row"
            : ""
        }
      >
        <TextField
          className="w-100 mb-3"
          label={`${
            props.svnType === SVNType.SPMRE ? SVNType.SPMRE : SVNType.Modspace
          } Username`}
          required
          placeholder="Enter username"
          data-testid="importFromSvnUserFormControl"
          id="importFromSvnUserFormControl"
          value={loginUsername}
          onChange={(e) => setLoginUsername(e.target.value)}
        />
        <TextField
          className="w-100 mb-3"
          label={`${
            props.svnType === SVNType.SPMRE ? SVNType.SPMRE : SVNType.Modspace
          } Password`}
          required
          type={
            showPassword && loginPassword?.length < MAXIMUM_CLEAR_PASSWORD
              ? "text"
              : "password"
          }
          placeholder="Enter password"
          id="importFromSvnPasswordFormControl"
          value={loginPassword}
          onChange={(e) => setLoginPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={(e) => e.preventDefault()}
              >
                {loginPassword?.length > MAXIMUM_CLEAR_PASSWORD ? (
                  <></> ? (
                    showPassword
                  ) : (
                    <VisibilityOff />
                  )
                ) : (
                  <Visibility />
                )}
              </IconButton>
            )
          }}
        />
      </div>
      <TextField
        className="w-100 mb-3"
        id="importFromSvnLocationFormControl"
        label="Location"
        placeholder=""
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        helperText="Enter at least 3 characters"
      />
      {/* todo: fix for modspace */}
      <DataGridPro
        rows={
          metadata?.getSpmReFolders && props.svnType !== SVNType.Modspace
            ? metadata?.getSpmReFolders?.spmReFolders.map((name: string) => ({
                name
              }))
            : (props.svnType === SVNType.Modspace &&
                modspaceMetadata?.getModspaceFolders?.modspaceFolders.map(
                  (name: string) => ({
                    name
                  })
                )) ||
              []
        }
        columns={columnsMetadata}
        loading={loadingMetadata || loadingModspaceMetadata}
        pageSize={10}
        rowHeight={60}
        autoHeight
        className="svn-metadata-table"
        getRowId={(row) => row.name}
        disableMultipleSelection={true}
        selectionModel={selectionModel}
        onSelectionModelChange={(selection) => handleOnRowClick(selection)}
        components={{
          Footer: CustomFooter
        }}
      />
      {selectedSvnName && (
        <Row>
          <Col xs={2}>
            <Form.Label>Revisions:</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Select
              aria-label="HEAD"
              value={showRevision()}
              onClick={loadRevisions}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                props.svnType === SVNType.SPMRE
                  ? updateSvnDetails([
                      { key: "spmReRevision", value: event.target.value }
                    ])
                  : updateSvnDetails([
                      { key: "modspaceRevision", value: event.target.value }
                    ]);
              }}
              key="edit-revision"
              id="revision-number-select"
            >
              <option>HEAD</option>
              {revisions.map((revisionNumber: string) => (
                <option key={revisionNumber} value={revisionNumber}>
                  {revisionNumber}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={2}>
            {(loadingRevisions || loadingModspaceRevisions) && (
              <Spinner className="spinner_color" />
            )}
          </Col>
          <Col xs={2}>
            <Button
              data-testid="reset-import-button"
              variant="primary"
              onClick={resetFields}
            >
              Unselect Repo
            </Button>
          </Col>
        </Row>
      )}

      {(errorMetadata ||
        errorModspaceMetadata ||
        errorRevisions ||
        errorModspaceRevisions) && (
        <Error
          error={
            errorMetadata ||
            errorModspaceMetadata ||
            errorRevisions ||
            errorModspaceRevisions
          }
        />
      )}
    </>
  );
}
