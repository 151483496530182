import { gql, useQuery } from "@apollo/client";
export const GET_HISTORY_LABELS_QUERY = gql`
  query getHistoryLabels($activityId: ID!, $page: Int, $pageSize: Int) {
    getHistoryLabels(
      input: { activityId: $activityId, page: $page, pageSize: $pageSize }
    ) {
      totalRowCount
      historyLabels {
        id
        name
        actionId
        updatedBy
        updated
      }
    }
  }
`;

export function useGetHistoryLabels(
  activityId: Number,
  page: Number = 0,
  pageSize: Number = 1000
) {
  const {
    data: historyLabels,
    loading: getHistoryLabelsLoading,
    error: getHistoryLabelsError,
    refetch: refetchHistoryLabel
  } = useQuery(GET_HISTORY_LABELS_QUERY, {
    variables: { activityId: activityId, page: page, pageSize: pageSize },
    fetchPolicy: "no-cache"
  });

  return {
    historyLabels: historyLabels?.getHistoryLabels,
    getHistoryLabelsLoading,
    getHistoryLabelsError,
    refetchHistoryLabel
  };
}
