// maybe we should remove this??
import { gql, useQuery } from "@apollo/client";
export const QUERY_GET_PMX_ACTIONS = gql`
  query getPmxActions($activityId: ID!) {
    getPmxActions(input: { activityId: $activityId }) {
      __typename
      id
      actionType
      actionFile {
        __typename
        actionFileType
        file {
          __typename
          name
          versionId
          isValid
          revision
          repo {
            name
            id
          }
        }
      }
      startDatetime
      sourceFolder
      sourceFiles
      user {
        email
      }
      description
      actionQualityCheckStatus
      pmxActivity {
        __typename
        id
        trialNumber
        mainRepository {
          id
          name
        }
      }
      actionStatus
      actionSource
      actionStatusDescription
      actionHistory {
        updated
        description
        actionQualityCheckStatus

        userId
      }
      historyLabel {
        __typename
        name
      }
      referencedInComments {
        __typename
        id
        text
        created
        createdBy
      }
      additionalDetails
      jobId
      hasAdditionalFilesLinked
    }
  }
`;

export function useGetPmxActionsQuery(activityId: Number) {
  const {
    loading: isLoadingActions,
    data: actions,
    error: errorActions,
    refetch: refetchActions
  } = useQuery(QUERY_GET_PMX_ACTIONS, {
    variables: {
      activityId: activityId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  return {
    actions,
    errorActions,
    refetchActions,
    isLoadingActions
  };
}
