import React, { useState, createContext } from "react";
import { ApolloError } from "@apollo/client";
import { useGetPmxActivitiyDetailsQuery } from "../backend/hooks/pmxActivity/queryGetPmxActivityDetails";
import { useLocation } from "react-router-dom";
import { PmxActivity } from "../backend/types";

interface PmxActivityDetailsType {
  activityId: number;
  activityData: PmxActivity;
  isActivityLoading: boolean;
  refetchActivityDetails: Function;
  isLoadingMutation: boolean;
  setIsLoadingMutation: Function;
  errorActivity?: ApolloError;
}

interface CaughtProviderProps {
  children: React.ReactNode;
}

export const PmxActivityDetailsContext = createContext<PmxActivityDetailsType>(
  {} as PmxActivityDetailsType
);

export const PmxActivityDetailsProvider: React.FC<CaughtProviderProps> = ({
  children
}) => {
  const regexActivity = /\/activity\//;
  let activityId: any = useLocation()
    .pathname.replace(regexActivity, "")
    .split("%")[0];
  activityId = activityId.includes("%")
    ? Number(activityId.split("%")[0])
    : Number(activityId.split("/")[0]);
  const [isLoadingMutation, setIsLoadingMutation] = useState<boolean>(false);

  const {
    activityData,
    isActivityLoading,
    errorActivity,
    refetchActivityDetails
  } = useGetPmxActivitiyDetailsQuery((data: any) => {
    document.title = `PHIL App - Activity ${data.getPmxActivityDetails.trialNumber}`;
  }, activityId);

  return (
    <PmxActivityDetailsContext.Provider
      value={{
        activityId,
        activityData: activityData?.getPmxActivityDetails,
        refetchActivityDetails,
        isActivityLoading,
        isLoadingMutation,
        setIsLoadingMutation,
        errorActivity
      }}
    >
      {children}
    </PmxActivityDetailsContext.Provider>
  );
};
