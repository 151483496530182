import SvgIcon from "@mui/material/SvgIcon";
import { svgTypes } from "./svgIconTypes";

interface SVGIconsProps {
  svgName: string;
}

export function SVGIcons(props: SVGIconsProps) {
  const { svgName } = props;

  return <SvgIcon className="svg-custom-icons">{svgTypes[svgName]}</SvgIcon>;
}
