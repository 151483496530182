import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_DIFFERENCE = gql`
  mutation createDifference($environmentId: ID!) {
    createDifference(input: { environmentId: $environmentId })
  }
`;

export function useCreateDifferenceMutation() {
  const [
    createDifference,
    {
      data: differenceCreated,
      loading: isLoadingCreateDifference,
      error: errorCreateDifference
    }
  ] = useMutation(MUTATION_CREATE_DIFFERENCE, {
    context: { clientName: "interactive" }
  });

  return {
    createDifference,
    differenceCreated,
    isLoadingCreateDifference,
    errorCreateDifference
  };
}
