import { gql, useQuery } from "@apollo/client";

export const GET_JOBS_LIST = gql`
  query getJobs($activityId: ID!, $limit: Int!, $cursor: String) {
    getJobs(
      input: { activityId: $activityId, limit: $limit, cursor: $cursor }
    ) {
      jobs {
        id
        name
        status
        createdAt
        createdBy
        commands
        environmentVariables {
          name
          value
        }
        image {
          id
          name
          environmentType
          environmentTypeVersion
        }
      }
      totalCount
      nextCursor
    }
  }
`;

export function useGetJobsList(
  activityId: number,
  limit: number = 20,
  cursor: string | null | undefined = null
) {
  const {
    data: jobList,
    loading: isGetJobsListLoading,
    error: errorGetJobsList,
    refetch: refetchJobList,
    fetchMore: fetchMoreJobs
  } = useQuery(GET_JOBS_LIST, {
    variables: { activityId, limit, cursor },
    context: { clientName: "execution" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  return {
    jobList,
    isGetJobsListLoading,
    errorGetJobsList,
    refetchJobList,
    fetchMoreJobs
  };
}
