import { gql, useMutation } from "@apollo/client";

export const MUTATION_EDIT_ACCESS_RIGHTS = gql`
  mutation editAccessRights($repoId: ID!, $contributor: [String]) {
    editAccessRights(input: { repoId: $repoId, contributor: $contributor }) {
      name
      reader
      contributor
    }
  }
`;

export function useEditAccessRightsMutation() {
  const [
    editAccessRightsMutation,
    { loading: isLoadingMutation, error: errorMutation }
  ] = useMutation(MUTATION_EDIT_ACCESS_RIGHTS);

  return {
    editAccessRightsMutation,
    isLoadingMutation,
    errorMutation
  };
}
