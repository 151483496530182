import { gql, useMutation } from "@apollo/client";

export const CANCEL_JOB = gql`
  mutation cancelJob($jobId: ID!) {
    cancelJob(input: { jobId: $jobId }) {
      id
      status
    }
  }
`;

export function useCancelExeuctionJobMutation() {
  const [
    cancelJob,
    { data: executionJob, loading: isLoadingCancelJob, error: errorCancelJob }
  ] = useMutation(CANCEL_JOB, {
    context: { clientName: "execution" }
  });

  return {
    cancelJob,
    executionJob,
    isLoadingCancelJob,
    errorCancelJob
  };
}
