import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_SVN_CONTENT = gql`
  mutation importSpmReContent(
    $username: String!
    $encryptedPassword: String!
    $spmRePaths: [String]!
    $spmReRevision: String
    $spmReFolder: String!
    $repoFolder: String!
    $activityId: ID!
    $qualityCheckStatus: QualityCheckStatus
    $description: String
  ) {
    importSpmReContent(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        spmReFolder: $spmReFolder
        spmRePaths: $spmRePaths
        spmReRevision: $spmReRevision
        repoFolder: $repoFolder
        activityId: $activityId
        qualityCheckStatus: $qualityCheckStatus
        description: $description
      }
    ) {
      actionId
      actionStatus
    }
  }
`;

export function useImportContentFromSvnMutation() {
  const [
    importContentMutation,
    {
      data: svnImportMutationData,
      loading: isLoadingSvnMutation,
      error: errorSvnMutation
    }
  ] = useMutation(MUTATION_IMPORT_SVN_CONTENT, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    importContentMutation,
    svnImportMutationData,
    isLoadingSvnMutation,
    errorSvnMutation
  };
}
