import { gql, useMutation } from "@apollo/client";

export const MUTATION_EXPORT_FILES = gql`
  mutation exportFiles(
    $environmentId: ID!
    $exportPaths: [ActionFilePathInputType!]!
    $deletePaths: [String!]
    $additionalFiles: [ActionFileInputType!]
    $qualityCheckStatus: QualityCheckStatus
    $description: String
    $actionType: ActionType
  ) {
    exportFiles(
      input: {
        environmentId: $environmentId
        exportPaths: $exportPaths
        deletePaths: $deletePaths
        additionalFiles: $additionalFiles
        qualityCheckStatus: $qualityCheckStatus
        description: $description
        actionType: $actionType
      }
    )
  }
`;

export function useExportFilesMutation() {
  const [
    exportFiles,
    { loading: isLoadingExportFiles, error: errorExportFiles }
  ] = useMutation(MUTATION_EXPORT_FILES, {
    context: { clientName: "interactive" }
  });

  return {
    exportFiles,
    isLoadingExportFiles,
    errorExportFiles
  };
}
