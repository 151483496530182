import { useAuth } from "./authContextProvider";
import { Row, Col } from "react-bootstrap";
import { useUserHelper } from "../hooks/userHelper";

export default function Footer(): JSX.Element {
  const { decodedToken, isAdminUser } = useAuth();
  const { isTestUser } = useUserHelper();

  return (
    <footer className="container-fluid">
      <Row>
        <Col xs={5}>
          Copyright © PHIL 2023 |{" "}
          <a id="activities_link_footer" href="/activities">
            Activities
          </a>{" "}
          |{" "}
          <a id="actions_link_footer" href="/actions">
            Actions
          </a>{" "}
          |{" "}
          {isAdminUser && (
            <>
              <a id="audit_link_footer" href="/audit">
                Audit Trail
              </a>{" "}
              |{" "}
            </>
          )}{" "}
          {(isTestUser || isAdminUser) && (
            <>
              <a href="/token">Token</a> |{" "}
            </>
          )}
          <a id="contact_us_footer" href="/support">
            Contact Us
          </a>{" "}
          |{" "}
          <a id="contact_us_footer" href="/external">
            Help
          </a>
        </Col>
        {decodedToken && (
          <Col className="username_footer_div" xs={2}>
            Current user:{" "}
            <span className="username_footer_span">
              {decodedToken["email"]}
            </span>
          </Col>
        )}
        <Col>
          <img
            src="/images/Bayer-PHIL-Icon_white.png"
            alt="PHIL_logo_small"
            className="float-end"
          />
        </Col>
      </Row>
    </footer>
  );
}
