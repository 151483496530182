import { gql, useMutation } from "@apollo/client";

export const MUTATION_DELETE_ACCESS_RIGHTS = gql`
  mutation deleteAccessRights($repoId: ID!, $contributor: [String]) {
    deleteAccessRights(input: { repoId: $repoId, contributor: $contributor }) {
      name
      reader
      contributor
    }
  }
`;

export function useDeleteAccessRightsMutation() {
  const [
    deleteAccessRightsMutation,
    { loading: isLoadingDeleteMutation, error: errorDeleteMutation }
  ] = useMutation(MUTATION_DELETE_ACCESS_RIGHTS);

  return {
    deleteAccessRightsMutation,
    isLoadingDeleteMutation,
    errorDeleteMutation
  };
}
