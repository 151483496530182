import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_UPLOAD_LINK = gql`
  mutation createUploadLinks(
    $absolutePaths: [String]!
    $md5sums: [String]!
    $activityId: ID!
    $actionFileTypes: [ActionFileType]
    $actionId: ID
    $qualityCheckStatus: QualityCheckStatus
    $uncompressOnUpload: Boolean
  ) {
    createUploadLinks(
      input: {
        activityId: $activityId
        md5sums: $md5sums
        absolutePaths: $absolutePaths
        actionFileTypes: $actionFileTypes
        actionId: $actionId
        qualityCheckStatus: $qualityCheckStatus
        uncompressOnUpload: $uncompressOnUpload
      }
    ) {
      actionId
      actionStatus
      presignedUrls {
        url
        fields
      }
    }
  }
`;

export function useCreateUploadLinkMutation() {
  const [
    getUploadLinksForFiles,
    { data: uploadData, loading: uploadLoading, error: uploadLinksError }
  ] = useMutation(MUTATION_CREATE_UPLOAD_LINK, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    getUploadLinksForFiles,
    uploadData,
    uploadLoading,
    uploadLinksError
  };
}
