import { gql, useLazyQuery } from "@apollo/client";
export const GET_HISTORY_VERSIONS = gql`
  query getHistoryVersions(
    $repoId: ID
    $versionId: ID!
    $forceRefresh: Boolean
  ) {
    getHistoryVersions(
      input: {
        repoId: $repoId
        versionId: $versionId
        forceRefresh: $forceRefresh
      }
    ) {
      __typename
      name
      isValid
      versionId
      revision
      createdBy
      created
      md5
      size
      repo {
        id
        name
      }
    }
  }
`;

export function useHistoryVersionsLazyQuery() {
  const [getFileVersions, { error, data: fileVersions, loading: isLoading }] =
    useLazyQuery(GET_HISTORY_VERSIONS);
  return {
    getFileVersions,
    fileVersions,
    isLoading,
    error
  };
}
