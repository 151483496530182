import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_MULTIPLART_UPLOAD = gql`
  mutation createMultipartUpload(
    $absolutePath: String!
    $md5sum: String!
    $activityId: ID!
    $actionFileType: ActionFileType
    $qualityCheckStatus: QualityCheckStatus
    $uncompressOnUpload: Boolean
    $actionId: ID
  ) {
    createMultipartUpload(
      input: {
        absolutePath: $absolutePath
        md5sum: $md5sum
        activityId: $activityId
        actionId: $actionId
        actionFileType: $actionFileType
        qualityCheckStatus: $qualityCheckStatus
        uncompressOnUpload: $uncompressOnUpload
      }
    ) {
      actionId
      actionStatus
      uploadId
    }
  }
`;

export function useCreateMultipartUpload() {
  const [
    createMulipartUpload,
    {
      loading: createMulipartUploadLoading,
      data: createMulipartUploadData,
      error: createMulipartUploadError
    }
  ] = useMutation(MUTATION_CREATE_MULTIPLART_UPLOAD, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    createMulipartUpload,
    createMulipartUploadLoading,
    createMulipartUploadData,
    createMulipartUploadError
  };
}
