import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, Spinner } from "react-bootstrap";
import { useActivateUserMutation } from "../backend/hooks/mutationActivateUser";
import { useUserHelper } from "../hooks/userHelper";
import video from "../resources/PHILtraining_externals.webm";
import training_slides from "../resources/TrainingExternals.pdf";
import training_material from "../resources/training_material.png";
import Error from "./abstractComponents/error";
import { useAuth } from "./authContextProvider";

// Landing page for a user that has not performed the PHIL training
export default function External() {
  const { signOut } = useAuth();
  const { isUnactivatedUser } = useUserHelper();

  const { activateUserMutation, isLoadingMutation, errorMutation } =
    useActivateUserMutation();

  function activateUser() {
    if (
      window.confirm(
        "Please confirm that you have completed all of the trainings by clicking on OK!\nAfter clicking on it, you will be logged out and redirected to the login page. To finalize the confirmation and registration procedure, please log in again!"
      )
    ) {
      activateUserMutation().then(() => {
        signOut();
      });
    }
  }

  return (
    <div className="app_content">
      {isLoadingMutation && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {!isLoadingMutation && !errorMutation && (
        <div>
          <h3>Welcome to the PHIL training page!</h3>
          <Card
            sx={{ maxWidth: 640 }}
            style={{ border: "none", boxShadow: "none" }}
          >
            <CardContent>
              <Typography gutterBottom variant="subtitle1" component="div">
                Please follow the instructions below to get access to PHIL
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingLeft: "10px" }}
              >
                1. Read the training slides. Additionally, you are free to watch
                the training video for a better comprehension of PHIL.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingLeft: "10px" }}
              >
                2. Confirm that you have conducted and understood the training
                slides.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingLeft: "10px" }}
              >
                3. After pressing the confirmation button, you will be
                redirected to the PHIL login page. Re-login into PHIL in order
                to successfully complete the training confirmation.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingLeft: "10px" }}
              >
                4. Inform your Bayer partner that you have completed the
                training.
              </Typography>
            </CardContent>
          </Card>
          <Stack direction="row" spacing={3}>
            <Card sx={{ maxWidth: 640 }}>
              <CardMedia>
                <video
                  width="640"
                  height="360"
                  controls
                  src={video}
                  title="Training Video"
                ></video>
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  PHIL Training Video
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The training video serves as a supplement to the training
                  slides. It is recommended to watch it in addition to the
                  slides.
                </Typography>
              </CardContent>
              <CardActions></CardActions>
            </Card>
            <Card sx={{ maxWidth: 640 }}>
              <CardMedia>
                <img
                  width="100%"
                  height="100%"
                  src={training_material}
                  alt="training_material"
                ></img>
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  PHIL Training Slides
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The training slides contain the most important information
                  about PHIL as well as short explanations of its
                  functionalities.
                </Typography>
              </CardContent>
              <CardActions>
                <div className="center_div">
                  <Button
                    href={training_slides}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read Training Slides
                  </Button>
                  {errorMutation && <Error error={errorMutation} />}
                </div>
                {isUnactivatedUser && (
                  <div className="center_div">
                    <Button className="confirm_training" onClick={activateUser}>
                      Confirm Training
                    </Button>

                    {errorMutation && <Error error={errorMutation} />}
                  </div>
                )}
              </CardActions>
            </Card>
          </Stack>
        </div>
      )}
    </div>
  );
}
