import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_AUDIT_TRAIL_EVENTS = gql`
  query getAuditTrailById($auditId: ID!) {
    getAuditTrailById(input: { auditId: $auditId }) {
      __typename
      id
      created
      userId
      repository {
        id
        name
      }
      data
      actionType
    }
  }
`;

export function useGetAuditTrailEventByIdQuery() {
  const [
    auditQueryId,
    { data: auditTrailEventId, loading: isLoadingId, error: errorId }
  ] = useLazyQuery(QUERY_GET_AUDIT_TRAIL_EVENTS, {
    fetchPolicy: "no-cache"
  });

  return { auditQueryId, auditTrailEventId, isLoadingId, errorId };
}
