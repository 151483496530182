import { GridValueGetterParams } from "@mui/x-data-grid-pro";
import { customFilters } from "../../helpers/tableViewHelper";
import { ActionType, ActionTypeType } from "../../helpers/stringHelper";
import { Link } from "react-router-dom";

export const getActivityColumns = (activityId: Number) => [
  {
    field: "id",
    headerName: "Action ID",
    width: 80,
    filterOperators: customFilters,
    renderCell: (params: any) => (
      <Link
        to={`/activity/${activityId}/history/fileExplorer?actionId=${params.row.id}`}
      >
        {params.row.id}
      </Link>
    )
  },
  {
    field: "actionType",
    headerName: "Type",
    filterOperators: customFilters,

    width: 180,
    valueGetter: (params: GridValueGetterParams) =>
      ActionType[params.row.actionType as ActionTypeType]
  },
  {
    field: "startDatetime",
    headerName: "Executed At",
    filterOperators: customFilters,
    width: 180
  },
  {
    field: "userId",
    headerName: "User",
    filterOperators: customFilters,
    width: 200,
    valueGetter: (params: GridValueGetterParams) => params.row.user.email
  },
  {
    field: "description",
    headerName: "Description",
    filterOperators: customFilters,
    width: 250
  },
  {
    field: "historyLabel",
    headerName: "History Label",
    width: 120,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row?.historyLabel ? params.row.historyLabel.name : "";
    }
  }
];
