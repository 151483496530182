import { gql, useMutation } from "@apollo/client";

export const MUTATION_RESTORE_FILE_VERSION = gql`
  mutation restoreFileVersion(
    $activityId: ID!
    $versionIds: [ID!]!
    $comment: String
  ) {
    restoreFileVersion(
      input: {
        activityId: $activityId
        versionIds: $versionIds
        comment: $comment
      }
    ) {
      actionId
      versionIds
    }
  }
`;

export function useRestoreFileVersionMutation() {
  const [
    restoreFileVersionMutation,
    {
      loading: isLoadingRestoreMutation,
      error: errorRestoreMutation,
      data: dataMutation
    }
  ] = useMutation(MUTATION_RESTORE_FILE_VERSION);

  return {
    restoreFileVersionMutation,
    isLoadingRestoreMutation,
    errorRestoreMutation,
    dataMutation
  };
}
