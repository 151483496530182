import { gql, useMutation } from "@apollo/client";

export const CREATE_FOLDER_MUTATION = gql`
  mutation createFolder(
    $name: String!
    $parentFolder: String!
    $activityId: ID!
  ) {
    createFolder(
      input: {
        name: $name
        parentFolder: $parentFolder
        activityId: $activityId
      }
    ) {
      name
    }
  }
`;

export function useCreateFolderMutation() {
  const [
    createFolderMutation,
    { loading: isLoadingCreateFolderMutation, error: errorCreateFolderMutation }
  ] = useMutation(CREATE_FOLDER_MUTATION);

  return {
    createFolderMutation,
    isLoadingCreateFolderMutation,
    errorCreateFolderMutation
  };
}
