import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_SVN_REVISIONS = gql`
  query getSpmReRevisions(
    $username: String!
    $encryptedPassword: String!
    $spmReFolder: String!
  ) {
    getSpmReRevisions(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        spmReFolder: $spmReFolder
      }
    ) {
      spmReRevisions
    }
  }
`;

export function useGetSpmReRevisionsQuery() {
  const [getSpmReRevisions, { error, loading, data }] = useLazyQuery(
    QUERY_GET_SVN_REVISIONS,
    { fetchPolicy: "no-cache" }
  );

  return {
    getSpmReRevisions,
    errorRevisions: error,
    loadingRevisions: loading,
    revisions: data
  };
}
