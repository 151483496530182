import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Form,
  Modal,
  Spinner,
  InputGroup,
  Row,
  Col
} from "react-bootstrap";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { columnsRender, MenuContext } from "../../../helpers/tableViewHelper";
import { useMoveObjectsMutation } from "../../../backend/hooks/contextMenu/fileOperations/mutationMoveObjects";
import { useCopyObjectsMutation } from "../../../backend/hooks/contextMenu/fileOperations/mutationCopyObjects";
import Error from "../../abstractComponents/error";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
interface CopyMoveProps {
  activityId: number;
  selectedRows: string[];
  isCopyMoveModalOpen: boolean;
  setIsCopyMoveModalOpen: Function;
  refetchRepoObjects: Function;
  tree: any;
  actionType: string;
  currentSelectedPath: string;
  currentSubArray: any;
  onNodeSelect: any;
  repoName: string;
  setIsCopyMoveModalOpenHook: Function;
  setIsCreateFolderWindowOpen: Function;
  actions: any;
  readOnlyCopyAction: boolean;
  setCurrentSelectedRepoId: Function;
  currentSelectedRepoId: string;
  areRepoObjectsLoading: Boolean;
}
const MAX_ROWS_DISPLAYED = 5;

// Component that allow users to copy/move files or directorys
export default function CopyMoveModal(props: CopyMoveProps) {
  const history = useHistory();
  const { onNodeSelect, setIsCopyMoveModalOpenHook } = props;
  const [directoryList, setDirectoryList] = useState<string[]>([]);
  const [copyMoveComment, setCopyMoveComment] = useState<string>("");
  const [originalPath, setOriginalPath] = useState<string>(
    props.currentSelectedPath
  );
  const [readOnlyRepoId, setReadOnlyRepoId] = useState<string>("");
  const OVERRIDE_EXIST_MESSAGE = "already exists at destination";
  const OVERRIDE_HIDDEN_MESSAGE = "are hidden at destination";
  const { moveObjectsMutation, isMoveObjectsLoading, moveOjectsError } =
    useMoveObjectsMutation();
  const { copyObjectsMutation, isCopyObjectsLoading, copyObjectsError } =
    useCopyObjectsMutation();
  const handleClickFolder = () => {
      return "";
    },
    handleClickFile = () => {
      return "";
    },
    handleClickComment = () => {
      return "";
    };

  const closeCopyMoveModal = (goToOriginalpath = false) => {
    setCopyMoveComment("");
    props.setIsCopyMoveModalOpen(false);

    if (goToOriginalpath) {
      if (props.readOnlyCopyAction) {
        // set the repoid to read only to use the tree for mapping
        props.setCurrentSelectedRepoId(readOnlyRepoId);
        onNodeSelect(originalPath);
      } else {
        props.onNodeSelect(originalPath);
      }
    }
  };

  const linkToDirectory = (index: number) => {
    let link = directoryList.slice(1, index).join("/");
    if (!link.endsWith("/")) {
      link = link + "/";
    }
    props.onNodeSelect(link);
  };

  const destinationCheckPath = (destinationPath: string) => {
    let newPath = destinationPath;
    if (newPath.charAt(newPath.length - 1) !== "/") {
      newPath = newPath.concat("", "/");
    }

    return newPath;
  };

  const onSuccess = () => {
    props.refetchRepoObjects();
    closeCopyMoveModal();
  };

  const handleError = (
    error: { message: string | string[] },
    variables: any,
    type: string
  ) => {
    if (
      error &&
      (error.message.includes(OVERRIDE_EXIST_MESSAGE) ||
        error.message.includes(OVERRIDE_HIDDEN_MESSAGE))
    ) {
      if (window.confirm(error.message + " Do you want to proceed?")) {
        if (type === "move") {
          moveObjectsMutation({
            variables: { ...variables, override: true }
          }).then(() => {
            onSuccess();
          });
        } else if (type === "copy") {
          copyObjectsMutation({
            variables: { ...variables, override: true }
          }).then(() => {
            onSuccess();
          });
        }
      }
    }
  };

  const onClickAction = (type: string) => {
    const destinationPath = destinationCheckPath(props.currentSelectedPath);

    const variables = {
      activityId: props.activityId,
      absolutePaths: props.selectedRows,
      destinationPath,
      comment: copyMoveComment,
      ...(props.readOnlyCopyAction && {
        repoIdOrigin: parseInt(readOnlyRepoId)
      })
    };

    if (type === "move") {
      moveObjectsMutation({
        variables
      })
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          handleError(error, variables, type);
        });
    } else if (type === "copy") {
      copyObjectsMutation({
        variables
      })
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          handleError(error, variables, type);
        });
    }
  };

  useEffect(() => {
    props.isCopyMoveModalOpen && setIsCopyMoveModalOpenHook(true);
    setOriginalPath(props.currentSelectedPath);

    if (props.readOnlyCopyAction && props.isCopyMoveModalOpen) {
      // go to writeable activity for read only copy
      onNodeSelect("/");
      props.setCurrentSelectedRepoId(props.tree.repo.id);
      props.activityId &&
        history.push(
          `/activity/${props.activityId.toString()}` +
            `${encodeURIComponent(`?repoId=`)}${props.tree.repo.id}`
        );
    }
  }, [props.isCopyMoveModalOpen]);

  useEffect(() => {
    let directory: string | string[] = props.currentSelectedPath as string;
    if (props.currentSelectedPath.split("").pop() === "/") {
      directory = props.currentSelectedPath.slice(0, -1);
    }
    directory = directory.split("/");
    directory.unshift("/");
    directory = directory.filter((element) => {
      return element !== "";
    });

    setDirectoryList(directory);
  }, [props.currentSelectedPath]);

  useEffect(() => {
    if (props.tree?.repo.id) {
      if (
        props.currentSelectedRepoId &&
        props.currentSelectedRepoId !== props.tree?.repo?.id.toString()
      ) {
        setReadOnlyRepoId(
          props.currentSelectedRepoId ? props.currentSelectedRepoId : ""
        );
      }
    }
  }, [props.currentSelectedRepoId]);

  return (
    <>
      <Modal
        show={props.isCopyMoveModalOpen as boolean}
        onHide={() => closeCopyMoveModal(true)}
        dialogClassName="modal-75w"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 d-flex flex-column fs-5">
            {props.actionType === "copy" ? "Copy" : "Move"} Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              Items to be {props.actionType === "copy" ? "copied" : "moved"}
              <ul>
                {props.selectedRows.map((row, index) =>
                  index < MAX_ROWS_DISPLAYED ? (
                    <li key={index}>{row}</li>
                  ) : (
                    <></>
                  )
                )}
                {props.selectedRows.length > MAX_ROWS_DISPLAYED && <li>...</li>}
              </ul>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="d-flex flex-wrap">
                Destination folder:{"\u00A0"}
                {directoryList.map((dirName, index) => (
                  <span key={index + "span"}>
                    {index !== 0 && `>${"\u00A0"}`}
                    <u
                      className={`pe-2 ${
                        index === directoryList.length - 1 && "fw-bold"
                      }`}
                      role="button"
                      key={index}
                      id={`copy-move-heading-${index}`}
                      onClick={() =>
                        index !== directoryList.length - 1 &&
                        linkToDirectory(index + 1)
                      }
                    >
                      {index === 0 ? props.repoName : dirName}
                    </u>
                  </span>
                ))}
                {(isMoveObjectsLoading || isCopyObjectsLoading) && (
                  <Spinner animation="border" className="spinner_color" />
                )}
              </div>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={2}>
              {directoryList.length > 1 && (
                <Button
                  variant="light"
                  onClick={() => linkToDirectory(directoryList.length - 1)}
                >
                  <ArrowBackIcon fontSize="small" /> Back
                </Button>
              )}
            </Col>
            <Col>
              <Button
                onClick={() => props.setIsCreateFolderWindowOpen(true)}
                className="float-end"
              >
                <CreateNewFolderIcon
                  id={`${props.actionType}-new-folder-icon`}
                  className="w-auto align-self-center"
                />
                New Folder
              </Button>
            </Col>
          </Row>
          <br />
          <MenuContext.Provider
            value={{
              handleClickFolder,
              handleClickFile,
              handleClickComment,
              onNodeSelect
            }}
          >
            <DataGridPro
              rows={props.currentSubArray ? props.currentSubArray : props.tree}
              columns={columnsRender(() => {}, true, false, true)}
              rowHeight={60}
              autoHeight
              hideFooterRowCount
              hideFooterSelectedRowCount
              className="copy-move-repository-table"
              getRowId={(row) => row.absolutePath}
              loading={props.areRepoObjectsLoading}
            />
          </MenuContext.Provider>
          <hr />
          <Row>
            <Form noValidate className="mt-2">
              <Col xs={6}>
                <Form.Group>
                  <Form.Label>
                    {" "}
                    {props.actionType === "copy" ? "Copy" : "Move"} Comment
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      data-testid={`${props.actionType}-comment-form`}
                      placeholder="Enter comment"
                      value={copyMoveComment as string}
                      onChange={(e) => setCopyMoveComment(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Form>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Col className="d-flex justify-content-end align-self-end">
            <Button
              variant="secondary"
              onClick={() => closeCopyMoveModal(true)}
              className="me-2"
            >
              Cancel
            </Button>
            <Button
              id={`${props.actionType}-action-button`}
              data-testid={`${props.actionType}-action-button`}
              variant="danger"
              onClick={() => onClickAction(props.actionType as string)}
              disabled={
                isMoveObjectsLoading ||
                isCopyObjectsLoading ||
                (!props.readOnlyCopyAction &&
                  props.currentSelectedPath.replace("/", "") ===
                    originalPath.replace("/", "")) ||
                props.selectedRows?.some((val) => {
                  return props.currentSelectedPath.includes(val as string);
                })
              }
            >
              {props.actionType === "copy" ? "Copy" : "Move"}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
      {((moveOjectsError &&
        (!moveOjectsError.message.includes(OVERRIDE_EXIST_MESSAGE) ||
          !moveOjectsError.message.includes(OVERRIDE_HIDDEN_MESSAGE))) ||
        (copyObjectsError &&
          (!copyObjectsError.message.includes(OVERRIDE_EXIST_MESSAGE) ||
            !copyObjectsError.message.includes(OVERRIDE_HIDDEN_MESSAGE)))) && (
        <Error error={moveOjectsError || copyObjectsError} />
      )}
    </>
  );
}
