import { CheckCircle, DoNotDisturb, ErrorRounded } from "@mui/icons-material";
import { JobStatus } from "../../backend/executionTypes";
import { SVGIcons } from "../abstractComponents/svgIcons/svgIcons";
import Moment from "moment";

export function getStatusTitle(status: JobStatus) {
  return status
    .toString()
    .toLowerCase()
    .split(" ")
    .map((l: string) => l[0].toUpperCase() + l.substring(1))
    .join(" ");
}
export function getStatusIcon(status: JobStatus) {
  switch (status) {
    case JobStatus.Submitted:
      return <SVGIcons svgName="circle-slice-1" />;
    case JobStatus.Pending:
      return <SVGIcons svgName="circle-slice-2" />;
    case JobStatus.Runnable:
      return <SVGIcons svgName="circle-slice-3" />;
    case JobStatus.Starting:
      return <SVGIcons svgName="circle-slice-4" />;
    case JobStatus.Running:
      return <SVGIcons svgName="circle-slice-5" />;
    case JobStatus.Canceled:
      return <DoNotDisturb color="disabled" />;
    case JobStatus.Succeeded:
      return <CheckCircle color="success" />;
    case JobStatus.Failed:
      return <ErrorRounded color="error" />;
  }
}

// consider using dayjs
export function convertLocalTimeZone(time: string) {
  if (time !== "") {
    return Moment.utc(time, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
      "YYYY-MM-DD HH:mm:ss z"
    );
  }
}
