import { gql, useMutation } from "@apollo/client";

export const UPDATE_ACTION_STATUS_MUTATION = gql`
  mutation updateActionStatus(
    $actionId: ID!
    $actionStatus: ActionStatus
    $actionStatusDescription: String
  ) {
    updateActionStatus(
      input: {
        actionId: $actionId
        actionStatus: $actionStatus
        actionStatusDescription: $actionStatusDescription
      }
    ) {
      id
      actionStatus
      actionStatusDescription
    }
  }
`;

export function useUpdateActionStatusMutation() {
  const [updateActionStatus] = useMutation(UPDATE_ACTION_STATUS_MUTATION, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    updateActionStatus
  };
}
