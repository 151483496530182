import { gql, useLazyQuery } from "@apollo/client";
export const GET_TABLE_DETAILS_DATA = gql`
  query getSpectrumTableDetails(
    $username: String!
    $encryptedPassword: String!
    $table: String!
    $schema: String!
  ) {
    getSpectrumTableDetails(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        table: $table
        schema: $schema
      }
    ) {
      description
      oracleName
      sasName
      sasLabel
      type
      audited
      blinded
      columns {
        name
        sasName
        sasLabel
        sasFormat
        length
        dataType
        position
      }
    }
  }
`;

export function useQueryTableDetailsData() {
  const [
    getSpectrumTableQueryData,
    {
      data: tableDetailsData,
      loading: isTableDetailsLoading,
      error: errorTableDetailsData
    }
  ] = useLazyQuery(GET_TABLE_DETAILS_DATA, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  return {
    getSpectrumTableQueryData,
    tableDetailsData,
    isTableDetailsLoading,
    errorTableDetailsData
  };
}
