import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_COMMENT = gql`
  mutation createComment(
    $conversationId: ID!
    $repliedToCommentId: ID
    $text: String!
    $referencedFiles: [String]
    $referencedActions: [String]
  ) {
    createComment(
      input: {
        conversationId: $conversationId
        repliedToCommentId: $repliedToCommentId
        text: $text
        referencedFiles: $referencedFiles
        referencedActions: $referencedActions
      }
    ) {
      id
    }
  }
`;

export function useCreateCommentMutation() {
  const [
    addNewComment,
    {
      loading: isLoadingCreateCommentMutation,
      error: errorCreateCommentMutation
    }
  ] = useMutation(MUTATION_CREATE_COMMENT);

  return {
    addNewComment,
    isLoadingCreateCommentMutation,
    errorCreateCommentMutation
  };
}
