import { gql, useLazyQuery } from "@apollo/client";
export const GET_SPECTRUM_STUDY_LIST = gql`
  query getSpectrumStudyList($username: String!, $encryptedPassword: String!) {
    getSpectrumStudyList(
      input: { username: $username, encryptedPassword: $encryptedPassword }
    ) {
      study
      system
      therapeuticArea
      indication
      drugProgramCode
    }
  }
`;

export function useQueryGetSpectrumStudyList() {
  const [
    getSpectrumStudyList,
    { data: studyList, loading: isLoadingStudyList, error: errorStudyList }
  ] = useLazyQuery(GET_SPECTRUM_STUDY_LIST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  return {
    getSpectrumStudyList,
    studyList,
    isLoadingStudyList,
    errorStudyList
  };
}
