import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InventoryIcon from "@mui/icons-material/Inventory";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import {
  compressedFileExtensions,
  excelFormatsExtensions,
  imageExtensions,
  installersExtensions,
  isFileEndsWith,
  moviesExtensions
} from "../../helpers/fileHelper";

// Redering an icon of a file
const FileIcon = (props: {
  fileName: string;
  isValid: boolean;
  fontSize?: "small" | "inherit" | "large" | "medium" | undefined;
  className?: string | undefined;
  absolutePath?: string | undefined;
}) => (
  <>
    {props.fileName.endsWith("/") ||
    (props.absolutePath && props.absolutePath.endsWith("/")) ? (
      <FolderIcon
        className="grey_color folder_icon main_icon"
        fontSize={props.fontSize}
      />
    ) : props.fileName.toLocaleLowerCase().endsWith(".pdf") ? (
      <PictureAsPdfIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon pdf_icon " + props.className
            : "grey_color main_icon pdf_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(
        props.fileName.toLocaleLowerCase(),
        compressedFileExtensions
      ) ? (
      <InventoryIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon " + props.className
            : "grey_color main_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(props.fileName.toLocaleLowerCase(), imageExtensions) ? (
      <InsertPhotoIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon photo_icon " + props.className
            : "grey_color main_icon photo_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : props.fileName.toLocaleLowerCase().endsWith(".ppt") ||
      props.fileName.toLocaleLowerCase().endsWith(".pptx") ? (
      <SlideshowIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon " + props.className
            : "grey_color main_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(
        props.fileName.toLocaleLowerCase(),
        excelFormatsExtensions
      ) ? (
      <CalendarViewMonthIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon " + props.className
            : "grey_color main_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : props.fileName.toLocaleLowerCase().endsWith(".txt") ||
      props.fileName.toLocaleLowerCase().endsWith(".doc") ||
      props.fileName.toLocaleLowerCase().endsWith(".log") ||
      props.fileName.toLocaleLowerCase().endsWith(".docx") ? (
      <ArticleOutlinedIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon txt_icon " + props.className
            : "grey_color main_icon txt_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(props.fileName.toLocaleLowerCase(), moviesExtensions) ? (
      <LocalMoviesIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon " + props.className
            : "grey_color main_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(
        props.fileName.toLocaleLowerCase(),
        installersExtensions
      ) ? (
      <InstallDesktopIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon " + props.className
            : "grey_color main_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    ) : (
      <DescriptionIcon
        className={
          props.isValid
            ? "is_valid_clean main_icon " + props.className
            : "grey_color main_icon " + props.className
        }
        fontSize={props.fontSize}
      />
    )}
  </>
);

export default FileIcon;
